import React, { useState } from "react";
import Header from "../../../components/ui/Header";
import {
  addappCalcApis,
  getappCalcApis,
} from "../../../graphs/appCalculator/appcalcApis";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { notification } from "antd";
import DeleteModal from "../../../components/ui/modal/DeleteModal";
import DataTable from "react-data-table-component";
import CButton from "../../../components/ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const Feature = () => {
  const AuthToken = useSelector((state) => state?.auth?.authToken);
  const [allFeatureData, setAllFeatureData] = useState([]);
  const [allModuleData, setAllModuleData] = useState([]);
  const [editValue, setEditValue] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPageOptions = [10, 20, 50, 100];
  const rowsPerPage = 10;

  const getAllFeatureData = async () => {
    try {
      await getappCalcApis(AuthToken, "feature").then((res) => {
        setAllFeatureData(res?.data?.data);
      });
    } catch (err) {
      throw err;
    }
  };

  const getModulsData = async () => {
    try {
      await getappCalcApis(AuthToken, "module").then((res) => {
        const newdata = res.data?.data?.map((ele) => ({
          value: ele.id,
          label: ele.name,
          ...ele,
        }));
        setAllModuleData(res?.data?.data);
      });
    } catch (err) {
      throw err
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    let formData = new FormData();
    formData.append("module_id", values?.module_id);
    formData.append("name", values?.name);
    formData.append("timeline", values?.timeline);
    formData.append("mobile_timeline", values?.mobile_timeline);
    formData.append("price", values?.price);
    formData.append("admin_panel", values?.admin_panel);
    formData.append("web_services", values?.web_services);
    formData.append("database_hrs", values?.database_hrs);
    formData.append("support", values?.support);
    formData.append("is_selected", values?.is_selected);

    try {
      await addappCalcApis(AuthToken, "feature", formData).then((res) => {
        notification["success"]({
          message: "Feature added successfully",
        });
        getAllFeatureData();
        setEditValue(null);
        resetForm();
      });
    } catch (err) {
      throw err;
    }
  };
  const validateData = (value) => {
    const error = {};
    const phoneRegex = /^\+?\d{1,10}$/;
    if (!value.name) {
      error.name = "Please enter value";
    }
    if (!value.timeline) {
      error.timeline = "Please enter value";
    } else if (!phoneRegex.test(value.timeline)) {
      error.timeline = "Please enter valid value";
    }
    if (!value.mobile_timeline) {
      error.mobile_timeline = "Please enter value";
    } else if (!phoneRegex.test(value.mobile_timeline)) {
      error.mobile_timeline = "Please enter valid value";
    }
    if (!value.price) {
      error.price = "Please enter value";
    } else if (!phoneRegex.test(value.price)) {
      error.price = "Please enter valid value";
    }
    if (!value.is_selected) {
      error.is_selected = "Please enter value";
    }
    if (!value.admin_panel) {
      error.admin_panel = "Please enter value";
    } else if (!phoneRegex.test(value.admin_panel)) {
      error.admin_panel = "Please enter valid value";
    }
    if (!value.web_services) {
      error.web_services = "Please enter value";
    } else if (!phoneRegex.test(value.web_services)) {
      error.web_services = "Please enter valid value";
    }
    if (!value.database_hrs) {
      error.database_hrs = "Please enter value";
    } else if (!phoneRegex.test(value.database_hrs)) {
      error.database_hrs = "Please enter valid value";
    }
    if (!value.support) {
      error.support = "Please enter value";
    } else if (!phoneRegex.test(value.support)) {
      error.support = "Please enter valid value";
    }

    return error;
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const columns = [
    {
      name: <p>Sr. No.</p>,
      width: "50px",
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
    },
    {
      name: "Module Name",
      width: "220px",
      sortable: true,
      selector: (row) => row?.module_name,
    },
    {
      name: <p>Feature Name</p>,
      sortable: true,
      width: "200px",
      selector: (row) => (
        <p className="break-words whitespace-break-spaces">{row?.name}</p>
      ),
    },
    {
      name: <p>Timeline (hrs)</p>,
      width: "80px",
      selector: (row) => row?.timeline,
    },
    {
      name: <p>Mobile Timeline (hrs)</p>,
      width: "80px",
      selector: (row) => row?.mobile_timeline,
    },
    {
      name: <p>Admin Panel (hrs)</p>,
      width: "80px",
      selector: (row) => row?.admin_panel,
    },
    {
      name: <p>Web Services (hrs)</p>,
      width: "80px",
      selector: (row) => row?.web_services,
    },
    {
      name: <p>Database (hrs)</p>,
      width: "84px",
      selector: (row) => row?.web_services,
    },
    {
      name: <p>Support (Per hrs)</p>,
      width: "80px",
      selector: (row) => row?.support,
    },
    {
      name: <p>Base Price</p>,
      width: "80px",
      selector: (row) => row?.price,
    },
    {
      name: <p>If selected (consider this price)</p>,
      width: "82px",

      selector: (ele) => (ele?.is_selected === "1" ? "Yes" : "No"),
    },
    {
      name: "Action",
      width: "100px",
      cell: (ele) => (
        <div className="">
          <div className="flex">
            <div
              onClick={() => {
                setEditValue(ele);
                goToTop();
              }}
              className="inline-block mr-2 cursor-pointer"
            >
              <FontAwesomeIcon
                className="text-blue-600 hover:text-blue-800"
                size="lg"
                icon={faEdit}
              />
            </div>

            <div
              className="inline-block mr-2 cursor-pointer"
              onClick={() => {
                setDeleteId(ele?.id);
                setDeleteModal(true);
              }}
            >
              <FontAwesomeIcon
                className="text-rose-500 hover:text-rose-600"
                size="lg"
                icon={faTrashAlt}
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    getAllFeatureData();
    getModulsData();
  }, [editValue, deleteModal]);

  return (
    <>
      <section className="py-8">
        <Header title={"Features"} />
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full lg:w-full px-4 mb-8 lg:mb-0">
              <div className="px-6 pb-6 pt-4 bg-white shadow rounded">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id: editValue?.id,
                    module_id: editValue?.module_id ? editValue.module_id : "",
                    timeline: editValue?.timeline ? editValue.timeline : "",
                    name: editValue?.name ? editValue.name : "",
                    mobile_timeline: editValue?.mobile_timeline
                      ? editValue.mobile_timeline
                      : "",
                    price: editValue?.price ? editValue.price : "",
                    is_selected: editValue?.is_selected
                      ? editValue.is_selected
                      : "",
                    admin_panel: editValue?.admin_panel
                      ? editValue.admin_panel
                      : "",
                    web_services: editValue?.web_services
                      ? editValue.web_services
                      : "",
                    database_hrs: editValue?.web_services
                      ? editValue.web_services
                      : "",
                    support: editValue?.support ? editValue.support : "",
                  }}
                  onSubmit={handleSubmit}
                  validate={validateData}
                >
                  {({
                    values,
                    handleBlur,
                    handleChange,
                    handleReset,
                    handleSubmit,
                    errors,
                    touched,
                    setFieldValue,
                    resetForm,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-6 gap-4 items-start">
                          <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 col-span-6">
                            <div className="mb-6">
                              <div>
                                <label
                                  className="block text-sm font-medium mb-2"
                                  for=""
                                >
                                  Select Modules *
                                </label>
                                <div className="relative">
                                  <select
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.module_id}
                                    name="module_id"
                                    id="module_id"
                                    className="appearance-none block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                  >
                                    {allModuleData?.map((ele, idx) => {
                                      return (
                                        <option key={idx} value={ele?.id}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
                                    <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewbox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 col-span-6">
                            <div className="mb-6">
                              <label
                                className="block text-sm font-medium mb-2"
                                for=""
                              >
                                Enter Feature *
                              </label>
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.name}
                                name="name"
                                id="name"
                                className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                type="text"
                                placeholder="new service"
                              />
                              {errors.name && touched.name ? (
                                <p className="text-rose-500 text-sm">
                                  {errors.name}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-4 items-start">
                          <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 col-span-6">
                            <div className="flex items-start w-full gap-2 justify-between">
                              <div className="mb-6 flex-initial w-64">
                                <div>
                                  <label
                                    className="block text-sm font-medium mb-2"
                                    for=""
                                  >
                                    Web Timeline *
                                  </label>
                                  <input
                                    className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.timeline}
                                    name="timeline"
                                    id="timeline"
                                    placeholder="Timeline in Hours"
                                  />
                                  {errors.timeline && touched.timeline ? (
                                    <p className="text-rose-500 text-sm">
                                      {errors.timeline}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mb-6 flex-initial w-64">
                                <label
                                  className="block text-sm font-medium mb-2"
                                  for=""
                                >
                                  Mobile Timeline *
                                </label>
                                <input
                                  className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.mobile_timeline}
                                  name="mobile_timeline"
                                  id="mobile_timeline"
                                  placeholder="Timeline in Hours"
                                />
                                {errors.mobile_timeline &&
                                touched.mobile_timeline ? (
                                  <p className="text-rose-500 text-sm">
                                    {errors.mobile_timeline}
                                  </p>
                                ) : null}
                              </div>
                              <div></div>
                            </div>
                          </div>
                          {editValue !== null ? (
                            <>
                              <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 col-span-6">
                                <div className="flex gap-2 items-center w-full justify-between">
                                  <div className="mb-6 flex-initial w-64 gap-2">
                                    <label
                                      className="block text-sm font-medium mb-2"
                                      for=""
                                    >
                                      Admin Panel *
                                    </label>
                                    <input
                                      className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                      type="text"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values?.admin_panel}
                                      name="admin_panel"
                                      id="admin_panel"
                                      placeholder="Enter admin_panel"
                                    />
                                    {errors.admin_panel &&
                                    touched.admin_panel ? (
                                      <p className="text-rose-500 text-sm">
                                        {errors.admin_panel}
                                      </p>
                                    ) : null}
                                  </div>
                                  <div className="mb-6 flex-initial w-64">
                                    <label
                                      className="block text-sm font-medium mb-2"
                                      for=""
                                    >
                                      Web services *
                                    </label>
                                    <input
                                      className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                      type="text"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values?.web_services}
                                      name="web_services"
                                      id="web_services"
                                      placeholder="Enter value"
                                    />
                                    {errors.web_services &&
                                    touched.web_services ? (
                                      <p className="text-rose-500 text-sm">
                                        {errors.web_services}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 col-span-6">
                                <div className="flex gap-2 items-start w-full justify-between">
                                  <div className="mb-6 flex-initial w-64 gap-2">
                                    <label
                                      className="block text-sm font-medium mb-2"
                                      for=""
                                    >
                                      Database (hrs) *
                                    </label>
                                    <input
                                      className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                      type="text"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values?.database_hrs}
                                      name="database_hrs"
                                      id="database_hrs"
                                      placeholder="Enter value"
                                    />
                                    {errors.database_hrs &&
                                    touched.database_hrs ? (
                                      <p className="text-rose-500 text-sm">
                                        {errors.database_hrs}
                                      </p>
                                    ) : null}
                                  </div>
                                  <div className="mb-6 flex-initial w-64">
                                    <label
                                      className="block text-sm font-medium mb-2"
                                      for=""
                                    >
                                      Support(hrs) *
                                    </label>
                                    <input
                                      className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                      type="text"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values?.support}
                                      name="support"
                                      id="support"
                                      placeholder="Enter value"
                                    />
                                    {errors.support && touched.support ? (
                                      <p className="text-rose-500 text-sm">
                                        {errors.support}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                          <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 col-span-6">
                            <div className="flex gap-2 items-start w-full justify-start">
                              <div className="mb-6 flex-initial w-64">
                                <label
                                  className="block text-sm font-medium mb-2"
                                  for=""
                                >
                                  Enter Price *
                                </label>
                                <input
                                  className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.price}
                                  name="price"
                                  id="price"
                                  placeholder="Enter Price"
                                />
                                {errors.price && touched.price ? (
                                  <p className="text-rose-500 text-sm">
                                    {errors.price}
                                  </p>
                                ) : null}
                              </div>
                              {editValue !== null ? (
                                <div className="mb-6 flex-initial w-64">
                                  <label className="block text-sm font-medium mb-2">
                                    If Selected *
                                  </label>
                                  <select
                                    className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.is_selected}
                                    name="is_selected"
                                    id="is_selected"
                                  >
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                  </select>
                                  {errors.is_selected && touched.is_selected ? (
                                    <p className="text-rose-500 text-sm">
                                      {errors.is_selected}
                                    </p>
                                  ) : null}
                                </div>
                              ) : null}
                              <div className="my-auto">
                                <CButton title={"Save"} type={"submit"} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>

                <div className="p-2 w-full mb-6 border bg-white shadow-xl rounded overflow-x-auto">
                  <DataTable
                    columns={columns}
                    data={allFeatureData}
                    pagination={true}
                    striped
                    paginationPerPage={8}
                    onChangePage={handlePageChange}
                    paginationRowsPerPageOptions={rowsPerPageOptions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeleteModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          deleteId={deleteId}
          type={"feature"}
          title={"feature"}
        />
      </section>
    </>
  );
};

export default Feature;
