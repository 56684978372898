import { Modal, notification } from "antd";
import { Formik } from "formik";
import React from "react";
import { uploadImage } from "../../../graphs/appCalculator/appcalcApis";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

const UploadImage = ({ uploadImageModal, setUploadImageModal }) => {
    const AuthToken = useSelector((state)=> state?.auth?.authToken)
  const handleSubmit = async(value, {resetForm}) => {
    const formData = new FormData()
    formData.append("image", value.file)
    console.log("image file value", value);
    try{
        await uploadImage(AuthToken, formData).then((res)=> {
            console.log(res)
            notification["success"]({
                message:"Image Uploaded Successfully"
            })
            resetForm();
            setUploadImageModal(false)
        })
    }catch(err){
        throw err;
    }
  };

  const validateData = (value) => {
    const error = {};
    if (!value.file) {
      error.file = "Please select file";
    }
    console.log(error)
    return error;
  };

  return (
    <div>
      <Modal
        open={uploadImageModal}
        onOk={() => setUploadImageModal(false)}
        onCancel={() => setUploadImageModal(false)}
        footer={null}
        destroyOnClose
      >
        <h2 className="mb-4 text-xl font-bold mr-2 "> Upload Image  </h2>
        <div className="pb-3">
          <Formik
            initialValues={{ file: "" }}
            onSubmit={handleSubmit}
            validate={validateData}
          >
            {({
              handleBlur,
              handleSubmit,
              values,
              handleChange,
              errors,
              setFieldValue,
              touched,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="mb-2 flex items-center gap-2">
                    <label>Upload Image : </label>
                    <input
                      accept="image/jpg, image/png, image/jpeg"
                      className="border rounded"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("file", e.target.files[0]);
                      }}
                      type="file"
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="px-4 py-2 bg-yellow-500 rounded "
                    >
                      Save
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default UploadImage;
