import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const Protected = ({isSignedIn, children}) => {
 if(isSignedIn){
    return <Navigate to="/" replace/>
 }
 return children


// const isSignedIn = true
// return isSignedIn ?  <Outlet/> : <Navigate to="/"/>;
}

export default Protected
