import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";

const CButton = ({title, icon, type, onClick}) => {
    const navigate = useNavigate()
  return (
    <>
    <button onClick={onClick} type={type} className="lg:py-2 lg:px-4 md:py-2 md:px-4 sm:py-1 sm:px-2 py-1 px-2 bg-yellow-400 border hover:border-yellow-700 border-yellow-400 rounded-lg backdrop-blur">
      <div className="flex items-center justify-center">
        {icon ? <FontAwesomeIcon icon={icon}/> : null}
        <span className={`${icon ? "ml-2" : ""} lg:text-base lg:font-medium md:text-base md:font-medium sm:text-sm sm:font-normal`}>{title}</span>
      </div>
    </button>
    </>
  );
};

export default CButton;
