import React, { useEffect, useState } from "react";
import loaderGif from "../../assets/images/loader.gif"
import StatsCard from "../../components/ui/StatsCard";
import { useNavigate } from "react-router-dom";
import { getUsers } from "../../graphs/getUsers";
import { useDispatch, useSelector } from "react-redux";
import { setUsers } from "../../actions/users";
import { getProposals } from "../../graphs/getProposals";

const Dashboard = () => {
  const [usersData, setUsersData] = useState([])
  const [proposalData, setProposalData] = useState([])
  const dispatch = useDispatch()
  const AuthToken = useSelector((state)=> state?.auth?.authToken)
  const id = useSelector((state)=> state?.auth?.loginUserData?.user_id)
  const role = useSelector((state)=> state?.auth?.loginUserData?.role)
  // console.log(AuthToken)
  const navigate = useNavigate()
  
  const getUsersData = async () => {
    await getUsers(AuthToken).then((res)=>{
      setUsersData(res?.data)
      dispatch(setUsers(res?.data))
      console.log("user response => ",res)
    })
  }

  const getProposalData = async () => {
    try{
      await getProposals(AuthToken,id,role).then((res)=> {
        console.log(res)
        setProposalData(res?.data)
      }) 
    }catch(err){
      throw err
    }
  }
  useEffect(() => {
    getUsersData()
    getProposalData();
  },[])
  return (
    <>
      <section className="py-8 min-h-screen">
        <div className=" px-4">
          <div className="container mb-6 p-4 text-center bg-white rounded">
            <div className="flex items-center justify-center mb-3">
            <img src={loaderGif} alt="loader"/>
            </div>
            <h1 className="font-bold text-4xl">Welcome To Prometteur</h1>
            <p>“When you do your best, you become better at what you do.”</p>
          </div>
        </div>
        <div className="container px-4 mx-auto">
          <div className="flex items-center justify-center flex-wrap -m-4">
            <StatsCard link={"/admin/users"} title={"Total Users"} value={usersData?.length}/>
            <StatsCard link={"/admin/proposals"} title={"Total Proposals"} value={proposalData?.length}/>
            {/* <StatsCard link={"/admin/dashboard"} title={"Click Counter"} value={"73.5%"}/>
            <StatsCard link={"/admin/dashboard"} title={"Visit Duration"} value={"0m 50s"}/> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
