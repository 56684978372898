import React from 'react'
import './App.css';
import PageRouter from "./components/Router.jsx";
import store from './store';
import { Provider } from 'react-redux';
import { BrowserRouter, RouterProvider } from 'react-router-dom';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter basename='/'>
      {/* <RouterProvider router={PageRouter} /> */}
      <PageRouter/>
      </BrowserRouter>
    </Provider>
  )
}

export default App
