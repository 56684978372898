import { LOGIN } from "../../constant/common";
import axios from "axios";

export const adminLogin = async(value) => {
    try{
        return await axios.post(`${LOGIN}`, {
            username: value.username,
            password: value.password,
        })

    }catch(err){
        throw err;
    }
};
