import React, { useState } from 'react'
import { Editor } from '@tinymce/tinymce-react';

const TinyTextEditor = ({id, name, value,initialValue, onEditorChange}) => {
    const [editorContent, setEditorContent] = useState('');
    const handleEditorChange = (content, editor) => {
        setEditorContent(content);
        console.log(content, editor)
      };
  return (
    <>
       <Editor
       init={{
          plugins: 'code table pagebreak',
          toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | table | pagebreak | code | removeformat | bullist numlist outdent indent |',
        }}
        initialValue={initialValue}
        apiKey="wfo9r3vw6yq5etlwjspajfcoypzqpnevzaus1tvithfgd34p" // Add your TinyMCE API key here
        name={name}
        id={id}
        value={value}
        onEditorChange={onEditorChange}
      />
    </>
  )
}

export default TinyTextEditor
