import { CREATE_PROPOSAL, GET_PROPOSALS, GET_PROPOSAL_DETAILS, UPDATE_PROPOSAL } from "../constant/common";
import axios from "axios";

export const getProposals = async (AuthToken, id, role) => {
  try {
    return await axios.get(`${GET_PROPOSALS}/?id=${id}&role=${role}`, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};

export const getProposalDetails1 = async (AuthToken, id) => {
  try {
    return await axios.get(`${GET_PROPOSAL_DETAILS}/${id}`,{valu:""}, {
        headers: {Authorization: AuthToken}
    });
  } catch (err) {
    throw err;
  }
};


export const getProposalDetails = async (AuthToken, id) => {
  try {
    return await axios.get(`${GET_PROPOSAL_DETAILS}/${id}`, {
      headers: {Authorization : AuthToken}
    })
  } catch (err) {
    throw err;
  }
};

//create proposal
export const createProposals = async(AuthToken, values) => {
    try{
        return await axios.post(`${CREATE_PROPOSAL}`,values, {
            headers: {Authorization:AuthToken}
        })
    }catch(err){
        throw err
    }
}

//update proposal
export const updateProposal = async(AuthToken, values, id) => {
    try{
        return await axios.post(`${UPDATE_PROPOSAL}/${id}`,values, {
            headers: {Authorization:AuthToken}
        })
    }catch(err){
        throw err
    }
}