export const WEBURL = "https://prometteursolutions.com/appcalc_api/api/";
export const MEDIALINK = `https://prometteursolutions.com/appcalc_api/media/`
export const RESUME_LINK = `https://prometteursolutions.com/resumes/`
const VERSION = "v1";
// Authentication
export const LOGIN = `${WEBURL}login`;
export const LOGOUT = `${WEBURL}logout`;
// User
export const REGISTER_USER = `${WEBURL}register`;
export const GET_USER = `${WEBURL}getusers`;
export const DELETE_USER = `${WEBURL}deleteUser`;
export const UPDATE_USER = `${WEBURL}updateUser`;
export const UPDATE_PASSWORD = `${WEBURL}updateUserPass`;
export const GET_SINGLE_USER = `${WEBURL}get_user`;

// blogs or proposals
export const GET_PROPOSALS = `${WEBURL}adminBlogs`;
export const GET_PROPOSAL_DETAILS = `${WEBURL}adminBlog`;
export const CREATE_PROPOSAL = `${WEBURL}createBlog`;
export const UPDATE_PROPOSAL = `${WEBURL}updateBlog`;

//App calculator
    //industry
export const GET_ALL_INDUSTRY = `${WEBURL}industry/view`;
export const GET_INDUSTRY = `${WEBURL}getIndustrybyId`;
export const ADD_INDUSTRY = `https://prometteursolutions.com/appcalc/admin/add-industry`;
export const DELETE_INDUSTRY = `${WEBURL}industry/delete`;

    //module
export const GET_ALL_MODULE = `${WEBURL}module/view`;


    //services
export const GET_ALL_SERVICES = `${WEBURL}services/view`;
export const GET_SERVICES = `${WEBURL}services/view`;
export const GET_PARENT_SERVICES = `${WEBURL}services/getservices/0`;

//proposals 
export const GET_RESPONSE_DETAILS = `${WEBURL}proposalenquiry/getenquiry`;
export const DELETE_PROPOSAL = `${WEBURL}deleteBlog`;
export const DELETE_CALC_RESPONSE = `${WEBURL}deletePropsalEnquiry`;
//technology main
export const GET_TECHNOLOGY_MAIN = `${WEBURL}technology/bymain`;
export const GET_SERVICES_TECH = `${WEBURL}proposalenquiry/getservices`;

export const GET_ALL_IMAGES = `${WEBURL}getImages`;

export const GET_ALL_CALC_MODULE = `${WEBURL}proposalenquiry/getmodules/`;
export const GET_ALL_SUBFEATURES = `${WEBURL}getFeaturebyModuleId/`;