import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToggle } from "../actions/toggleBtn";

const AdminLayout = () => {
  const [toggleBtn, setToggleBtn] = useState(false);

  const dispatch = useDispatch();
  const loginFlag = useSelector((state) => state?.auth?.loginFlag);
  const handleToggle = () => {
    setToggleBtn(!toggleBtn);
    dispatch(setToggle(!toggleBtn));
  };

  useEffect(() => {}, [toggleBtn]);
  return (
    <div className="">
      {loginFlag ? (
        <div className="">
          <Sidebar toggleBtn={toggleBtn} />
          <Header togglefun={handleToggle} toggleBtn={toggleBtn} />
          <div
            className={`${
              toggleBtn
                ? "lg:ml-32 md:ml-32 sm:ml-0 ml-0 ease-in-out duration-300"
                : "lg:ml-80 md:ml-80 sm:ml-12 ml-12 ease-in-out duration-300"
            } pt-16 pb-0 bg-slate-200`}
          >
            <Outlet />
          </div>
        </div>
      ) : (
        <Navigate to="/" />
      )}
    </div>
  );
};

export default AdminLayout;
