import { faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  return (
    <div className="h-full w-full">
      <div className="flex items-center justify-center pt-12 h-full w-full">
        <div className="my-12">
          <div className="p-8 rounded-lg bg-white">
            <div className="text-center">
                <div className="text-slate-800 mb-4 p-3"><FontAwesomeIcon className="text-7xl" size="xl" icon={faUnlock} /></div>
                <h2 className="text-base font-medium mb-4">Need to <span className="text-[#FECD1A]">Reset your password?</span>  No problem! Just <br/> click the button below and you'll be on your way.</h2>
                <Link target="_blank" to="skype:swapnilsat?chat" className="px-4 py-2 bg-[#303030] text-white rounded-lg">Reset your password</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
