import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  addappCalcApis,
  getAllCalcModule,
  getServicesTech,
  getTechnologyMain,
  getappCalcApis,
} from "../../graphs/appCalculator/appcalcApis";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowRight,
  faCalculator,
  faCircleCheck,
  faComment,
  faEnvelope,
  faMapMarkedAlt,
  faPhone,
  faPhoneAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import CalcTitle from "../../components/ui/CalcTitle";
import FeatureModal from "./FeatureModal";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import CalcInputCard from "../../components/ui/CalcInputCard";

const Services = () => {
  const AuthToken = useSelector((state) => state?.auth?.authToken);
  const [technologyDetail, setTechnologyDetail] = useState([]);
  // const [allModules, setAllModules] = useState([]);
  const [webFroentTech, setWebFroentTech] = useState([]);
  const [backendTech, setBackendTech] = useState([]);
  const [mobileAppTech, setMobileAppTech] = useState([]);
  const [appQuality, setAppQuality] = useState([]);
  // const [featureModalOpen, setFeatureModalOpen] = useState(false);
  // const [featuteId, setFeatureId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [screenState, setScreenState] = useState({
    technologyDetail: [],
    allModules: [],
    featureModalOpen: false,
    featuteId: null,
    subModules: [],
  });

  console.log("submodules ==> ", screenState?.subModules);

  document.title = "Project Cost Calculator | Prometteur Solutions";
  const totalpages = 4;
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();

    let technologyData = `admin-frontend=[${values?.adminFroentEnd}],mobileapi=[${values?.mobile ? 'Node.js' : ''}],mobile=[${values?.mobile}],web-backend=[${values?.backend}],enableNext=true,web-frontend=[${values?.webAppFroentEnd}],currentTab=4,admin-backend=[${values?.backend}]`;

    formData.append("main_service", JSON.stringify(values?.parentServices));
    formData.append("modules", JSON?.stringify(values?.modules));
    formData.append("technology", technologyData);
    formData.append("app_quality", values?.appQuality);
    formData.append("name", values?.fullName);
    formData.append("email", values?.email);
    formData.append("contact", values?.phoneNumber);
    formData.append("city", values?.city);
    formData.append("comment", values?.comment);
    formData.append("attachment", values?.attachement);

    console.log("costcalc values =>", values);

    try {
      await addappCalcApis(AuthToken, "proposalenquiry", formData).then(
        (res) => {
          resetForm();
          console.log("add appcalc response => ", res?.data?.insert_id);
          navigate(`/appcalc/calculator-overview/${res?.data?.insert_id}`);
        }
      );
    } catch (err) {
      throw err;
    }
  };

  const getResponseDetailsHandler = async () => {
    try {
      await getServicesTech(AuthToken, 0).then((res) => {
        // setResponseDetails(res?.data?.data);
        console.log("res==================", res);
        setTechnologyDetail(res?.data?.data);
        // setScreenState((prev) => {
        //   return {
        //     ...prev,
        //     technologyDetail: res?.data?.data,
        //   };
        // });
        console.log(res?.data);
      });
    } catch (err) {
      console.log(err);
    }

    try {
      await getAllCalcModule(AuthToken).then((res) => {
        console.log("res ======= get all nodules", res);
        // setAllModules(res?.data?.data);
        setScreenState((prev) => {
          return {
            ...prev,
            allModules: res?.data?.data,
          };
        });
      });
    } catch (err) {
      throw err;
    }
    try {
      await getTechnologyMain(AuthToken, "2").then((res) => {
        console.log("web froentend tech =======", res);
        setWebFroentTech(res?.data?.data);
      });
    } catch (err) {
      throw err;
    }
    try {
      await getTechnologyMain(AuthToken, "1").then((res) => {
        console.log("mobile app tech =======", res);
        setMobileAppTech(res?.data?.data);
      });
    } catch (err) {
      throw err;
    }
    try {
      await getTechnologyMain(AuthToken, "3").then((res) => {
        console.log("mobile app tech =======", res);
        setBackendTech(res?.data?.data);
      });
    } catch (err) {
      throw err;
    }
    try {
      await getappCalcApis(AuthToken, "app_quality").then((res) => {
        console.log("app quality =======", res);
        setAppQuality(res?.data?.data);
      });
    } catch (err) {
      throw err;
    }
  };

  const prevPageHandler = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPageHandler = (errors, values) => {
    console.log("errors next page handler ======", errors, values);

    //validation if parent servicess are not selected
    if (currentPage === 1 && !values?.parentServices?.length > 0) {
      notification["error"]({
        message: "Please Select atleast one Service",
      });
    } else if(currentPage === 1 && values?.parentServices?.length > 0) {
      setCurrentPage(currentPage + 1);
    }

    //validation if modules are not selected
    if(currentPage === 2 && !values?.modules?.length > 0){
      notification["error"]({
        message: "Please Select atleast one feature",
      });
    }else if(currentPage === 2 && values?.modules?.length > 0) {
      setCurrentPage(currentPage + 1);
    }


    

    // validation if selected only web services (51) 
    if(currentPage === 3 && values?.parentServices.includes('51')  && !values?.webAppBackEnd && !values?.webAppFroentEnd){
      notification["error"]({
        message: "Please Select required technology",
      });
    } else if (currentPage === 3 && values?.parentServices.includes('51') && !values?.parentServices.includes('2') && values?.webAppBackEnd && values?.webAppFroentEnd){
      setCurrentPage(currentPage + 1);
    }

// validation if selected only mobile services (2) 
if(currentPage === 3 && values?.parentServices.includes('2') && !values?.adminpanel){

  if(currentPage === 3 && values?.parentServices.includes('2') && !values?.mobile ){
    notification["error"]({
      message: "Please Select required technology",
    });
  } else if (currentPage === 3 && values?.parentServices.includes('2') && values?.mobile ){
    setCurrentPage(currentPage + 1);
    }

// validation if mobile servicess and admin panel also selected 
} else if(currentPage === 3 && values?.parentServices.includes('2') && values?.adminpanel){

  if(currentPage === 3 && values?.parentServices.includes('2') && !values?.mobile && !values?.adminFroentEnd && !values?.backend){
    notification["error"]({
      message: "Please Select required technology",
    });
  } else if (currentPage === 3 && values?.parentServices.includes('2') && !values?.parentServices.includes('51') && values?.mobile && values?.adminFroentEnd && values?.backend){
    setCurrentPage(currentPage + 1);
    }
}

//validation for if both servicess are selected web and mobile


if(currentPage === 4 && !values?.appQuality){
  notification["error"]({
    message: "Please Select app quality",
  });
} else if (currentPage === 4 && values?.appQuality){
  setCurrentPage(currentPage + 1);
}

// if(currentPage === 5 &&  !values?.phoneNumber && !values?.fullName && !values?.email && !values?.city){
//   notification["error"]({
//     message: "Please Select required field",
//   });
// }

  };

  const validateData = (value) => {
    const phoneRegex = /^\+?\d{1,12}$/;
    const error = {};
    if (currentPage === 1 && !value.parentServices?.length > 0) {
      error.parentServices = "Please select value";
    }
    if (!value.parentServices) {
      error.parentServices = "Please Enter value";
    }

    if (!value.fullName) {
      error.fullName = "Please enter value";
    }else if (!/^[a-zA-Z]+$/.test(value.fullName)) {
      error.fullName = "Accept alphabetic letters only";
    }

    if (!value?.email) {
      error.email = "Please Enter value";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.email)) {
      error.email = "Please enter valid value";
    }
    if (!value.phoneNumber) {
      error.phoneNumber = "Please Enter value";
    } else if (!phoneRegex.test(value.phoneNumber)) {
      error.phoneNumber = "Please enter valid value";
    }

    if (!value.city) {
      error.city = "Please enter value";
    }else if (!/^[a-zA-Z]+$/.test(value.city)) {
      error.city = "Accept alphabetic letters only";
    }
    return error;
  };


  useEffect(() => {
    getResponseDetailsHandler();
  }, []);
  return (
    <div className="lg:mx-20 md:mx-16 sm:mx-4 mx-4 h-full min-h-screen">
      <div className="relative  py-20">
        <div className="flex">
          <div className="flex items-center gap-2">
            <FontAwesomeIcon icon={faCalculator} size="lg" />
            <h1 className="lg:text-2xl md:text-2xl sm:text-xl text-xl font-normal my-3">
              Project{" "}
              <span className="lg:text-2xl md:text-2xl sm:text-xl text-xl text-[#ffcf1e]">
                Cost Calculator
              </span>{" "}
            </h1>
          </div>
        </div>
        <div className="bg-white lg:mb-20 md:mb-20 sm:mb-4 mb-4 h-full">
          <Formik
            // enableReinitialize={true}
            initialValues={{
              parentServices: [],
              modules: [],
              mainServices: [],
              mobile: "",
              adminpanel: false,
              backend: "Node.js",
              appQuality: "",
              city: "",
              phoneNumber: "",
              email: "",
              fullName: "",
              comment: "",
              adminFroentEnd: "",
              webAppFroentEnd: "",
              webAppBackEnd: "",
              attachement: null,
              moduleDetail: [],
            }}
            onSubmit={handleSubmit}
            validate={validateData}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              resetForm,
              setFieldValue,
              handleSubmit,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="lg:p-10 md:p-6 sm:p-3 p-3">
                  {currentPage === 1 ? (
                    <>
                      <CalcTitle
                        title="Which"
                        highlightTitle="service do you prefer to use?"
                        main="SERVICES"
                      />

                      <div>
                        <div className="about-survey mt-6">
                          <div className="px-3 flex items-center justify-center lg:gap-8 md:gap-8 sm:gap-3 gap-3">
                            {technologyDetail?.map((ele, idx) => (
                              <CalcInputCard
                                ele={ele}
                                value1={values?.parentServices}
                                value2={values?.mainServices}
                                type="checkbox"
                                handleBlur={handleBlur}
                                name={`parentServices[${idx}]`}
                                onChangeCheckStatus={(data) => {
                                  if (data?.parentServicesArray) {
                                    setFieldValue(
                                      `parentServices`,
                                      data?.parentServicesArray
                                    );
                                  }
                                  if (data?.parentServicesArrayname) {
                                    setFieldValue(
                                      `mainServices`,
                                      data?.parentServicesArrayname
                                    );
                                  }
                                }}
                              />
                              //    <label
                              //   key={ele?.id}
                              //   className="mb-3 relative cursor-pointer"
                              // >
                              //   {values.parentServices?.includes(ele?.id) ? (
                              //     <img
                              //       src="https://prometteursolutions.com/appcalc/bx-bg.png"
                              //       className="absolute top-0 right-0  z-20 transition-all ease-in-out "
                              //     />
                              //   ) : null}
                              //   <div
                              //     className={`${
                              //       values.parentServices?.includes(ele?.id)
                              //         ? "border border-yellow-300 shadow-lg scale-105"
                              //         : ""
                              //     }  border rounded-sm hover:scale-105 hover:shadow-lg transition-all ease-in-out relative group w-32 h-32`}
                              //   >
                              //     <div className="flex items-center cursor-pointer p-2 custom-control-label">
                              //       <input
                              //         className="hidden"
                              //         name={`parentServices[${idx}]`}
                              //         onChange={(e) => {
                              //           const isChecked = e.target.checked;
                              //           const itemId = ele?.id;
                              //           const parentServicesArray =
                              //             values.parentServices || [];

                              //           if (isChecked) {
                              //             parentServicesArray.push(itemId);
                              //           } else {
                              //             const index =
                              //               parentServicesArray.indexOf(itemId);
                              //             if (index !== -1) {
                              //               parentServicesArray.splice(
                              //                 index,
                              //                 1
                              //               );
                              //             }
                              //           }

                              //           setFieldValue(
                              //             `parentServices`,
                              //             parentServicesArray
                              //           );

                              //           const itemName = ele;
                              //           const parentServicesArrayname =
                              //             values.mainServices || [];

                              //           if (isChecked) {
                              //             parentServicesArrayname.push(
                              //               itemName
                              //             );
                              //           } else {
                              //             const index =
                              //               parentServicesArrayname.indexOf(
                              //                 itemName
                              //               );
                              //             if (index !== -1) {
                              //               parentServicesArrayname.splice(
                              //                 index,
                              //                 1
                              //               );
                              //             }
                              //           }

                              //           setFieldValue(
                              //             `mainServices`,
                              //             parentServicesArrayname
                              //           );
                              //         }}
                              //         checked={
                              //           values.parentServices?.includes(
                              //             ele?.id
                              //           ) || false
                              //         }
                              //         onBlur={handleBlur}
                              //         type="checkbox"
                              //       />

                              //       <div className="text-center w-full">
                              //         <img
                              //           className="w-10 mb-4 block mx-auto formbx_img"
                              //           alt="service4"
                              //           src={`https://prometteursolutions.com/appcalc_api/media/services/${ele?.icon}`}
                              //         />
                              //         <p className="block relative p-0 m-0 text-xs font-medium ">
                              //           {ele?.service}
                              //         </p>
                              //       </div>
                              //     </div>
                              //   </div>
                              // </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {currentPage === 2 ? (
                    <>
                      <CalcTitle
                        title="Select"
                        highlightTitle="Feature you want"
                        main="FEATURES"
                      />

                      <div>
                        <div className="about-survey mt-4">
                          <div className="px-3 grid grid-cols-12 items-center justify-center gap-4">
                            {screenState?.allModules?.map((ele, idx) => (
                              <label
                                key={ele?.id}
                                className="relative custom-control-label cursor-pointer lg:col-span-2 md:col-span-3 sm:col-span-6 col-span-6"
                              >
                                {values.modules?.includes(ele?.id) ? (
                                  <img
                                    src="https://prometteursolutions.com/appcalc/bx-bg.png"
                                    className="absolute top-0 right-0  z-20 transition-all ease-in-out "
                                  />
                                ) : null}

                                <div
                                  className={`${
                                    values.modules?.includes(ele?.id)
                                      ? "border border-yellow-300 shadow-lg scale-105"
                                      : ""
                                  } p-2 border rounded-sm hover:scale-105 hover:shadow-lg transition-all ease-in-out relative group h-32 w-auto`}
                                >
                                  <div
                                    onClick={() => {
                                      console.log(
                                        "newValues_ele?.features",
                                        ele?.features
                                      );
                                      setScreenState((prev) => {
                                        return {
                                          ...prev,
                                          featureModalOpen:
                                            values.modules?.includes(ele?.id)
                                              ? false
                                              : true,
                                          featuteId: ele?.features,
                                        };
                                      });
                                    }}
                                    className="flex mx-0 items-center cursor-pointer"
                                  >
                                    <input
                                      className="hidden"
                                      name={`modules[${idx}]`}
                                      onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        const itemId = ele?.id;
                                        const modulesArray =
                                          values.modules || [];

                                        if (isChecked) {
                                          modulesArray.push(itemId);
                                        } else {
                                          const index =
                                            modulesArray.indexOf(itemId);
                                          if (index !== -1) {
                                            modulesArray.splice(index, 1);
                                          }
                                        }

                                        setFieldValue(`modules`, modulesArray);

                                        const itemName = ele;
                                        const parentServicesArrayname =
                                          values?.moduleDetail || [];

                                        if (isChecked) {
                                          parentServicesArrayname.push(
                                            itemName
                                          );
                                        } else {
                                          const index =
                                            parentServicesArrayname.indexOf(
                                              itemName
                                            );
                                          if (index !== -1) {
                                            parentServicesArrayname.splice(
                                              index,
                                              1
                                            );
                                          }
                                        }

                                        setFieldValue(
                                          `moduleDetail`,
                                          parentServicesArrayname
                                        );
                                      }}
                                      checked={
                                        values.modules?.includes(ele?.id) ||
                                        false
                                      }
                                      onBlur={handleBlur}
                                      type="checkbox"
                                    />
                                    <div className="text-center w-full">
                                      <img
                                        className="w-10 mb-4 block mx-auto formbx_img"
                                        alt="service4"
                                        src={`https://prometteursolutions.com/appcalc_api/media/modules/${ele?.icon}`}
                                      />
                                      <p className="block relative p-0 m-0 text-xs font-medium">
                                        {ele?.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {currentPage === 3 ? (
                    <>
                      <CalcTitle
                        title="Which"
                        highlightTitle="Technology do you prefer to use?"
                        main="TECHNOLOGY"
                      />

                      <div>
                        {values?.mainServices?.map((ele) => (
                          <>
                            {ele?.id === "51" ? (
                              <>
                                <div className="mt-3" key={ele?.id}>
                                  <div className="w-full mb-3 p-2 flex items-center border">
                                    <div className="text-left flex items-center">
                                      <img
                                        className="lg:w-10 md:w-8 sm:w-6 w-6"
                                        alt="service4"
                                        src={`https://prometteursolutions.com/appcalc_api/media/services/${ele?.icon}`}
                                      />
                                      <span className="ml-2 sm:text-sm text-sm lg:text-base md:text-base font-medium">
                                        {ele?.service}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="grid grid-cols-12 items- justify-center gap-2 mt-2">
                                  <div className="lg:col-span-8 md:col-span-6 sm:col-span-12 col-span-12">
                                    <div>
                                      <h2 className="text-base font-medium mb-2">
                                        Web-Frontend
                                      </h2>
                                    </div>
                                    <div className="grid grid-cols-12 items-center gap-4">
                                      {webFroentTech?.map((ele, idx) => (
                                        <label
                                          key={ele?.tech_id}
                                          className="lg:col-span-3 relative md:col-span-6  custom-control-label cursor-pointer sm:col-span-6 col-span-6  h-28 w-28"
                                        >
                                          {values.webAppFroentEnd?.includes(
                                            ele?.name
                                          ) ? (
                                            <img
                                              src="https://prometteursolutions.com/appcalc/bx-bg.png"
                                              className="absolute top-0 right-0  z-20 transition-all ease-in-out "
                                            />
                                          ) : null}

                                          <div
                                            className={`${
                                              values.webAppFroentEnd?.includes(
                                                ele?.name
                                              )
                                                ? "border border-yellow-300 shadow-lg scale-105"
                                                : ""
                                            } p-2 border rounded-sm hover:scale-105 hover:shadow-lg transition-all ease-in-out relative group`}
                                          >
                                            <div className="flex mx-0 items-center cursor-pointer">
                                              <input
                                                className="hidden"
                                                type="radio"
                                                name="webAppFroentEnd"
                                                value={ele?.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={
                                                  values.webAppFroentEnd ===
                                                  ele?.name
                                                }
                                              />

                                              <div className="text-center w-full">
                                                <img
                                                  className="w-10 mb-4 block mx-auto formbx_img"
                                                  alt="service4"
                                                  src={`https://prometteursolutions.com/appcalc_api/media/technologies/${ele?.icon}`}
                                                />
                                                <p className="block relative p-0 m-0 text-xs font-medium">
                                                  {ele?.name}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </label>
                                      ))}
                                    </div>
                                  </div>

                                  <div className="lg:col-span-4 md:col-span-6 sm:col-span-12 col-span-12">
                                    <h2 className="text-base font-medium mb-2">
                                      Web-Backend
                                    </h2>

                                    <div className="grid grid-cols-12 items-center gap-4">
                                      {backendTech?.map((ele, idx) => (
                                        <label
                                          key={ele?.tech_id}
                                          className="lg:col-span-6 md:col-span-6 sm:col-span-6 col-span-6 relative cursor-pointer custom-control-label h-28 w-28"
                                        >
                                          {values.webAppBackEnd?.includes(
                                            ele?.name
                                          ) ? (
                                            <img
                                              src="https://prometteursolutions.com/appcalc/bx-bg.png"
                                              className="absolute top-0 right-0  z-20 transition-all ease-in-out "
                                            />
                                          ) : null}

                                          <div
                                            className={`${
                                              values.webAppBackEnd?.includes(
                                                ele?.name
                                              )
                                                ? "border border-yellow-300 shadow-lg scale-105"
                                                : ""
                                            } p-2 border rounded-sm hover:scale-105 hover:shadow-lg transition-all ease-in-out relative group`}
                                          >
                                            <div className="flex mx-0 items-center cursor-pointer">
                                              <input
                                                type="radio"
                                                className="hidden"
                                                name="webAppBackEnd"
                                                value={ele?.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={
                                                  values.webAppBackEnd ===
                                                  ele?.name
                                                }
                                              />
                                              <div className="text-center w-full">
                                                <img
                                                  className="w-10 mb-4 block mx-auto formbx_img"
                                                  alt="service4"
                                                  src={`https://prometteursolutions.com/appcalc_api/media/technologies/${ele?.icon}`}
                                                />
                                                <p className="block relative p-0 m-0 text-xs font-medium">
                                                  {ele?.name}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </label>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}

                            {ele?.id === "2" ? (
                              <>
                                <div className="mt-3" key={ele?.id}>
                                  <div className="w-full mb-3 p-2 flex items-center border">
                                    <div className="text-left flex items-center">
                                      <img
                                        className="lg:w-10 md:w-8 sm:w-6 w-6"
                                        alt="service4"
                                        src={`https://prometteursolutions.com/appcalc_api/media/services/${ele?.icon}`}
                                      />
                                      <span className="ml-2 sm:text-sm text-sm lg:text-base md:text-base font-medium">
                                        {ele?.service}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="px-3 grid grid-cols-12 items-center justify-center gap-4">
                                  {mobileAppTech?.map((ele, idx) => (
                                    <label
                                      key={ele?.tech_id}
                                      className="relative lg:col-span-2 custom-control-label cursor-pointer md:col-span-3 sm:col-span-6 col-span-6 w-28 h-28"
                                    >
                                      {values.mobile?.includes(ele?.name) ? (
                                        <img
                                          src="https://prometteursolutions.com/appcalc/bx-bg.png"
                                          className="absolute top-0 right-0  z-20 transition-all ease-in-out "
                                        />
                                      ) : null}
                                      <div
                                        className={`${
                                          values.mobile?.includes(ele?.name)
                                            ? "border border-yellow-300 shadow-lg scale-105"
                                            : ""
                                        } p-2 border rounded-sm hover:scale-105 hover:shadow-lg transition-all ease-in-out relative group`}
                                      >
                                        <div className="flex mx-0 items-center cursor-pointer">
                                          <input
                                            className="hidden"
                                            type="radio"
                                            name="mobile"
                                            value={ele?.name}
                                            onChange={() =>setFieldValue('mobile', ele?.name === 'Native Android' ? 'Native Android,Native IOS' : ele?.name === 'Native IOS' ? 'Native Android,Native IOS' : ele?.name )}
                                            onBlur={handleBlur}
                                            checked={
                                              values.mobile?.includes(ele?.name)
                                            }
                                          />
                                          <div className="text-center w-full">
                                            <img
                                              className="w-10 mb-4 block mx-auto formbx_img"
                                              alt="service4"
                                              src={`https://prometteursolutions.com/appcalc_api/media/technologies/${ele?.icon}`}
                                            />
                                            <p className="block relative p-0 m-0 text-xs font-medium">
                                              {ele?.name}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </label>
                                  ))}
                                </div>

                                <div className="mt-3">
                                  <label>
                                    <input
                                      value={values?.adminpanel}
                                      type="checkbox"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      name="adminpanel"
                                      checked={values?.adminpanel}
                                    />
                                    <span className="text-sm font-medium ml-2">
                                      Do you want admin panel?
                                    </span>
                                  </label>
                                </div>

                                {values?.adminpanel ? (
                                  <>
                                    <div className="px-3 grid grid-cols-12 items-start justify-center gap-4 mt-2">
                                      <div className="lg:col-span-8 md:col-span-8 sm:col-span-6 col-span-6">
                                        <div>
                                          <h2 className="lg:text-base md:text-base sm:text-sm text-sm font-medium mb-2">
                                            Admin Panel Frontend
                                          </h2>
                                        </div>

                                        <div className="grid grid-cols-12 items-center gap-4">
                                          {webFroentTech?.map((ele, idx) => (
                                            <label
                                              key={ele?.tech_id}
                                              className="lg:col-span-3 md:col-span-4 sm:col-span-12 col-span-12 cursor-pointer relative custom-control-label w-28 h-28"
                                            >
                                              {values.adminFroentEnd?.includes(
                                                ele?.name
                                              ) ? (
                                                <img
                                                  src="https://prometteursolutions.com/appcalc/bx-bg.png"
                                                  className="absolute top-0 right-0  z-20 transition-all ease-in-out "
                                                />
                                              ) : null}

                                              <div
                                                className={`${
                                                  values.adminFroentEnd?.includes(
                                                    ele?.name
                                                  )
                                                    ? "border border-yellow-300 shadow-lg scale-105"
                                                    : ""
                                                } p-2 border rounded-sm hover:scale-105 hover:shadow-lg transition-all ease-in-out relative group `}
                                              >
                                                <div className="flex mx-0 items-center cursor-pointer">
                                                  <input
                                                    className="hidden"
                                                    type="radio"
                                                    name="adminFroentEnd"
                                                    value={ele?.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={
                                                      values.adminFroentEnd ===
                                                      ele?.name
                                                    }
                                                  />
                                                  <div className="text-center w-full">
                                                    <img
                                                      className="w-10 mb-4 block mx-auto formbx_img"
                                                      alt="service4"
                                                      src={`https://prometteursolutions.com/appcalc_api/media/technologies/${ele?.icon}`}
                                                    />
                                                    <p className="block relative p-0 m-0 text-xs font-medium">
                                                      {ele?.name}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </label>
                                          ))}
                                        </div>
                                      </div>

                                      <div className=" lg:col-span-4 md:col-span-4 sm:col-span-6 col-span-6">
                                        <h2 className="lg:text-base md:text-base sm:text-sm text-sm font-medium mb-2">
                                          Admin Panel Backend
                                        </h2>

                                        <div className="grid grid-cols-12 items-center gap-4">
                                          {backendTech
                                            ?.slice(0, 1)
                                            .map((ele, idx) => (
                                              <label
                                                key={ele?.tech_id}
                                                className="col-span-6 relative custom-control-label cursor-pointer w-28 h-28"
                                              >
                                                {values.backend?.includes(
                                                  ele?.name
                                                ) ? (
                                                  <img
                                                    src="https://prometteursolutions.com/appcalc/bx-bg.png"
                                                    className="absolute top-0 right-0  z-20 transition-all ease-in-out "
                                                  />
                                                ) : null}
                                                <div
                                                  className={`${
                                                    values.backend?.includes(
                                                      ele?.name
                                                    )
                                                      ? "border border-yellow-300 shadow-lg scale-105"
                                                      : ""
                                                  } p-2 border rounded-sm hover:scale-105 hover:shadow-lg transition-all ease-in-out relative group sm:col-span-6 col-span-6`}
                                                >
                                                  <div className="flex mx-0 items-center cursor-pointer">
                                                    <input
                                                      className="hidden"
                                                      type="radio"
                                                      name="backend"
                                                      value={ele?.name}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      checked={
                                                        values.backend ===
                                                        ele?.name
                                                      }
                                                    />
                                                    <div className="text-center w-full">
                                                      <img
                                                        className="w-10 mb-4 block mx-auto formbx_img"
                                                        alt="service4"
                                                        src={`https://prometteursolutions.com/appcalc_api/media/technologies/${ele?.icon}`}
                                                      />
                                                      <p className="block relative p-0 m-0 text-xs font-medium">
                                                        {ele?.name}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </label>
                                            ))}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </>
                            ) : null}
                          </>
                        ))}
                      </div>
                    </>
                  ) : null}

                  {currentPage === 4 ? (
                    <>
                      <CalcTitle
                        title="Select"
                        highlightTitle="app quality"
                        main="APP QUALITY"
                      />

                      <div>
                        <div className="about-survey mt-4">
                          <div className="px-3 flex flex-wrap items-center justify-center gap-4">
                            {appQuality?.map((ele, idx) => (
                              <label
                                key={ele?.id}
                                className=" cursor-pointer relative custom-control-label w-28 h-28"
                              >
                                {values.appQuality?.includes(
                                  ele?.quality_id
                                ) ? (
                                  <img
                                    src="https://prometteursolutions.com/appcalc/bx-bg.png"
                                    className="absolute top-0 right-0  z-20 transition-all ease-in-out "
                                  />
                                ) : null}

                                <div
                                  className={`${
                                    values.appQuality?.includes(ele?.quality_id)
                                      ? "border border-yellow-300 shadow-lg scale-105"
                                      : ""
                                  } p-2 border rounded-sm hover:scale-105 hover:shadow-lg transition-all ease-in-out relative group `}
                                >
                                  <div className="flex mx-0 items-center cursor-pointer">
                                    <input
                                      className="hidden"
                                      type="radio"
                                      name="appQuality"
                                      value={ele?.quality_id}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.appQuality === ele?.quality_id
                                      }
                                    />

                                    {/* <input
                                      className="hidden"
                                      name={`appQuality[${idx}]`}
                                      onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        const itemId = ele?.quality_id;
                                        const appQualityArray =
                                          values.appQuality || [];

                                        if (isChecked) {
                                          appQualityArray.push(itemId);
                                        } else {
                                          const index =
                                            appQualityArray.indexOf(itemId);
                                          if (index !== -1) {
                                            appQualityArray.splice(index, 1);
                                          }
                                        }

                                        setFieldValue(
                                          `appQuality`,
                                          appQualityArray
                                        );
                                      }}
                                      checked={
                                        values.appQuality?.includes(
                                          ele?.quality_id
                                        ) || false
                                      }
                                      onBlur={handleBlur}
                                      type="checkbox"
                                    /> */}
                                    <div className="text-center w-full">
                                      <img
                                        className="w-10 mb-4 block mx-auto formbx_img"
                                        alt="service4"
                                        src={`https://prometteursolutions.com/appcalc_api/media/appquality/${ele?.icon}`}
                                      />
                                      <p className="block relative p-0 m-0 text-xs font-medium">
                                        {ele?.type}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {currentPage === 5 ? (
                    <>
                      <CalcTitle
                        title="Almost"
                        highlightTitle="Done"
                        main="Contact"
                        noteText="Let us know where we should send your final estimation"
                      />

                      <div>
                        <div className="about-survey mt-4 relative">
                          <div className="lg:px-3 md:px-3 sm:px-1 px-1 ">
                            <div className="border relative lg:p-4 md:p-4 sm:p-2 p-2">
                              <div className="grid grid-cols-12 gap-6 items-">
                                <div className="lg:col-span-6 w-full md:col-span-6 sm:col-span-12 col-span-12 ">
                                  <FontAwesomeIcon
                                    className="text-sm mx-1 text-[#f6c40e]"
                                    icon={faUser}
                                  />
                                  <label className="text-sm font-medium">
                                    Full Name *
                                  </label>
                                  <input
                                    name="fullName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.fullName}
                                    className="border-2 max-w-full lg:w-full md:w-full sm:w-56 w-56 block p-2"
                                    placeholder="Type Something"
                                  />
                                  {errors?.fullName && touched?.fullName && (
                                    <p className="text-sm text-rose-500">
                                      {errors.fullName}
                                    </p>
                                  )}
                                </div>

                                <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12 ">
                                  <FontAwesomeIcon
                                    className="text-sm mx-1 text-[#f6c40e]"
                                    icon={faEnvelope}
                                  />
                                  <label className="text-sm font-medium">
                                    Email Id *
                                  </label>
                                  <input
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.email}
                                    className="border-2 max-w-full lg:w-full md:w-full sm:w-56 w-56 block p-2"
                                    placeholder="Type Something"
                                  />
                                  {errors?.email && touched?.email && (
                                    <p className="text-sm text-rose-500">
                                      {errors.email}
                                    </p>
                                  )}
                                </div>
                                <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12 ">
                                  <FontAwesomeIcon
                                    className="text-sm mx-1 text-[#f6c40e]"
                                    icon={faPhone}
                                  />
                                  <label className="text-sm font-medium">
                                    Phone Number *
                                  </label>
                                  <input
                                    name="phoneNumber"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.phoneNumber}
                                    className="border max-w-full lg:w-full md:w-full sm:w-56 w-56 block p-2"
                                    placeholder="Type Something"
                                  />
                                  {errors?.phoneNumber &&
                                    touched?.phoneNumber && (
                                      <p className="text-sm text-rose-500">
                                        {errors.phoneNumber}
                                      </p>
                                    )}
                                </div>
                                <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12">
                                  <FontAwesomeIcon
                                    className="text-sm mx-1 text-[#f6c40e]"
                                    icon={faMapMarkedAlt}
                                  />
                                  <label className="text-sm font-medium">
                                    City *
                                  </label>
                                  <input
                                    name="city"
                                    id="city"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.city}
                                    className="border max-w-full lg:w-full md:w-full sm:w-56 w-56 block p-2"
                                    placeholder="Type Something"
                                  />

                                  {errors.city && touched.city ? (
                                    <p className="text-rose-500 text-sm">
                                      {errors.city}
                                    </p>
                                  ) : null}
                                </div>

                                <div className="col-span-12 ">
                                  <FontAwesomeIcon
                                    className="text-sm mx-1 text-[#f6c40e]"
                                    icon={faCircleCheck}
                                  />
                                  <label className="text-sm font-medium">
                                    Selected Features
                                  </label>
                                  <div className="grid lg:grid-cols-3 md:grid-col-3 sm:grid-cols-1 grid-cols-1">
                                    {values?.moduleDetail?.map((ele) => (
                                      <div className="flex items-center mt-2">
                                        <img
                                          className="w-5 h-5"
                                          src={`https://prometteursolutions.com/appcalc_api/media/modules/${ele?.icon}`}
                                        />
                                        <p className="text-sm font-normal ml-2">
                                          {ele?.name}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                </div>

                                <div className="col-span-12 ">
                                  <FontAwesomeIcon
                                    className="text-sm mx-1 text-[#f6c40e]"
                                    icon={faComment}
                                  />
                                  <label className="text-sm font-medium">
                                    Comment
                                  </label>
                                  <textarea
                                    rows={5}
                                    name="comment"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.comment}
                                    className="border-2 max-w-full lg:w-full md:w-full sm:w-56 w-56 block p-2"
                                    placeholder="Type Something"
                                  />
                                </div>

                                <div className="col-span-12 ">
                                  <div>
                                    <FontAwesomeIcon
                                      className="text-sm mx-1 text-[#f6c40e]"
                                      icon={faComment}
                                    />
                                    <label className="text-sm font-medium">
                                      Attachement
                                    </label>
                                  </div>
                                  <input
                                    type="file"
                                    name="attachement"
                                    onChange={(e) =>
                                      setFieldValue(
                                        "attachement",
                                        e.target.files[0]
                                      )
                                    }
                                    onBlur={handleBlur}
                                    className="max-w-full lg:w-full md:w-full sm:w-56 w-56 block border-2 p-2"
                                    placeholder="Type Something"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="fixed bottom-0 left-0 right-0 z-50 block bg-[#F0F4F5]">
                    <div className=" lg:mx-20 md:mx-16 sm:mx-4 mx-4 ">
                      <div className="relative lg:py-6 md:py-6 sm:py-2 py-2 w-full">
                        <div className="lg:grid md:grid sm:flex flex grid-cols-12 gap-2 items-center justify-between">
                          <div className="lg:col-span-3 md:col-span-3 sm:col-span-12 col-span-12">
                            {currentPage === 1 ? null : (
                              <button
                                className="mx-2 rounded-full lg:px-6 md:px-4 sm:px-3 px-3 lg:py-3 md:py-3 sm:py-2 py-2 text-base font-medium bg-[#ffcf1e] hover:shadow-lg hover:bg-black hover:text-white transition-all ease-in-out"
                                type="button"
                                onClick={() => prevPageHandler()}
                              >
                                <FontAwesomeIcon
                                  className="sm:hidden hidden lg:inline md:inline sm:text-xs text-xs md:text-base lg:text-base"
                                  icon={faAngleLeft}
                                />
                                <FontAwesomeIcon
                                  className="sm:inline inline lg:inline md:inline sm:text-xs text-xs md:text-base lg:text-base my-auto"
                                  icon={faAngleLeft}
                                />
                                <span className="ml-2 lg:inline md:inline sm:hidden hidden">
                                  Prev
                                </span>
                              </button>
                            )}
                          </div>
                          <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12 flex flex-wrap items-center w-full justify-around">
                            <div className="flex items-center w-full justify-around">
                              <div
                                className={`${
                                  currentPage > 1
                                    ? "border-[#ffcf1e] border-4 "
                                    : ""
                                } ${
                                  currentPage === 1
                                    ? "bg-[#ffcf1e] border-[#ffcf1e]"
                                    : ""
                                } flex h-8 w-8 p-3 text-sm rounded-full  items-center justify-center border-2`}
                              >
                                <span>1</span>
                              </div>
                              <div
                                className={`h-[3px] ${
                                  currentPage === 2 || currentPage > 2
                                    ? "bg-[#ffcf1e]"
                                    : "bg-[#D3D3D3]"
                                }  mt-1 w-full`}
                              ></div>
                              <div
                                className={`${
                                  currentPage > 2
                                    ? "border-[#ffcf1e] border-4 "
                                    : ""
                                } ${
                                  currentPage === 2
                                    ? "bg-[#ffcf1e] border-[#ffcf1e]"
                                    : ""
                                } flex h-8 w-8 p-3 text-sm rounded-full  items-center justify-center border-2`}
                              >
                                <span>2</span>
                              </div>
                              <div
                                className={`h-[3px] ${
                                  currentPage === 3 || currentPage > 3
                                    ? "bg-[#ffcf1e]"
                                    : "bg-[#D3D3D3]"
                                }  mt-1 w-full`}
                              ></div>
                              <div
                                className={`${
                                  currentPage > 3
                                    ? "border-[#ffcf1e] border-4 "
                                    : ""
                                } ${
                                  currentPage === 3
                                    ? "bg-[#ffcf1e] border-[#ffcf1e]"
                                    : ""
                                } flex h-8 w-8 p-3 text-sm rounded-full  items-center justify-center border-2`}
                              >
                                <span>3</span>
                              </div>
                              <div
                                className={`h-[3px] ${
                                  currentPage === 4 || currentPage > 4
                                    ? "bg-[#ffcf1e]"
                                    : "bg-[#D3D3D3]"
                                }  mt-1 w-full`}
                              ></div>
                              <div
                                className={`${
                                  currentPage > 4
                                    ? "border-[#ffcf1e] border-4 "
                                    : ""
                                } ${
                                  currentPage === 4
                                    ? "bg-[#ffcf1e] border-[#ffcf1e]"
                                    : ""
                                } flex h-8 w-8 p-3 text-sm rounded-full  items-center justify-center border-2`}
                              >
                                <span>4</span>
                              </div>
                            </div>
                          </div>

                          <div className="lg:col-span-3 md:col-span-3 sm:col-span-12 col-span-12 ">
                            {currentPage === 5 ? (
                              <button
                                className="mx-2  flex items-center justify-center sm:h-10 sm:w-10 w-10 h-10 lg:w-auto lg:h-auto md:w-auto md:h-auto float-right rounded-full lg:px-6 md:px-4 sm:px-3 px-3 lg:py-3 md:py-3 sm:py-2 py-2 lg:text-base md:font-base sm:font-sm font-sm font-medium bg-[#ffcf1e] hover:shadow-lg hover:bg-black hover:text-white transition-all ease-in-out"
                                type="submit"
                              >
                                <span className="mr-2 lg:inline md:block sm:inline hidden">
                                 Submit
                                </span>
                                  <FontAwesomeIcon
                                    className="sm:inline ml-2 inline lg:inline md:inline sm:text-xs text-xs md:text-base lg:text-base"
                                    icon={faArrowRight}
                                  />
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="float-right mx-2 rounded-full lg:px-6 md:px-4 sm:px-3 px-3 lg:py-3 md:py-3 sm:py-2 py-2 text-base font-medium bg-[#ffcf1e] hover:shadow-lg hover:bg-black hover:text-white transition-all ease-in-out"
                                onClick={() => nextPageHandler(errors, values)}
                              >
                                <span className="mr-2 lg:inline md:inline sm:hidden hidden">
                                  Next
                                </span>
                                <FontAwesomeIcon
                                  className="sm:hidden hidden lg:inline md:inline sm:text-xs text-xs md:text-base lg:text-base"
                                  icon={faAngleRight}
                                />
                                <FontAwesomeIcon
                                  className="sm:inline inline lg:inline md:inline sm:text-xs text-xs md:text-base lg:text-base"
                                  icon={faAngleRight}
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      {screenState?.featureModalOpen && (
        <FeatureModal
          featureModalOpen={true}
          subModules = {screenState?.subModules}
          setSubmodules={(ele) => {
            setScreenState((prev) => {
              return {
                ...prev,
                subModules: ele,
              };
            });
          }}
          setFeatureModalOpen={(flag) => {
            setScreenState((prev) => {
              return {
                ...prev,
                featureModalOpen: flag,
              };
            });
          }}
          features={screenState?.featuteId}
        />
      )}
    </div>
  );
};

export default Services;
