import { createStore, applyMiddleware, compose } from "redux";
import  thunkMiddleware  from "redux-thunk";
import logger from "redux-logger";
import { loadState } from "./localstorage";
import reducer from "../reducers";


function saveToLocalStorage(state) {
    try{
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    }catch(err){
        return undefined
    }
}


// create redux store 

let persitedState = loadState()

const store = createStore(reducer, persitedState, compose(
    applyMiddleware(thunkMiddleware,logger),
     //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
     window.devToolsExtension ? window.devToolsExtension() : function (f) {
        return f;
    }
))

const unsubscribe = store.subscribe(() => {
    const state = store.getState();
    saveToLocalStorage(state);
});

export default store;