import { Modal, notification } from "antd";
import React from "react";
import { deleteappCalcApis } from "../../../graphs/appCalculator/appcalcApis";
import { useSelector } from "react-redux";

const DeleteModal = ({
  deleteModal,
  setDeleteModal,
  deleteId,
  title,
  type,
}) => {

  const AuthToken = useSelector((state)=> state?.auth?.authToken)
  const sidebarToggle = useSelector((state)=> state?.toggleBtn?.toggle)
console.log("sidebar toggle value", sidebarToggle)
  console.log(deleteId, title, type);

  const handleDelete = async (id, endpoint) => {
    console.log(id, endpoint);
    try {
      await deleteappCalcApis(AuthToken, endpoint, id).then((res) => {
    notification["success"]({
      message:"field deleted Successfully !"
    })
    setDeleteModal(false)
        console.log("delete data response", res);
        // setAllUserData(res?.data)
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Modal
        width={400}
        open={deleteModal}
        onOk={() => setDeleteModal(false)}
        onCancel={() => setDeleteModal(false)}
        destroyOnClose
        centered
        style={{left: `${sidebarToggle ? '120' : '150px'}`,}}

        footer={null}
      >
        <div className="text-center">
          <h2 className=" text-base">
            Are you sure want to delete this field ?
          </h2>
          <div className="flex items-center justify-center mt-3">
            <button
              onClick={() => setDeleteModal(false)}
              className="py-2 px-4 bg-slate-400 border hover:border-slate-700 border-slate-400 rounded-lg backdrop-blur"
            >
              Cancel
            </button>
            <button
              onClick={() => handleDelete(deleteId, type)}
              className="ml-2 py-2 px-4 bg-yellow-400 border hover:border-yellow-700 border-yellow-400 rounded-lg backdrop-blur"
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteModal;
