import React, { useEffect, useState } from "react";
import Header from "../../../components/ui/Header";
import { Formik } from "formik";
import {
  editappCalcApis,
  getResponseDetails,
  getServicesTech,
  getTechnologyMain,
  getappCalcApis,
} from "../../../graphs/appCalculator/appcalcApis";
import { useSelector } from "react-redux";
import { json, useLocation } from "react-router-dom";
import { notification } from "antd";

const EditResponseDetails = () => {
  const [allIndustryData, setAllIndustryData] = useState([]);
  const [responseDetails, setResponseDetails] = useState([]);
  const [allFeatureData, setAllFeatureData] = useState([]);
  const [appQuealityData, setAllAppQualityData] = useState([]);
  const [mobileTech, setMobileTech] = useState([]);
  const [mobileApi, setMobileApi] = useState([]);
  const [webTech, setWebTech] = useState([]);
  const [cmsTech, setCmsTech] = useState([]);
  const [technologyDettail, setTechnologyDetail] = useState([]);
  const AuthToken = useSelector((state) => state?.auth?.authToken);
  const { state } = useLocation();

  const getIndustryData = async () => {
    try {
      await getappCalcApis(AuthToken, "industry").then((res) => {
        setAllIndustryData(res?.data?.data);
        // setAllUserData(res?.data)
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getAllFeatureData = async () => {
    try {
      await getappCalcApis(AuthToken, "feature").then((res) => {
        setAllFeatureData(res?.data?.data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getAllCalculateResponseData = async () => {
    try {
      await getResponseDetails(AuthToken, state).then((res) => {
        setResponseDetails(res?.data?.data);
        const data = res?.data?.data[0]?.mainservice?.map((ele, idx) => {
          return ele?.id;
        });
        setTechnologyDetail(data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getAppQualityHandler = async () => {
    try {
      await getappCalcApis(AuthToken, "app_quality").then((res) => {
        setAllAppQualityData(res?.data?.data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getTechnologyMainData = async () => {
    try {
      await getTechnologyMain(AuthToken, "1").then((res) => {
        setMobileTech(res?.data?.data);
      });

      await getTechnologyMain(AuthToken, "3").then((res) => {
        setMobileApi(res?.data?.data);
      });

      await getTechnologyMain(AuthToken, "2").then((res) => {
        setWebTech(res?.data?.data);
      });
      await getServicesTech(AuthToken, "51").then((res) => {
        setCmsTech(res?.data?.data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (value) => {
    console.log("calc values => ",value)
    const featureDescriptionsArray = Object.keys(
      value.feature_descriptions
    ).map((key) => ({
      featureid: key,
      featuredesc: value.feature_descriptions[key],
    }));
    const sortedDescription = value?.feature_ids.map((id) => {
      const matchingObject = featureDescriptionsArray.find(
        (obj) => obj.featureid === id
      );
      return matchingObject ? matchingObject.featuredesc : "";
    });

    const formData = new FormData();

    formData.append("main_service", JSON.stringify(value?.parentServices));
    formData.append("feature_ids", JSON.stringify(value?.feature_ids));
    formData.append("descriptions", JSON.stringify(sortedDescription));
    formData.append("app_quality", value?.app_quality);
    formData.append("industry", value?.industry);
    formData.append("mobile", JSON.stringify(value?.mobile));
    formData.append("mobileapi", value?.mobileapi);
    formData.append("web-frontend", value?.webfrontend);
    formData.append("web-backend", value?.webbackend);
    formData.append("web-cms", value?.webcms);
    formData.append("enquiry_id", value?.enquiry_id);

    // try {
    //   await editappCalcApis(AuthToken, "proposalenquiry", formData).then(
    //     (res) => {
    //       notification["success"]({
    //         message: "Response updated successfully",
    //       });
    //     }
    //   );
    // } catch (err) {
    //   throw err;
    // }
  };

  useEffect(() => {
    getTechnologyMainData();
    getAllCalculateResponseData();
    getAppQualityHandler();
    getIndustryData();
    getAllFeatureData();
  }, []);
  return (
    <section class="py-8">
      <Header title={"Edit Calculator Response Details"} />
      <section class="py-2">
        {responseDetails?.map((ele, idx) => {
          return (
            <div key={idx} class="container px-4 mx-auto">
              <div className="bg-white p-4 rounded-lg w-full">
                <div className="my-2">
                  <h2 className="lg:text-xl lg:font-bold md:text-xl md:font-bold sm:text- mb-2">
                    {ele?.proposalData?.name}
                  </h2>
                  <hr />
                  <div className="flex flex-wrap gap-2 items-center mt-2">
                    <div className="flex items-center gap-2 text-sm  mx-4">
                      <span className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="25"
                          height="25"
                          viewBox="0 0 30 30.000001"
                          preserveAspectRatio="xMidYMid meet"
                          version="1.0"
                        >
                          <defs>
                            <clipPath id="id1">
                              <path
                                fill="#C2C9D2"
                                d="M 3.460938 6.5625 L 26.539062 6.5625 L 26.539062 24.707031 L 3.460938 24.707031 Z M 3.460938 6.5625 "
                                clipRule="nonzero"
                              />
                            </clipPath>
                          </defs>
                          <g clipPath="url(#id1)">
                            <path
                              fill="#FFCF1E"
                              d="M 24.230469 11.101562 L 15 16.769531 L 5.769531 11.101562 L 5.769531 8.832031 L 15 14.503906 L 24.230469 8.832031 Z M 24.230469 6.5625 L 5.769531 6.5625 C 4.492188 6.5625 3.472656 7.578125 3.472656 8.832031 L 3.460938 22.441406 C 3.460938 23.695312 4.492188 24.707031 5.769531 24.707031 L 24.230469 24.707031 C 25.507812 24.707031 26.539062 23.695312 26.539062 22.441406 L 26.539062 8.832031 C 26.539062 7.578125 25.507812 6.5625 24.230469 6.5625 "
                              fillOpacity="1"
                              fillRule="nonzero"
                            />
                          </g>
                        </svg>
                      </span>
                      <p>{ele?.enquirydata?.email}</p>
                    </div>
                    <div className="flex items-center gap-2 text-sm  mx-4">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-telephone-fill"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="#FFCF1E"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                          />{" "}
                        </svg>
                      </span>
                      <p>{ele?.enquirydata?.contact}</p>
                    </div>
                    <div className="flex items-center gap-2 text-sm  mx-4">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-calendar-fill"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="#FFCF1E"
                            d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z"
                          />{" "}
                        </svg>
                      </span>
                      <p>{ele?.enquirydata?.created} </p>
                    </div>
                  <div className="flex items-center gap-2 text-sm  mx-4">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-message-circle"
                      >
                        <path
                          fill="#FFCF1E"
                          d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"
                        ></path>
                      </svg>
                    </span>
                    <p>{ele?.enquirydata?.comment}</p>
                  </div>
                  </div>
                </div>
              </div>

              <div className="bg-white p-4 rounded-lg w-full mt-3">
                <div className="my-2">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      main_service: ele?.mainservice ? ele?.mainservice : [],
                      feature_ids:
                        ele?.proposalData?.feature_ids?.split(",") || [],
                      descriptions:
                        JSON.parse(ele?.proposalData?.descriptions) || [],
                      // descriptions:  ele?.proposalData?.descriptions?.split(",") || [],
                      // descriptions: ele?.proposalData?.feature_ids?.split(","),
                      //   descriptions: allFeatureData.filter((feature) =>
                      //   ele?.proposalData?.feature_ids?.includes(feature.id) ? '' : ''
                      // ),
                      feature_descriptions: [],
                      app_quality: ele?.proposalData?.app_quality
                        ? ele?.proposalData?.app_quality
                        : "",
                      industry: ele?.enquirydata?.industry,
                      // mobile: ele?.technology?.mobile ? ele?.technology?.mobile : [],
                      mobile: ele?.technology?.mobile
                        ? ele?.technology?.mobile
                        : [],
                      mobileapi: ele?.technology?.mobileapi
                        ? ele?.technology?.mobileapi
                        : "",

                      webfrontend: ele?.technology?.["web-frontend"]
                        ? ele?.technology?.["web-frontend"]
                        : "",
                      webbackend: ele?.technology?.["web-backend"]
                        ? ele?.technology?.["web-backend"]
                        : "",
                      webcms: ele?.technology?.["web-cms"]
                        ? ele?.technology?.["web-cms"]
                        : "",
                      enquiry_id: ele?.enquirydata?.id,
                      enqmodules: ele?.enqmodules,
                      parentServices: technologyDettail
                        ? technologyDettail
                        : [],
                    }}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      handleBlur,
                      handleChange,
                      resetForm,
                      setFieldValue,
                      handleSubmit,
                      touched,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="flex flex-col ">
                          <label>Select Application Category*</label>
                          <select
                            name="industry"
                            id="industry"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.industry}
                            className="border p-2 rounded mt-2 outline-0"
                          >
                            <option value="">Select Option</option>
                            {allIndustryData?.map((ele) => (
                              <option
                                key={ele?.id}
                                // defaultValue={values?.industry === ele?.id}
                                value={ele?.id}
                              >
                                {ele?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <label>Services*</label>

                        {values?.main_service?.map((item, idx) => (
                          <>
                            {item?.service === "Mobile App Development" ? (
                              <>
                                <div>
                                  <input
                                    id="parentServices"
                                    name={`parentServices[${idx}]`} // Use template literals to specify the array index
                                    type="checkbox"
                                    onChange={(e) => {
                                      const isChecked = e.target.checked;
                                      const itemId = item?.id;
                                      const parentServicesArray =
                                        values.parentServices || []; // Initialize the array if it's empty

                                      if (isChecked) {
                                        // Add the item's id to the array
                                        parentServicesArray.push(itemId);
                                      } else {
                                        // Remove the item's id from the array
                                        const index =
                                          parentServicesArray.indexOf(itemId);
                                        if (index !== -1) {
                                          parentServicesArray.splice(index, 1);
                                        }
                                      }

                                      setFieldValue(
                                        `parentServices`,
                                        parentServicesArray
                                      ); // Update the parentServices array
                                    }}
                                    checked={
                                      values.parentServices?.includes(
                                        item?.id
                                      ) || false
                                    }
                                    defaultChecked={values.main_service[
                                      idx
                                    ]?.id.includes(item?.id)}
                                  />
                                </div>
                                <h2 className="text-base font-medium mt-2">
                                  {item?.service}
                                </h2>

                                <div>
                                  {mobileTech?.map((ele) => (
                                    <div
                                      key={ele?.tech_id}
                                      className="flex items-center"
                                    >
                                      <label className="flex ml-2 w-fit">
                                        <input
                                          type="checkbox"
                                          name="mobile"
                                          value={ele?.name}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          checked={values.mobile.includes(
                                            ele?.name
                                          )}
                                        />
                                        <span className="text-sm  ml-2">
                                          {ele?.name}
                                        </span>
                                      </label>

                                      {/* <input type="checkbox" id="mobile" name="mobile" defaultChecked={values?.mobile === ele?.name ? true : false} />
                                                      <span className="text-sm ml-2">{ele?.name}</span> */}
                                    </div>
                                  ))}
                                </div>

                                <div className="flex flex-col ">
                                  <h2 className="text-base font-medium mt-2">
                                    Mobile Application API
                                  </h2>
                                  <div>
                                    {mobileApi?.map((ele) => (
                                      <div
                                        key={ele?.tech_id}
                                        className="flex items-center"
                                      >
                                        <label className="flex ml-2 w-fit">
                                          <input
                                            type="radio"
                                            name="mobileapi"
                                            value={ele?.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={
                                              values.mobileapi === ele?.name
                                            }
                                          />
                                          <span className="text-sm  ml-2">
                                            {ele?.name}
                                          </span>
                                        </label>

                                        {/* <input name="mobileapi" id="mobileapi" type="radio"  defaultChecked={values?.mobileapi === ele?.name} />
                                  <span className="text-sm ml-2">{ele?.name}</span> */}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </>
                            ) : null}

                            {item?.service === "Web App Development" ? (
                              <>
                                <div>
                                  {/* <input
                               id="parentServices"
                                name="parentServices" 
                               type="checkbox"
                              //  value={item?.id} 
                               onChange={() => setFieldValue("parentServices[idx]", item?.id)}
                               onBlur={handleBlur}
                              //  defaultChecked={values.main_service.includes(values?.main_service[idx])}
                              defaultChecked={values.parentServices[idx]?.id.includes(item?.id)}
                              // onChange={()=> setFieldValue(main_service, ["2"])} 
                              // defaultChecked={item?.service === "Mobile App Development"} 
                              /> */}

                                  <input
                                    id="parentServices"
                                    name={`parentServices[${idx}]`} // Use template literals to specify the array index
                                    type="checkbox"
                                    onChange={(e) => {
                                      const isChecked = e.target.checked;
                                      const itemId = item?.id;
                                      const parentServicesArray =
                                        values.parentServices || []; // Initialize the array if it's empty

                                      if (isChecked) {
                                        // Add the item's id to the array
                                        parentServicesArray.push(itemId);
                                      } else {
                                        // Remove the item's id from the array
                                        const index =
                                          parentServicesArray.indexOf(itemId);
                                        if (index !== -1) {
                                          parentServicesArray.splice(index, 1);
                                        }
                                      }

                                      setFieldValue(
                                        `parentServices`,
                                        parentServicesArray
                                      ); // Update the parentServices array
                                    }}
                                    // checked={values.parentServices?.includes(item?.id) || false}
                                    checked={
                                      values.parentServices?.includes(
                                        item?.id
                                      ) || false
                                    }
                                    defaultChecked={values.main_service[
                                      idx
                                    ]?.id.includes(item?.id)}
                                  />
                                </div>

                                <div className="flex flex-col ">
                                  <h2 className="text-base font-medium mt-2">
                                    Web App Development
                                  </h2>
                                  <h2 className="text-sm font-medium">
                                    Web Frontend
                                  </h2>
                                  <div>
                                    {webTech?.map((ele) => (
                                      <div
                                        key={ele?.tech_id}
                                        className="flex items-center"
                                      >
                                        <label className="flex ml-2 w-fit">
                                          <input
                                            type="radio"
                                            name="webfrontend"
                                            value={ele?.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={
                                              values.webfrontend === ele?.name
                                            }
                                          />
                                          <span className="text-sm  ml-2">
                                            {ele?.name}
                                          </span>
                                        </label>

                                        {/* <input name="webfrontend" id="webfrontend" onSelect={() => setFieldValue("webfrontend", ele?.name)} type="radio" defaultChecked={values?.webfrontend === ele?.name} />
                            <span className="text-sm ml-2">{ele?.name}</span> */}
                                      </div>
                                    ))}
                                  </div>
                                  <div>
                                    <h2 className="text-sm font-medium">
                                      Web Backend
                                    </h2>
                                    {mobileApi?.map((ele) => (
                                      <div
                                        key={ele?.tech_id}
                                        className="flex items-center"
                                      >
                                        <label className="flex ml-2 w-fit">
                                          <input
                                            type="radio"
                                            name="webbackend"
                                            value={ele?.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={
                                              values.webbackend === ele?.name
                                            }
                                          />
                                          <span className="text-sm  ml-2">
                                            {ele?.name}
                                          </span>
                                        </label>

                                        {/* <input name="webbackend" id="webbackend" onSelect={() => setFieldValue("webbackend", ele?.name)} type="radio" defaultChecked={values?.webbackend === ele?.name} />
                            <span className="text-sm ml-2">{ele?.name}</span> */}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="my-2">
                                  <h2 className="text-sm font-medium">
                                    Web CMS
                                  </h2>
                                  {cmsTech?.map((ele) => (
                                    <div
                                      key={ele?.id}
                                      className="flex items-center"
                                    >
                                      {/* <input name="webcms"  type="radio" /> */}

                                      <label className="flex ml-2 w-fit">
                                        <input
                                          type="radio"
                                          name="webcms" // Make sure the name is the same for all radio buttons
                                          value={ele?.service} // Set the value to the option's identifier
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          checked={
                                            values.webcms === ele?.service
                                          }
                                        />
                                        <span className="text-sm  ml-2">
                                          {ele?.service}
                                        </span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </>
                            ) : null}
                          </>
                        ))}

                        <hr />
                        <div className="mt-2">
                          <h2 className="text-sm font-medium">
                            Modules and features*
                          </h2>

                        <div className="w-full overflow-hidden  overflow-x-auto">
                          <table className="table-auto w-full ">
                            <thead>
                              <tr className="text-sm text-gray-500 text-left bg-slate-100">
                                <th className="font-medium text-base">
                                  Module
                                </th>
                                <th className="font-medium text-base">
                                  Feature
                                </th>
                                <th className="font-medium text-base">
                                  Description
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {allFeatureData?.map((ele, idx) => (
                                <tr className="">
                                  <td className="font-medium p-2 ">
                                    <div className="flex items-center">
                                      <input
                                        type="checkbox"
                                        name={`feature_ids[${idx}]`}
                                        checked={values.feature_ids.includes(
                                          ele.id
                                        )}
                                        onChange={() => {
                                          const isChecked =
                                            values.feature_ids.includes(ele.id);
                                          const newFeatureIds = isChecked
                                            ? values.feature_ids.filter(
                                                (id) => id !== ele.id
                                              )
                                            : [...values.feature_ids, ele.id];
                                          setFieldValue(
                                            "feature_ids",
                                            newFeatureIds
                                          );
                                        }}
                                        onBlur={handleBlur}
                                      />
                                      <p className="ml-2 font-medium lg:text-lg md:text-lg sm:text-sm text-sm">{ele?.module_name}</p>
                                    </div>
                                  </td>

                                  <td className="font-medium lg:text-lg md:text-lg sm:text-sm text-sm p-2">
                                    {ele?.name}
                                  </td>

                                  {/* <td className="font-medium p-2">
                                    <textarea
                                      rows="3"
                                      value={
                                        values?.feature_ids?.includes(
                                          ele?.id
                                        ) && values?.descriptions[idx]
                                          ? values?.descriptions[idx]
                                          : ""
                                      }
                                      onChange={(event) => {
                                        handleChange(event);

                                        setFieldValue(
                                          `descriptions[${idx}]`,
                                          values?.feature_ids?.includes(
                                            ele.id
                                          ) && event.target.value
                                            ? event.target.value
                                            : ""
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      name={`descriptions[${idx}]`}
                                      id={`descriptions[${idx}]`}
                                      className="outline-0 border rounded w-full"
                                    />
                                  </td> */}

                                  <td className="font-medium p-2 ">
                                    {/* {values.feature_ids.includes(ele.id) && ( */}
                                    <textarea
                                      rows="4"
                                      className="border rounded outline-none lg:w-72 md:w-72 sm:w-36 w-36"
                                      name={`feature_descriptions[${ele.id}]`}
                                      value={
                                        values.feature_descriptions[ele.id] ||
                                        ""
                                      }
                                      disabled={
                                        !values?.feature_ids?.includes(ele.id)
                                      }
                                      onChange={(e) => {
                                        const newDescriptions = {
                                          ...values.feature_descriptions,
                                          [ele.id]: e.target.value,
                                        };
                                        setFieldValue(
                                          "feature_descriptions",
                                          newDescriptions
                                        );
                                      }}
                                    />
                                    {/* )} */}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          </div>
                        </div>
                        <hr />

                        <div className="mb-4">
                          <label className="mb-1 text-sm font-medium">
                            App Quality*
                          </label>
                          <div className="">
                            {appQuealityData?.map((ele) => (
                              <label className="flex ml-2 w-fit">
                                <input
                                  type="radio"
                                  name="app_quality" // Make sure the name is the same for all radio buttons
                                  value={ele?.quality_id} // Set the value to the option's identifier
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  checked={
                                    values.app_quality === ele?.quality_id
                                  }
                                />
                                <span className="text-sm  ml-2">
                                  {ele?.type}
                                </span>
                              </label>
                            ))}
                          </div>
                        </div>
                        <div className="w-full">
                          <button
                            type="submit"
                            className="w-full rounded px-4 py-2 bg-slate-700 text-white"
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </section>
  );
};

export default EditResponseDetails;
