import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { getProposalDetails } from '../../../../graphs/getProposals'
import { useSelector } from 'react-redux'

const ViewProposalModal = ({viewProposalModal, setViewProposalModal, proposalId}) => {
  const AuthToken = useSelector((state)=> state?.auth?.authToken)
  const [proposalData, setProposalData] = useState()

    const getProposalDetail = async() => {
      try{
        await getProposalDetails(AuthToken, proposalId).then((res)=> {
          setProposalData(res?.data)
        })
      }catch(err){
        throw err
      }
    }
    useEffect(()=> {
        getProposalDetail()
 
    },[viewProposalModal])
  return (
    <>
      <Modal
      width={900}
      open={viewProposalModal}
      onOk={()=> setViewProposalModal(false)}
      onCancel={()=> setViewProposalModal(false)}
      destroyOnClose
      footer={null}
      >
        <div className='viewproposal'>
          <div className='relative'>
            <img className='absolute top-0 right-0 pt-8 pr-8' src="https://prometteursolutions.com/appcalc/assets/images/pdfimgs/prom-logo.png" alt="" />
            <img className='' src='https://prometteursolutions.com/appcalc/assets/images/pdfimgs/bannbg.png'/>
            <div className='project-name absolute bottom-0 left-0 right-0 text-white font-bold p-4'>
              <div  dangerouslySetInnerHTML={{__html: proposalData?.description}} />
            </div>
          </div>

          <div className='relative'>
            <div className='px-8'>
              <div className='blog-desc'>
          <div dangerouslySetInnerHTML={{__html: proposalData?.tableofcontents}} />

              </div>
            </div>

          </div>

          <div className='mt-2 border p-2 rounded introduction'>
          <div dangerouslySetInnerHTML={{__html: proposalData?.company}} />
          </div>

          <div className='mt-2 border p-2 rounded project-desc'>
          <div dangerouslySetInnerHTML={{__html: proposalData?.greet}} />
          </div>

          <div className='mt-2 border p-2 rounded project-process'>
          <div dangerouslySetInnerHTML={{__html: proposalData?.process}} />
          </div>

          <div className='mt-2 border p-2 rounded project-specification'>
          <div dangerouslySetInnerHTML={{__html: proposalData?.specification}} />
          </div>

          <div className='mt-2 border p-2 rounded techstack'>
          <div dangerouslySetInnerHTML={{__html: proposalData?.deliverables}} />
          </div>

          <div className='mt-2 border p-2 rounded timeline'>
          <div dangerouslySetInnerHTML={{__html: proposalData?.timeline}} />
          </div>

          <div className='mt-2 border p-2 rounded estimateandsign'>
          <div dangerouslySetInnerHTML={{__html: proposalData?.price}} />
          </div>

          <div className='mt-2 border p-2 rounded agreement'>
          <div dangerouslySetInnerHTML={{__html: proposalData?.agreement}} />
          </div>

        </div>

      </Modal>
    </>
  )
}

export default ViewProposalModal
