import React from 'react'
import { Modal, notification } from 'antd'
import { deleteUser } from '../../../graphs/getUsers'
import { useSelector } from 'react-redux'


const DeleteUserModal = ({deleteUserModal, setDeleteUserModal, userId}) => {
  const AuthToken = useSelector((state)=> state?.auth?.authToken)
    console.log("Delete user Id =>",userId)

    const handleDeleteUser = async() => {
    await deleteUser(AuthToken,userId).then((res)=> {
      console.log(res)
      notification["success"]({
        message:"User deleted"
      })
    })  
     setDeleteUserModal(false)
    }
  return (
    <>
      <Modal
      width={400}
      open={deleteUserModal}
      onOk={()=>setDeleteUserModal(false)}
      onCancel={()=>setDeleteUserModal(false)}
      destroyOnClose
      footer={null}
      >
      <div className='text-center'>
        <h2 className=' text-base'>Are you sure want to delete user</h2>
        <div className='flex items-center justify-center mt-3'>
            <button onClick={()=> setDeleteUserModal(false)} className='py-2 px-4 bg-slate-400 border hover:border-slate-700 border-slate-400 rounded-lg backdrop-blur'>cancel</button>
            <button onClick={handleDeleteUser}  className='ml-2 py-2 px-4 bg-yellow-400 border hover:border-yellow-700 border-yellow-400 rounded-lg backdrop-blur'>Ok</button>
        </div>
        </div>  
      </Modal>
    </>
  )
}

export default DeleteUserModal
