import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { getAllImages } from '../../../graphs/appCalculator/appcalcApis'
import { useSelector } from 'react-redux'

const ViewImagesModal = ({viewImageModal, setViewImagesModal}) => {
const [allImages, setAllImages] = useState([])
  const AuthToken = useSelector((state)=> state?.auth?.authToken)
    
const getAllImagesData = async() => {
    try{
        await getAllImages(AuthToken).then((res)=> {
            console.log("get  all images => ",res)
            setAllImages(res?.data)
        })
    }catch(err){
        console.log(err)
    }
}

const copyToClickboard = async(value) => {
    console.log(value)
    if('clipboard' in navigator){
        return await navigator.clipboard.writeText(value)
    }else{
        return document.execCommand('copy',true,value)
    }
}

useEffect(()=> {
    getAllImagesData()
},[viewImageModal])
  return (
    <div>
       <Modal
        open={viewImageModal}
        onOk={() => setViewImagesModal(false)}
        onCancel={() => setViewImagesModal(false)}
        footer={null}
        width={1200}
        destroyOnClose
      >
        <h2 className="mb-4 text-xl font-bold">All Images</h2>
        <div>
            <div className='grid grid-cols-4 gap-8'>
                {allImages?.map((ele,idx)=> (
                <div key={ele?.id} className='shadow-lg transition-all delay-75 border-2 hover:scale-110 rounded p-2 border-lime-600'>
                    <img className='border' src={`https://prometteursolutions.com/appcalc_api/media/test/${ele?.name}`} alt='images'/>
                    <div className='flex items-center border rounded  bg-lime-600'>
                        <input type='text' value={`https://prometteursolutions.com/appcalc_api/media/test/${ele?.name}`} className='ml-2 outline-0 border rounded p-1' />
                        <button onClick={() => copyToClickboard(`https://prometteursolutions.com/appcalc_api/media/test/${ele?.name}`)} className='px-4 py-2 text-white focus:bg-lime-900'>Copy</button>
                    </div>
                </div>
                ))}
            </div>
        </div>
      </Modal>
    </div>
  )
}

export default ViewImagesModal
