import React from 'react'

const Header = ({title, element}) => {
  return (
    <div className="px-4">
    <div className="flex items-center justify-between p-4 mb-6 bg-white shadow rounded overflow-x-auto">
      <h2 className="lg:text-lg lg:font-medium md:text-lg md:font-medium sm:text-base sm:font-medium text-base font-medium">{title}</h2>
    </div>
  </div>
  )
}

export default Header
