import { SET_USERS } from "../constant/actionTypes";

const initialstate = {
    users:[]
}

export default (state=initialstate, action)=>{
    switch (action.type) {
        case SET_USERS:
          return { ...state, users: action.data };
    
        default:
          return { ...state };
      }
}
