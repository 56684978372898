import { Modal, message } from "antd";
import React, { useEffect } from "react";
import { getSubFeatures } from "../../graphs/appCalculator/appcalcApis";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import axios from "axios";

const FeatureModal = ({
  featureModalOpen,
  setFeatureModalOpen,
  features,
  subModules,
  setSubmodules,
}) => {
  const AuthToken = useSelector((state) => state?.auth?.authToken);

  const handleSubmit = (values) => {
    // setSubmodules({...subModules,submodules:values?.subModule});
    setSubmodules([...subModules,...values?.subModule]);
    console.log("feature modal selected values => ", values);
    setFeatureModalOpen(false);
  };

  const validateData = (value) => {
    const error = {};
    if (value?.subModule?.length === 0) {
      error.subModule = "Please select atlease one Service";
    }
    console.log("feature module error =======> ", error);
    return error;
  };

  useEffect(() => {

  }, []);
  return (
    <div>
      <Modal
        width={500}
        open={featureModalOpen}
        onOk={() => setFeatureModalOpen(false)}
        onCancel={() => setFeatureModalOpen(false)}
        destroyOnClose
        centered
        footer={null}
      >
        <div>
          <h2 className="text-lg font-medium">Sub Modules</h2>
        </div>
        <div>
          <Formik
            initialValues={{ subModule: [], mainServices2: [] }}
            onSubmit={handleSubmit}
            validate={validateData}
          >
            {({
              handleBlur,
              handleChange,
              errors,
              values,
              handleSubmit,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="about-survey mt-2 relative">
                  <div className="px-3 realtive grid grid-cols-12 items-center justify-center gap-2">
                    {features?.map((ele, idx) => (
                      <label
                        key={ele?.id}
                        className="lg:col-span-4 md:col-span-4 sm:col-span-6 col-span-6 mb-3 relative cursor-pointer w-28  max-h-32"
                      >
                        {/* {values.subModule?.includes(ele?.id) ? (
                          <div className="absolute  top-0 right-0 bg-rose-500 h-5 w-4 z-20 transition-all ease-in-out "></div>
                        ) : null} */}
                        <div
                          className={`${
                            values.subModule?.includes(ele?.id)
                              ? "border border-yellow-300 shadow-lg scale-105"
                              : ""
                          } p-2 border rounded-sm hover:scale-105 hover:shadow-lg transition-all ease-in-out relative group `}
                        >
                          <div className="flex mx-0 items-center cursor-pointer">
                            <input
                              className="hidden"
                              name={`subModule[${idx}]`}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                const itemId = ele?.id;
                                const subModuleArray = values.subModule || [];

                                if (isChecked) {
                                  subModuleArray.push(itemId);
                                } else {
                                  const index = subModuleArray.indexOf(itemId);
                                  if (index !== -1) {
                                    subModuleArray.splice(index, 1);
                                  }
                                }

                                setFieldValue(`subModule`, subModuleArray);

                                const itemName = ele;
                                const subModuleArrayname =
                                  values.mainServices2 || [];

                                if (isChecked) {
                                  subModuleArrayname.push(itemName);
                                } else {
                                  const index =
                                    subModuleArrayname.indexOf(itemName);
                                  if (index !== -1) {
                                    subModuleArrayname.splice(index, 1);
                                  }
                                }

                                setFieldValue(
                                  `mainServices2`,
                                  subModuleArrayname
                                );
                              }}
                              checked={
                                values.subModule?.includes(ele?.id) || false
                              }
                              onBlur={handleBlur}
                              type="checkbox"
                            />

                            <div className="text-center w-full">
                              <p className="block relative p-0 m-0 text-xs font-medium ">
                                {ele?.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </label>
                    ))}
                  </div>
                  {errors?.subModule && touched?.subModule ? (
                    <p className=" text-xs my-2 text-rose-500">
                      {errors?.subModule}
                    </p>
                  ) : null}
                </div>
                <div>
                  <button
                    className="mx-2 rounded-lg lg:px-6 lg:py-3 md:px-4 md:py-3 sm:px-3 sm:py-2 px-3 py-2 lg:text-base md:text-base sm:text-sm text-sm font-medium bg-[#ffcf1e] hover:shadow-lg hover:bg-black hover:text-white transition-all ease-in-out"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default FeatureModal;
