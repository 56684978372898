import React from "react";

const CalcTitle = ({ title, highlightTitle, main, noteText }) => {
  return (
    <div className="relative">
      <div className="text-center">
        <h1 className="lg:text-4xl md:text-4xl sm:text-xl text-xl font-normal text-yellow-400">
          <span className="text-black">{title}</span> {highlightTitle}
        </h1>
        <span className="absolute top-[-10px] left-0 right-0 text-[#1e253012] text-center lg:text-6xl md:text-4xl sm:text-3xl text-3xl font-extrabold z-10">
          {main}
        </span>
        <p className="text-sm text-slate-400 mt-2">
          {noteText ? noteText : "(Select atleast one Option)"}{" "}
        </p>
      </div>
    </div>
  );
};

export default CalcTitle;
