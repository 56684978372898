import React, { useEffect, useRef, useState } from "react";
import { Field, FieldArray, Formik } from "formik";
import { Select, Space, notification } from "antd";
import Header from "../components/ui/Header";
import CButton from "../components/ui/Button";
import TinyTextEditor from "../components/ui/TinyTextEditor";
import { addCaseStudy } from "../graphs/appCalculator/appcalcApis";
import { useSelector } from "react-redux";

const AddCaseStudy = () => {
  const AuthToken = useSelector((state) => state?.auth?.authToken);
  const fileInputRef1 = useRef(null)
  const fileInputRef2 = useRef(null)
  const fileInputRef3 = useRef(null)
  const fileInputRef4 = useRef(null)
  const fileInputRef5 = useRef(null)
  const fileInputRef6 = useRef(null)

  const handleSubmit = async (value, { resetForm }) => {
    let formData = new FormData();
    formData.append("cstype", value?.appType);
    formData.append("cstitle", value?.title);
    formData.append("csurl", value?.url);
    formData.append("cardimg", value?.thumbnail);
    formData.append("cstech[]", value?.technologiesImage);
    formData.append("bannerimg", value?.bannerImage);
    formData.append("csoverview", value?.overview);
    formData.append("csss[]", value?.screens);
    formData.append("benefitsHeading", value?.benefitsHeading);
    formData.append("benefitsimg", value?.benefitsImage);
    formData.append("benefitsdesc", value?.benefitsDescription);
    formData.append("descHeading", value?.projectDescriptionHeading);
    formData.append("projdesc", value?.projectDescription);
    formData.append("featureHeading", value?.featureHeading);
    // formData.append("featureimgs[0]", "value.projectTitle");
    // formData.append("featureimgs[1]", "value.projectTitle");
    formData.append("features", value?.features);
    formData.append("adminheading", value?.adminScreenShotHeading);
    formData.append("csadminss[]", value?.adminScreenshot);

    try {
      await addCaseStudy(AuthToken, formData).then((res) => {
        notification["success"]({
          message: "Case study added successfully",
        });
        resetForm();
      });
    } catch (err) {
      notification["error"]({
        message: "Somthing went wrong",
      });
    }
  };

  const validateData = (value) => {
    const error = {};
    if (!value.appType) {
      error.appType = "Please enter value";
    }
    if (!value.url) {
      error.url = "Please enter value";
    }
    if (!value.thumbnail) {
      error.thumbnail = "Please enter value";
    }
    if (!value.title) {
      error.title = "Please enter value";
    }
    if (!value.technologiesImage) {
      error.technologiesImage = "Please enter value";
    }
    if (!value.bannerImage) {
      error.bannerImage = "Please enter value";
    }
    if (!value.overview) {
      error.overview = "Please enter value";
    }
    if (!value.screens) {
      error.screens = "Please enter value";
    }
    if (!value.benefitsHeading) {
      error.benefitsHeading = "Please enter value";
    }
    if (!value.benefitsImage) {
      error.benefitsImage = "Please enter value";
    }
    if (!value.benefitsDescription) {
      error.benefitsDescription = "Please enter value";
    }
    if (!value.projectDescription) {
      error.projectDescription = "Please enter value";
    }
    if (!value.projectDescriptionHeading) {
      error.projectDescriptionHeading = "Please enter value";
    }
    if (!value.featureHeading) {
      error.featureHeading = "Please enter value";
    }
    if (!value.adminScreenShotHeading) {
      error.adminScreenShotHeading = "Please enter value";
    }
    if (value.adminScreenshot === null) {
      error.adminScreenshot = "Please enter value";
    }
  //   if (!value.features?.length > 0) {
  //     error.features = "Please enter value";
  

  //   value?.features?.forEach((option, i) => {
  //     if (!option?.featuretitle) {
  //       error[`features${i}.featuretitle`] =
  //         "Please enter value";
  //     }
  //     if(!option?.featuredesc){
  //       error[`features${i}.featuredesc`] = "Please enter value"
  //     }
  //     if(!option?.featureimg){
  //       error[`features${i}.featureimg`] = "Please enter value"
  //     }
  //   });

  // }
    return error;
  };

  return (
    <section class="py-8">
      <Header title={"Add New Case Study"} />
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap -mx-4">
          <div class="w-full bg-[#E2E8F0] lg:w-full px-4 mb-8 lg:mb-0">
            <div class="px-6 pb-6 pt-4 bg-white shadow rounded">
              <Formik
                enableReinitialize="true"
                initialValues={{
                  appType: "",
                  url: "",
                  thumbnail: "",
                  title: "",
                  technologiesImage: "",
                  bannerImage: "",
                  overview: "",
                  screens: "",
                  benefitsHeading: "",
                  benefitsImage: "",
                  benefitsDescription: "",
                  projectDescription: "",
                  projectDescriptionHeading: "",
                  featureHeading: "",
                  adminScreenShotHeading: "",
                  adminScreenshot: null,
                  features: [],
                }}
                onSubmit={handleSubmit}
                validate={validateData}
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  handleReset,
                  handleSubmit,
                  errors,
                  touched,
                  setFieldValue,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit} className="mb-4">
                    <div className="grid grid-cols-12 gap-4 items-center">
                      <div class="mb-1 col-span-12 flex">
                        <label className="flex ml-2">
                          <input
                            type="radio"
                            name="appType"
                            id="appType"
                            onChange={() => setFieldValue("appType", "web")}
                            onBlur={handleBlur}
                            checked={values?.appType === "web"}
                          />
                          <span class="ml-2">Web</span>
                        </label>
                        <label className="flex ml-2">
                          <input
                            type="radio"
                            name="userStatus"
                            id="userStatus"
                            onChange={() => setFieldValue("appType", "mobile")}
                            onBlur={handleBlur}
                            defaultChecked
                            checked={values?.appType === "mobile"}
                          />
                          <span class="ml-2">Mobile</span>
                        </label>
                      </div>

                      <div className="col-span-12">
                        <div className="lg:flex md:flex sm:flex-wrap flex-wrap gap-2 items-start justify-betweeen">
                          <div className="w-72">
                            <div class="mb-2">
                              <label
                                class="block text-sm font-medium mb-2"
                                for=""
                              >
                                Case Study Url *
                              </label>
                              <input
                                class="block w-full min-w-72 px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                type="text"
                                value={values?.url}
                                name="url"
                                id="url"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Example small letter"
                              />
                              <p className="text-sm text-blue-500">
                                * Once added cannot be updated
                              </p>
                              {errors.url && touched.url ? (
                                <p className="text-rose-500 text-sm">
                                  {errors.url}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div className="w-72">
                            <div class="mb-2">
                              <label
                                class="block text-sm font-medium mb-2"
                                for=""
                              >
                                Case Study Thumbnail *
                              </label>
                              <input
                                accept="image/png, image/jpeg, image/jpg"
                                class="block w-full px-4 py-2 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                type="file"
                                ref={fileInputRef1}
                                name="thumbnail"
                                id="thumbnail"
                                onChange={(event) => {
                                  setFieldValue(
                                    `thumbnail`,
                                    event.target.files[0]
                                  );
                                }}
                                onBlur={handleBlur}
                                placeholder="Example small letter"
                              />
                              {errors.thumbnail && touched.thumbnail ? (
                                <p className="text-rose-500 text-sm">
                                  {errors.thumbnail}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div class="mb-2">
                          <label class="block text-sm font-medium mb-2" for="">
                            Case Study title *
                          </label>
                          <input
                            class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="text"
                            value={values?.title}
                            name="title"
                            id="title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Example small letter"
                          />
                          {errors.title && touched.title ? (
                            <p className="text-rose-500 text-sm">
                              {errors.title}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div class="mb-2">
                          <label class="block text-sm font-medium mb-2" for="">
                            Technologies Used *
                          </label>
                          <input
                            accept="image/png, image/jpeg, image/jpg"
                            class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="file"
                            ref={fileInputRef2}
                            name="technologiesImage"
                            id="technologiesImage"
                            onChange={(event) => {
                              setFieldValue(
                                `technologiesImage`,
                                event.target.files[0]
                              );
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.technologiesImage &&
                          touched.technologiesImage ? (
                            <p className="text-rose-500 text-sm">
                              {errors.technologiesImage}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div class="mb-2">
                          <label class="block text-sm font-medium mb-2" for="">
                            Case Study Banner Image *
                          </label>
                          <input
                            class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="file"
                            ref={fileInputRef3}
                            accept="image/png, image/jpeg, image/jpg"
                            name="bannerImage"
                            id="bannerImage"
                            onChange={(event) => {
                              setFieldValue(
                                `bannerImage`,
                                event.target.files[0]
                              );
                            }}
                            onBlur={handleBlur}
                            placeholder="Example small letter"
                          />
                          {errors.bannerImage && touched.bannerImage ? (
                            <p className="text-rose-500 text-sm">
                              {errors.bannerImage}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <label class="block text-sm font-medium mb-2" for="">
                          Overview *
                        </label>
                        <TinyTextEditor
                          initialValue={""}
                          className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                          id={"overview"}
                          onChange={handleChange}
                          onEditorChange={(content) =>
                            setFieldValue("overview", content)
                          }
                          name={"overview"}
                          value={values?.overview}
                        />
                        {errors.overview && touched.overview ? (
                          <p className="text-rose-500 text-sm">
                            {errors.overview}
                          </p>
                        ) : null}
                      </div>

                      <div className="col-span-12">
                        <div class="mb-2">
                          <label class="block text-sm font-medium mb-2">
                            Screens *
                          </label>
                          <input
                            class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="file"
                            ref={fileInputRef4}
                            accept="image/png, image/jpeg, image/jpg"
                            name="screens"
                            id="screens"
                            onChange={(event) => {
                              setFieldValue(`screens`, event.target.files[0]);
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.screens && touched.screens ? (
                            <p className="text-rose-500 text-sm">
                              {errors.screens}
                            </p>
                          ) : null}
                        </div>
                        <p className="text-sm text-blue-500">
                          *Mobile case-study needs same dimension screenshots or
                          images.
                        </p>
                      </div>

                      <div className="col-span-12">
                        <div class="mb-2">
                          <label class="block text-sm font-medium mb-2" for="">
                            Benefits Heading *
                          </label>
                          <input
                            class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="text"
                            value={values?.benefitsHeading}
                            name="benefitsHeading"
                            id="benefitsHeading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.benefitsHeading && touched.benefitsHeading ? (
                            <p className="text-rose-500 text-sm">
                              {errors.benefitsHeading}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div class="mb-2">
                          <label class="block text-sm font-medium mb-2" for="">
                            Benefits Image *
                          </label>
                          <input
                            class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="file"
                            ref={fileInputRef5}
                            accept="image/png, image/jpeg, image/jpg"
                            name="benefitsImage"
                            id="benefitsImage"
                            onChange={(event) => {
                              setFieldValue(
                                `benefitsImage`,
                                event.target.files[0]
                              );
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.benefitsImage && touched.benefitsImage ? (
                            <p className="text-rose-500 text-sm">
                              {errors.benefitsImage}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div class="mb-2">
                          <label class="block text-sm font-medium mb-2">
                            Benefits Description *
                          </label>

                          <TinyTextEditor
                            initialValue={""}
                            className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            id={"benefitsDescription"}
                            onBlur={handleBlur}
                            onEditorChange={(content) =>
                              setFieldValue("benefitsDescription", content)
                            }
                            name={"benefitsDescription"}
                            value={values?.benefitsDescription}
                          />
                          {errors.benefitsDescription &&
                          touched.benefitsDescription ? (
                            <p className="text-rose-500 text-sm">
                              {errors.benefitsDescription}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <hr />
                      <div className="col-span-12">
                        <div class="mb-2">
                          <label class="block text-sm font-medium mb-2" for="">
                            Project Description Heading *
                          </label>
                          <input
                            class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="text"
                            value={values?.projectDescriptionHeading}
                            name="projectDescriptionHeading"
                            id="projectDescriptionHeading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.projectDescriptionHeading &&
                          touched.projectDescriptionHeading ? (
                            <p className="text-rose-500 text-sm">
                              {errors.projectDescriptionHeading}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div class="mb-2">
                          <label class="block text-sm font-medium mb-2" for="">
                            Project Description *
                          </label>
                          <TinyTextEditor
                            initialValue={""}
                            className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            id={"projectDescription"}
                            onEditorChange={(content) =>
                              setFieldValue("projectDescription", content)
                            }
                            name={"projectDescription"}
                            value={values?.projectDescription}
                          />
                          {errors?.projectDescription &&
                          touched?.projectDescription ? (
                            <p className="text-sm text-rose-500">
                              {errors.projectDescription}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div class="mb-2">
                          <label class="block text-sm font-medium mb-2">
                            Features Heading *
                          </label>
                          <input
                            rows={8}
                            class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="text"
                            value={values?.featureHeading}
                            name="featureHeading"
                            id="featureHeading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.featureHeading && touched.featureHeading ? (
                            <p className="text-rose-500 text-sm">
                              {errors.featureHeading}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <FieldArray name="features">
                        {({ push, remove }) => (
                          <div className="col-span-12">
                            {values.features.map((feature, index) => (
                              <div
                                className="border bg-slate-100 p-2"
                                key={index}
                              >
                                <div className="mb-2">
                                  <label className="block text-sm font-medium mb-2">
                                    Feature Title {index + 1} *
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Feature Title"
                                    class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                    name={`features[${index}].featuretitle`}
                                    id={`features[${index}].featuretitle`}
                                    value={feature.featuretitle}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                                <div className="mb-2">
                                  <label className="block text-sm font-medium mb-2">
                                    Feature Description {index + 1} *
                                  </label>
                                  <textarea
                                    rows={8}
                                    class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                    type="text"
                                    name={`features[${index}].featuredesc`}
                                    id={`features[${index}].featuredesc`}
                                    value={feature.featuredesc}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                                <div className="mb-2">
                                  <label className="block text-sm font-medium mb-2">
                                    Feature Image {index + 1} *
                                  </label>
                                  <input
                                    type="file"
                                    class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                    name={`features[${index}].featureimg`}
                                    id={`features[${index}].featureimg`}
                                    onChange={(event) => {
                                      setFieldValue(
                                        `features[${index}].featureimg`,
                                        event.target.files[0]
                                      );
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </div>

                                <div className="flex items-center justify-end">
                                  <button
                                    className="bg-rose-500 px-4 py-2 rounded border-0 text-white"
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}
                            <button
                              className="bg-lime-500 px-4 py-2 rounded border-0 text-white mt-2"
                              type="button"
                              onClick={() =>
                                push({
                                  featuretitle: "",
                                  featuredesc: "",
                                  featureimg: "",
                                })
                              }
                            >
                              Add Feature
                            </button>
                          </div>
                        )}
                      </FieldArray>

                      <div className="col-span-12">
                        <div class="mb-2">
                          <label class="block text-sm font-medium mb-2" for="">
                            Admin Screenshot Heading *
                          </label>
                          <input
                            class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="text"
                            value={values?.adminScreenShotHeading}
                            name="adminScreenShotHeading"
                            id="adminScreenShotHeading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.adminScreenShotHeading &&
                          touched.adminScreenShotHeading ? (
                            <p className="text-rose-500 text-sm">
                              {errors.adminScreenShotHeading}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div class="mb-2">
                          <label class="block text-sm font-medium mb-2" for="">
                            Admin Screenshot *
                          </label>
                          <input
                            class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="file"
                            ref={fileInputRef6}
                            accept="image/png, image/jpeg, image/jpg"
                            name="adminScreenshot"
                            id="adminScreenshot"
                            // value={values?.adminScreenshot ? values?.adminScreenshot : null}
                            onChange={(event) => {
                              setFieldValue(
                                `adminScreenshot`,
                                event.target.files[0]
                              );
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.adminScreenshot && touched.adminScreenshot ? (
                            <p className="text-rose-500 text-sm">
                              {errors.adminScreenshot}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      {/* <CButton type="submit" title={"Submit"} /> */}
                    </div>
                    <div className="flex items-center justify-center gap-2 mt-2">
                      <button
                      disabled={!errors}
                        type="submit"
                        className={` ${!errors ? 'bg-[#787878]' : 'bg-[#787878]/50'} bg-[#787878] text-white px-4 py-2 rounded`}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={() => {resetForm(); fileInputRef1.current.value = ""; fileInputRef2.current.value = ""; fileInputRef3.current.value = ""; fileInputRef4.current.value = ""; fileInputRef5.current.value = ""; fileInputRef6.current.value = "";}}
                        className="bg-[#303030] text-white px-4 py-2 rounded"
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddCaseStudy;
