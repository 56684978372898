import React from "react";
import { useNavigate } from "react-router-dom";

const StatsCard = ({link, title, value}) => {
  const navigate= useNavigate()
  return (
    <div onClick={() => navigate(link)} class="w-full md:w-1/2 lg:w-1/4 p-4 cursor-pointer ">
      <div class="pt-6 text-center bg-white rounded hover:shadow-lg">
        <h4 class="mb-2 text-xs text-gray-500">{title}</h4>
        <p class="mb-1 text-4xl font-bold">{value}</p>
        <span class="inline-block py-1 px-2 mb-2 text-xs text-white bg-yellow-500 rounded-full"></span>
        <div class="chart" data-type="area-small" data-variant="indigo"></div>
      </div>
    </div>
  );
};

export default StatsCard;
