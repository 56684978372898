import React from 'react'
import { Input, Modal, notification } from 'antd'
import { Formik } from 'formik'
import { updatePassword, updateUser } from '../../../graphs/getUsers'
import { useSelector } from 'react-redux'

const EditUserModal = ({editUserModal, setEditUserModal, userData}) => {
  const AuthToken = useSelector((state)=> state?.auth?.authToken)
    console.log(userData)
    const handleSubmit = async(value) => {
      let formData = new FormData();
      formData.append('first_name',value.first_name);
      formData.append('last_name',value.last_name);
      formData.append('username',value.username);
      formData.append('is_active',value.is_active);
      formData.append('user_id',userData.user_id);

      try{
        await updateUser(AuthToken, formData, userData.user_id).then((res)=> {
          console.log(res)
          notification["success"]({
            message:"User Updated Successfully !"
          })
        })
      }catch(err){
        console.log(err)
      }
        console.log(value)
      }
    
      const validateData = (value) => {
        const error = {}
    
        if(!value.first_name){
          error.first_name = "Please enter value"
        }else if (!/^[a-zA-Z]+$/.test(value.first_name)) {
          error.first_name = "Please enter valid value";
        }
        if(!value.last_name){
          error.last_name = "Please enter value"
        }else if (!/^[a-zA-Z]+$/.test(value.last_name)) {
          error.last_name = "Please enter valid value";
        }
        if(!value.username){
          error.username = "Please enter value"
        }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.username)) {
          error.username = "Please enter valid email address";
        }
        console.log(error)
        return error;
      }

      const handlePassword = async(value) => {
        console.log("values for new password =>",value)
        let formData = new FormData();
        formData.append('c_password:',value.confirmPassword);
        formData.append('new_pass',value.password);
        formData.append('user_id',userData.user_id);
        try{
          await updatePassword(AuthToken, formData, userData.user_id).then((res)=> {
            console.log(res)
            notification["success"]({
              message:"User Updated Successfully !"
            })
          })
        }catch(err){
          console.log(err)
        }
        
      }
      const validatePassword = (value) =>{
        const error = {}
    
        if(!value.password){
          error.password = "Please enter value"
        }
        if(!value.confirmPassword){
          error.confirmPassword = "Please enter value"
        }else if(value.password !== value.confirmPassword){
          error.confirmPassword = "Please enter correct value"
        }
        return error
      }


  return (
    <>
          <Modal
        open={editUserModal}
        onOk={() => setEditUserModal(false)}
        onCancel={() => setEditUserModal(false)}
        footer={null}
        destroyOnClose
      >
        <h2 className="mb-4 text-xl font-bold">Edit user</h2>
        <div className='pb-3'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            first_name:userData?.first_name,
            last_name:userData?.last_name,
            username:userData?.username,
            is_active:userData?.is_active,
          }}
          onSubmit={handleSubmit}
          validate={validateData}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleReset,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div class="mb-6">
                <label class="block text-sm font-medium mb-2" for="">
                  First Name *
                </label>
                <input
                className={`block w-full px-4 py-3  text-sm placeholder-gray-500 bg-white border rounded ease-in-out transition-all delay-700 ${errors.first_name && touched.first_name ? "" : ""}`}
                  type="text"
                  name="first_name"
                  id="first_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.first_name}
                  placeholder="First Name"
                />
                {errors.first_name && touched.first_name ? (
                  <p className="text-rose-500">{errors.first_name}</p>
                ) : null}
              </div>
              <div class="mb-6">
                <label class="block text-sm font-medium mb-2" for="">
                  Last Name *
                </label>
                <input
                className={`block w-full px-4 py-3  text-sm placeholder-gray-500 bg-white border rounded ease-in-out transition-all delay-700 ${errors.last_name && touched.last_name ? "" : ""}`}
                  type="text"
                  name="last_name"
                  id="last_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.last_name}
                  placeholder="Last Name"
                />
                 {errors.last_name && touched.last_name ? (
                  <p className="text-rose-500">{errors.last_name}</p>
                ) : null}
              </div>
              <div class="mb-6">
                <label class="block text-sm font-medium mb-2" for="">
                  Email *
                </label>
                <input
                  class="block w-full px-4 py-3 text-sm placeholder-gray-500 bg-white border rounded"
                  type="email"
                  name="username"
                  id="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.username}
                  placeholder="username"
                />
                 {errors.username && touched.username ? (
                  <p className="text-rose-500">{errors.username}</p>
                ) : null}
              </div>
 
              <div class="mb-6 flex">
                <label class="block text-sm font-medium mb-2 ml-2"  htmlFor="is_active">
                  Status
                </label>
                <div class="mb-1 flex">
                  <label className="flex ml-2">
                    <input type="radio"
                     name="is_active"
                     id="is_active"
                     onChange={()=> setFieldValue("is_active", "1")}
                     onBlur={handleBlur}
                      checked={values?.is_active === "1"} 
                      />
                    <span class="ml-2">Active</span>
                  </label>
                  <label className="flex ml-2">
                    <input type="radio" 
                      name="is_active"
                      id="is_active"
                      onChange={()=> setFieldValue("is_active", "0")}
                      onBlur={handleBlur}
                      
                       checked={values?.is_active === "0"} 
                    />
                    <span class="ml-2">Inactive</span>
                  </label>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="py-2 px-4 bg-yellow-400 border hover:border-yellow-700 border-yellow-400 rounded-lg backdrop-blur"
                >
                  Save Changes
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
        <hr/>
        <div className='mt-3'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            password:"",
            confirmPassword:"",
          }}
          onSubmit={handlePassword}
          validate={validatePassword}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleReset,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div class="mb-6">
                <label class="block text-sm font-medium mb-2" for="">
                  New password *
                </label>
                <Input.Password
                // className={`block w-full px-4 py-3  text-sm placeholder-gray-500 bg-white border rounded ease-in-out transition-all delay-700 ${errors.firstName && touched.firstName ? "" : ""}`}
                className='w-full px-4 py-3 outline-0'
                  type="password"
                  name="password"
                  id="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.password}
                  placeholder="password"
                />
                {errors.password && touched.password ? (
                  <p className="text-rose-500">{errors.password}</p>
                ) : null}
              </div>
              <div class="mb-6">
                <label class="block text-sm font-medium mb-2" for="">
                  Re-enter password *
                </label>
                <Input.Password
                // className={`block w-full px-4 py-3  text-sm placeholder-gray-500 bg-white border rounded ease-in-out transition-all delay-700 ${errors.lastName && touched.lastName ? "" : ""}`}
                className='w-full px-4 py-3 outline-0'
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.confirmPassword}
                  placeholder="Re-enter password"
                />
                 {errors.confirmPassword && touched.confirmPassword ? (
                  <p className="text-rose-500">{errors.confirmPassword}</p>
                ) : null}
              </div>
              <div>
                <button
                  type="submit"
                  className="py-2 px-4 bg-yellow-400 border hover:border-yellow-700 border-yellow-400 rounded-lg backdrop-blur"
                >
                  Save new password
                </button>
              </div>
            </form>
          )}
        </Formik>
        </div>
      </Modal>
    </>
  )
}

export default EditUserModal
