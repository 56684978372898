import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getProposalDetails } from "../../graphs/getProposals";
import CButton from "../../components/ui/Button";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
// import { Document, Page, Text, Image, View, StyleSheet, PDFViewer, PageNumber } from '@react-pdf/renderer';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { faDownload, faEdit } from "@fortawesome/free-solid-svg-icons";
import proposalBg from "../../assets/images/bannbg.png";
import promLogo from "../../assets/images/prom-logo.png";
// import 'jspdf-autotable';

const ViewProposal = () => {
  const AuthToken = useSelector((state) => state?.auth?.authToken);
  const [proposalData, setProposalData] = useState();
  const targetRef = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const contentRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const getProposalDetail = async () => {
    try {
      await getProposalDetails(AuthToken, state?.id).then((res) => {
        setProposalData(res?.data);
      });
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getProposalDetail();
  }, []);

  const options = {
    // default is `save`
    method: "open",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "landscape",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break,
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: false,
      },
    },
  };

  return (
    <div className="py-8">
      <div className="px-4">
        <div className="flex items-center justify-between p-4 mb-6 bg-white shadow rounded overflow-x-auto">
          <h2 className="text-lg font-medium">Proposal Details</h2>
          <div className="flex items-center ">
            <div className="flex items-center justify-center"></div>
            <div className="flex items-center gap-2">
              <CButton
                //  onClick={()=>{setViewImagesModal(true) }}
                // onClick={handleDownloadPDF}
                onClick={() =>
                  generatePDF(targetRef, { filename: "proposal.pdf" })
                }
                // onClick={generatePDF}
                // onClick={generateAndDownloadPDF}
                icon={faDownload}
                title={"Save PDF"}
              />
              <CButton
                onClick={() =>
                  navigate(`/admin/editProposal/${proposalData?.id}`, {
                    state: proposalData,
                  })
                }
                //  onClick={()=>{setViewImagesModal(true) }}
                icon={faEdit}
                title={"Edit"}
              />
            </div>
          </div>
        </div>
      </div>

      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap -mx-4">
          <div class="w-full lg:w-full px-4 mb-8 lg:mb-0">
            <div class="px-6 pb-6 pt-4 bg-white shadow rounded">
              <div id="content-id" ref={targetRef} className="viewproposal">
                <div ref={contentRefs[0]} className="relative ">
                  <img
                    className="absolute top-0 right-0 pt-8 pr-8 w-72 "
                    src={promLogo}
                    alt=""
                  />
                  <img className="rounded-lg " src={proposalBg} />
                  <div className="project-name absolute bottom-0 left-0 right-0 text-white pb-12 pl-12">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: proposalData?.description,
                      }}
                    />
                  </div>
                </div>

                <div ref={contentRefs[1]} className="relative a4-page">
                  <div className=" ">
                    <div className="blog-desc">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: proposalData?.tableofcontents,
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div ref={contentRefs[2]} className="a4-page  introduction">
                  <div
                    dangerouslySetInnerHTML={{ __html: proposalData?.company }}
                  />
                </div>

                <div ref={contentRefs[3]} className="a4-page   project-desc">
                  <div
                    dangerouslySetInnerHTML={{ __html: proposalData?.greet }}
                  />
                </div>

                <div className="a4-page project-process">
                  <div
                    dangerouslySetInnerHTML={{ __html: proposalData?.process }}
                  />
                </div>

                <div className="a4-page project-specification">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: proposalData?.specification,
                    }}
                  />
                </div>

                <div className="a4-page techstack">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: proposalData?.deliverables,
                    }}
                  />
                </div>

                <div className="a4-page timeline">
                  <div
                    dangerouslySetInnerHTML={{ __html: proposalData?.timeline }}
                  />
                </div>

                <div className="a4-page estimateandsign">
                  <div
                    dangerouslySetInnerHTML={{ __html: proposalData?.price }}
                  />
                </div>

                <div className="a4-page agreement">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: proposalData?.agreement,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProposal;
