import { combineReducers } from "redux";
import auth from "./auth";
import users from "./users";
import toggleBtn from "./toggleBtn";
import { USER_LOGOUT } from "../constant/actionTypes";

const reducer = combineReducers({
  auth,
  toggleBtn,
  users,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return reducer(state, action);
};

export default rootReducer;
