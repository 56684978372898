import React, { useEffect, useState } from "react";
import Header from "../../../components/ui/Header";
import ResponseCard from "../../../components/ui/ResponseCard";
import { useSelector } from "react-redux";
import { getappCalcApis } from "../../../graphs/appCalculator/appcalcApis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faEdit,
  faEnvelope,
  faEye,
  faPhone,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import DeLeteCalcResponseModal from "../../../components/ui/modal/proposals/DeLeteCalcResponseModal";

const CalculatorResponse = () => {
  const AuthToken = useSelector((state) => state?.auth?.authToken);
  const [allCalculateResponseData, setAllCalculateResponseData] = useState([]);
  const [deleteResponseModal, setDeleteResponseModal] = useState(false);
  const [responseId, setResponseId] = useState(null);
  const navigate = useNavigate();

  const getAllCalculateResponseData = async () => {
    try {
      await getappCalcApis(AuthToken, "calc_response").then((res) => {
        setAllCalculateResponseData(res?.data?.data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllCalculateResponseData();
  }, [deleteResponseModal]);

  return (
    <>
      <section class="py-8">
        <Header title={"Calculate Response"} />

        <section class="py-2">
          <div class="container px-4 ">
            <div class="flex flex-wrap -m-4">
              {allCalculateResponseData?.map((ele, idx) => {
                return (
                  <div class="w-full md:w-1/2 lg:w-1/4 p-4 relative ">
                    <div class="p-4 bg-white h-64 hover:-translate-y-2 transition-all ease-in hover:shadow-lg rounded">
                      <div class="flex items-center mb-2">
                        <div>
                          <p class="text-xs font-bold">{ele?.name}</p>
                        </div>
                      </div>
                      <div>
                        <div class="flex items-center mb-2 relative break-words whitespace-break-spaces ">
                          <span class="mr-3 text-gray-500">
                            <FontAwesomeIcon icon={faEnvelope} />
                          </span>
                          <p class="text-xs w-40 text-gray-500 break-words whitespace-break-spaces">
                            {ele?.email}
                          </p>
                        </div>
                        <div class="flex items-center mb-2">
                          <span class="mr-3 text-gray-500">
                            <FontAwesomeIcon icon={faPhone} />
                          </span>
                          <span class="text-xs text-gray-500">
                            {ele?.contact}
                          </span>
                        </div>

                        <div class="relative w-full h-1 mb-3 rounded-full bg-gray-50">
                          <div class="absolute top-0 left-0 h-full w-full rounded-full bg-[#FFCF1E]"></div>
                        </div>
                        <div class="flex items-center mb-3">
                          <p class="text-xs font-bold"> {ele?.service}</p>
                        </div>

                        <div class="flex items-center mb-3">
                          <span class="mr-3 text-gray-500">
                            <FontAwesomeIcon icon={faCalendar} />
                          </span>
                          <span class="text-xs text-gray-500">
                            {" "}
                            {ele?.created}
                          </span>
                        </div>

                        <div class="flex items-center justify-center">
                          <div className="border flex items-center gap-4 rounded-lg px-4 py-1">
                            <a
                              class="inline-block mr-2 cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `/admin/appcalc/response-details/${ele?.id}`,
                                  { state: ele?.id }
                                )
                              }
                            >
                              <span className="text-lime-600">
                                <FontAwesomeIcon
                                  className="hover:text-lime-600 text-lime-500"
                                  size="md"
                                  icon={faEye}
                                />
                              </span>
                            </a>

                            <a
                              class="inline-block mr-2 cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `/admin/appcalc/edit-details/${ele?.id}`,
                                  { state: ele?.id }
                                )
                              }
                            >
                              <FontAwesomeIcon
                                className="text-blue-600 hover:text-blue-800"
                                size="md"
                                icon={faEdit}
                              />
                            </a>

                            <a
                              class="inline-block cursor-pointer"
                              onClick={() => {
                                setResponseId(ele?.id);
                                setDeleteResponseModal(true);
                              }}
                            >
                              <FontAwesomeIcon
                                className="hover:text-rose-600 text-rose-500"
                                size="md"
                                icon={faTrashAlt}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <DeLeteCalcResponseModal
          deleteResponseModal={deleteResponseModal}
          setDeleteResponseModal={setDeleteResponseModal}
          responseId={responseId}
        />
      </section>
    </>
  );
};

export default CalculatorResponse;
