import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { setLoginFlag } from "../actions/auth";
import logo from "../assets/images/prometteur_logo.png";
import logo_sm from "../assets/images/prometteur_logo_sm.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faBuilding,
  faCalculator,
  faCogs,
  faEnvelope,
  faHandPointer,
  faHandshake,
  faIndustry,
  faLaptopCode,
  faLaptopMedical,
  faMicrochip,
  faPhoneAlt,
  faPhoneVolume,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faSignOut,
  faSun,
  faTachometer,
  faTasks,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

const Sidebar = ({ toggleBtn }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const logindata = useSelector((state) => state?.auth?.loginUserData);

  const toggleSubmenu = (menuIndex) => {
    setActiveMenu(activeMenu === menuIndex ? null : menuIndex);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(setLoginFlag(false));
    navigate("/");
  };

  return (
    <div className={`${toggleBtn ? 'sm:hidden lg:block md:block hidden transition-all ease-in-out duration-100 delay-100' : 'sm:block block transition-all ease-in-out duration-100 delay-100'}`}>
      <div className="lg:block md:block sm:block block navbar-menu relative z-50">
        <div className="navbar-backdrop block  overflow-hidden inset-0 ">
          Prometteur
        </div>
        <nav
          className={`fixed top-0 left-0 bottom-0 flex group hover:lg:w-80 hover:md:w-80 hover:sm:w-40 hover:w-40 flex-col  border-b border-slate-900/10 ${
            toggleBtn
              ? "lg:w-32 md:w-32 sm:w-0 w-0 ease-in-out duration-300 togglebtn"
              : "lg:w-80 md:w-80 sm:w-12 w-12 ease-in-out duration-300 "
              }  sm:max-w-xs pt-0 pb-8 backdrop-blur bg-slate-900`}
              >
          <div className="flex w-full items-center py-3 px-6 mb-2 lg:border-b border-gray-700">
            <a className="text-xl text-white font-semibold transition-all delay-50">
              {toggleBtn ? (
                <img
                  src={logo_sm}
                  className={`${
                    toggleBtn ? "block" : "hidden"
                  } group-hover:block h-10 `}
                />
              ) : (
                <img
                  src={logo}
                  className={`${
                    !toggleBtn ? "block" : "hidden"
                  } group-hover:block h-10`}
                />
              )}
            </a>
          </div>
          <div
            className={` lg:py-4 lg:px-6 md:px-6 md:py-4 py-2 sm:px-1 px-1 transition-all duration-300 sidebar-panel overflow-y-auto transition-overflow ease-in-out durarion-300 no-scrollbar`}
          >
            <ul className="mb-2 text-sm font-medium">
              {logindata?.role === "guest" ? (
                <>
                  <li>
                    <NavLink
                      to="/admin/dashboard"
                      className="relative flex items-center pl-3 py-3 pr-4 text-gray-200 hover:text-gray-800 hover:bg-[#FFCF1E] rounded"
                    >
                      <span className="inline-block mr-3">
                        <FontAwesomeIcon
                          className="text-slate-500 "
                          size="lg"
                          icon={faTachometer}
                        />
                      </span>
                      <span
                        className={`${
                          !toggleBtn ? "block" : "sm:hidden"
                        } group-hover:block whitespace-nowrap`}
                      >
                        Dashboard
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/admin/proposals"
                      className="flex items-center mt-1 pl-3 py-3 pr-2 text-gray-200 hover:text-gray-800 hover:bg-[#FFCF1E] rounded"
                    >
                      <span className="inline-block mr-3">
                        <FontAwesomeIcon
                          className="text-slate-500 "
                          size="lg"
                          icon={faHandshake}
                        />
                      </span>
                      <span
                        className={`${
                          !toggleBtn
                            ? "lg:block md:block hidden sm:hidden"
                            : "hidden"
                        } group-hover:block whitespace-nowrap`}
                      >
                        Proposals
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/admin/add-case-study"
                      className="flex items-center mt-1 pl-3 py-3 pr-4 text-gray-200 hover:text-gray-800 hover:bg-[#FFCF1E] rounded"
                    >
                      <span className="inline-block mr-3">
                        <FontAwesomeIcon
                          className="text-slate-500 "
                          size="lg"
                          icon={faLaptopMedical}
                        />
                      </span>
                      <span
                        className={`${
                          !toggleBtn
                            ? "lg:block md:block sm:hidden hidden"
                            : "hidden"
                        } group-hover:block whitespace-nowrap`}
                      >
                        Add Case Study
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/admin/add-clone-app"
                      className="flex items-center mt-1 pl-3 py-3 pr-4 text-gray-200 hover:text-gray-800 hover:bg-[#FFCF1E] rounded"
                    >
                      <span className="inline-block mr-3">
                        <FontAwesomeIcon
                          className="text-slate-500 "
                          size="lg"
                          icon={faLaptopMedical}
                        />
                      </span>
                      <span
                        className={`${
                          !toggleBtn ? "block" : "hidden"
                        } group-hover:block whitespace-nowrap`}
                      >
                        Add Clone App
                      </span>
                    </NavLink>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <NavLink
                      to="/admin/dashboard"
                      className="relative flex items-center pl-3 py-3 pr-4 text-gray-200 hover:text-gray-800 hover:bg-[#FFCF1E] rounded"
                    >
                      <span className="inline-block mr-3">
                        <FontAwesomeIcon
                          className="text-slate-500 "
                          size="lg"
                          icon={faTachometer}
                        />
                      </span>
                      <span
                        className={`${
                          !toggleBtn ? "block" : "hidden"
                        } group-hover:block `}
                      >
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/admin/users"
                      className="flex items-center pl-3 mt-1 py-3 pr-4 text-gray-50 hover:bg-[#FFCF1E] hover:text-gray-800 rounded"
                    >
                      <span className="inline-block mr-3">
                        <FontAwesomeIcon
                          className="text-slate-500 "
                          size="lg"
                          icon={faUsers}
                        />
                      </span>
                      <span
                        className={`${
                          !toggleBtn ? "block" : "hidden"
                        } group-hover:block whitespace-nowrap`}
                      >
                        Users
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/admin/proposals"
                      className="flex items-center mt-1 pl-3 py-3 pr-2 text-gray-200 hover:text-gray-800 hover:bg-[#FFCF1E] rounded"
                    >
                      <span className="inline-block mr-3">
                        <FontAwesomeIcon
                          className="text-slate-500 "
                          size="lg"
                          icon={faHandshake}
                        />
                      </span>
                      <span
                        className={`${
                          !toggleBtn ? "block" : "hidden"
                        } group-hover:block whitespace-nowrap`}
                      >
                        Proposals
                      </span>
                    </NavLink>
                  </li>

                  <li className={`${activeMenu === 1 ? "transition-all ease-in-out delay-75 duration-75" : "transition-all ease-in-out delay-75 duration-75"} `}>
                    <a
                      onClick={() => toggleSubmenu(1)}
                      className="flex items-center mt-1 pl-3 py-3 pr-4 hover:text-gray-800 text-gray-200  hover:bg-[#FFCF1E] rounded"
                    >
                      <span className="inline-block mr-3">
                        <FontAwesomeIcon
                          className="text-slate-500"
                          size="lg"
                          icon={faPlus}
                        />
                      </span>

                      <span
                        className={`${
                          !toggleBtn ? "block" : "hidden"
                        } group-hover:block whitespace-nowrap cursor-pointer`}
                      >
                        App Calculator
                      </span>

                      <span
                        className={` group-hover:inline-block ml-auto whitespace-nowrap`}
                      >
                        { activeMenu === 1 ?(
                        <FontAwesomeIcon className="transition-all delay-150 ease-in-out" icon={faAngleDown} />
                        ) :
                        <FontAwesomeIcon className="transition-all delay-150 ease-in-out" icon={faAngleRight} />
                        }
                      </span>
                    </a>

                    <ul
                      className={`${
                        activeMenu === 1
                          ? "block transition-all ease-in-out durarion-300"
                          : "hidden transition-all ease-in-out durarion-300"
                      } transition-all duration-300 mx-3 `}
                    >
                      <li>
                        <NavLink
                          to="/admin/appcalc/industry"
                          className="relative mt-1 flex items-center pl-3 py-3 pr-4 hover:text-gray-800 text-gray-200  hover:bg-[#FFCF1E] rounded"
                        >
                          <span className="inline-block mr-3">
                            <FontAwesomeIcon
                              className="text-slate-500 "
                              size="lg"
                              icon={faBuilding}
                            />
                          </span>
                          <span
                            className={`${
                              !toggleBtn ? "block" : "hidden"
                            } group-hover:block whitespace-nowrap`}
                          >
                            Industry
                          </span>
                        </NavLink>
                      </li>
                      <li className="">
                        <NavLink
                          to="/admin/appcalc/services"
                          className="relative mt-1 flex items-center pl-3 py-3 pr-4 hover:text-gray-800 text-gray-200  hover:bg-[#FFCF1E] rounded"
                        >
                          <span className="inline-block mr-3">
                            <FontAwesomeIcon
                              className="text-slate-500 "
                              size="lg"
                              icon={faHandPointer}
                            />
                          </span>
                          <span
                            className={`${
                              !toggleBtn ? "block" : "hidden"
                            } group-hover:block whitespace-nowrap`}
                          >
                            Services
                          </span>
                        </NavLink>
                      </li>
                      <li className="">
                        <NavLink
                          to="/admin/appcalc/module"
                          className="relative mt-1 flex items-center pl-3 py-3 pr-4 hover:text-gray-800 text-gray-200  hover:bg-[#FFCF1E] rounded"
                        >
                          <span className="inline-block mr-3">
                            <FontAwesomeIcon
                              className="text-slate-500 "
                              size="lg"
                              icon={faQuestionCircle}
                            />
                          </span>
                          <span
                            className={`${
                              !toggleBtn ? "block" : "hidden"
                            } group-hover:block whitespace-nowrap`}
                          >
                            Module
                          </span>
                        </NavLink>
                      </li>

                      <li className="">
                        <NavLink
                          to="/admin/appcalc/feature"
                          className="relative mt-1 flex items-center pl-3 py-3 pr-4 hover:text-gray-800 text-gray-200  hover:bg-[#FFCF1E] rounded"
                        >
                          <span className="inline-block mr-3">
                            <FontAwesomeIcon
                              className="text-slate-500 "
                              size="lg"
                              icon={faSun}
                            />
                          </span>
                          <span
                            className={`${
                              !toggleBtn ? "block" : "hidden"
                            } group-hover:block whitespace-nowrap`}
                          >
                            Feature
                          </span>
                        </NavLink>
                      </li>

                      <li className="">
                        <NavLink
                          to="/admin/appcalc/questions"
                          className="relative flex items-center mt-1 pl-3 py-3 pr-4 hover:text-gray-800 text-gray-200  hover:bg-[#FFCF1E] rounded transition duration-75"
                        >
                          <span className="inline-block mr-3">
                            <FontAwesomeIcon
                              className="text-slate-500 "
                              size="lg"
                              icon={faQuestion}
                            />
                          </span>
                          <span
                            className={`${
                              !toggleBtn ? "block" : "hidden"
                            } group-hover:block whitespace-nowrap`}
                          >
                            Questions
                          </span>
                        </NavLink>
                      </li>

                      <li className="">
                        <NavLink
                          to="/admin/appcalc/app-quality"
                          className="relative flex items-center mt-1 pl-3 py-3 pr-4 hover:text-gray-800 text-gray-200  hover:bg-[#FFCF1E] rounded transition duration-75"
                        >
                          <span className="inline-block mr-3">
                            <FontAwesomeIcon
                              className="text-slate-500 "
                              size="lg"
                              icon={faTasks}
                            />
                          </span>
                          <span
                            className={`${
                              !toggleBtn ? "block" : "hidden"
                            } group-hover:block whitespace-nowrap`}
                          >
                            App Quality
                          </span>
                        </NavLink>
                      </li>

                      <li className="">
                        <NavLink
                          to="/admin/appcalc/technology"
                          className="relative flex items-center mt-1 pl-3 py-3 pr-4 hover:text-gray-800 text-gray-200  hover:bg-[#FFCF1E] rounded transition duration-75"
                        >
                          <span className="inline-block mr-3">
                            <FontAwesomeIcon
                              className="text-slate-500 "
                              size="lg"
                              icon={faMicrochip}
                            />
                          </span>
                          <span
                            className={`${
                              !toggleBtn ? "block" : "hidden"
                            } group-hover:block whitespace-nowrap`}
                          >
                            Technology
                          </span>
                        </NavLink>
                      </li>

                      <li className="">
                        <NavLink
                          to="/admin/appcalc/calculator-response"
                          className="relative flex items-center mt-1 pl-3 py-3 pr-4 hover:text-gray-800 text-gray-200  hover:bg-[#FFCF1E] rounded transition duration-75"
                        >
                          <span className="inline-block mr-3">
                            <FontAwesomeIcon
                              className="text-slate-500 "
                              size="lg"
                              icon={faCalculator}
                            />
                          </span>
                          <span
                            className={`${
                              !toggleBtn ? "block" : "hidden"
                            } group-hover:block whitespace-nowrap`}
                          >
                            Calculator Response
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a
                      onClick={() => toggleSubmenu(2)}
                      className="flex items-center mt-1 pl-3 py-3 pr-4 hover:text-gray-800 text-gray-200  hover:bg-[#FFCF1E] rounded"
                    >
                      <span className="inline-block mr-3">
                        <FontAwesomeIcon
                          className="text-slate-500 "
                          size="lg"
                          icon={faPlus}
                        />
                      </span>
                      <span
                        className={`${
                          !toggleBtn ? "block" : "hidden"
                        } group-hover:block whitespace-nowrap cursor-pointer`}
                      >
                        Enquiries
                      </span>
                      <span className="inline-block ml-auto">
                      { activeMenu === 2 ?(
                        <FontAwesomeIcon className="transition-all delay-150 ease-in-out" icon={faAngleDown} />
                        ) :
                        <FontAwesomeIcon className="transition-all delay-150 ease-in-out" icon={faAngleRight} />
                        }
                      </span>
                    </a>

                    <ul
                      className={`${
                        activeMenu === 2 ? "block transition-all " : "hidden"
                      } ml-2`}
                    >
                      <li className="">
                        <NavLink
                          to="/admin/enquiries/callback"
                          className="relative flex items-center mt-1 pl-3 py-3 pr-4 hover:text-gray-800 text-gray-200  hover:bg-[#FFCF1E] rounded  ease-in durarion-300"
                        >
                          <span className="inline-block mr-3">
                            <FontAwesomeIcon
                              className="text-slate-500 "
                              size="lg"
                              icon={faPhoneVolume}
                            />
                          </span>
                          <span
                            className={`${
                              !toggleBtn ? "block" : "hidden"
                            } group-hover:block whitespace-nowrap`}
                          >
                            Callback
                          </span>
                        </NavLink>
                      </li>
                      <li className="">
                        <NavLink
                          to="/admin/enquiries/job-applications"
                          className="relative flex items-center mt-1 pl-3 py-3 pr-4 hover:text-gray-800 text-gray-200  hover:bg-[#FFCF1E] rounded"
                        >
                          <span className="inline-block mr-3">
                            <FontAwesomeIcon
                              className="text-slate-500 "
                              size="lg"
                              icon={faLaptopCode}
                            />
                          </span>
                          <span
                            className={`${
                              !toggleBtn ? "block" : "hidden"
                            } group-hover:block whitespace-nowrap`}
                          >
                            Job Applications
                          </span>
                        </NavLink>
                      </li>
                      <li className="">
                        <NavLink
                          to="/admin/enquiries/contact-us"
                          className="relative flex items-center mt-1 pl-3 py-3 pr-4 hover:text-gray-800 text-gray-200  hover:bg-[#FFCF1E] rounded"
                        >
                          <span className="inline-block mr-3">
                            <FontAwesomeIcon
                              className="text-slate-500 "
                              size="lg"
                              icon={faEnvelope}
                            />
                          </span>
                          <span
                            className={`${
                              !toggleBtn ? "block" : "hidden"
                            } group-hover:block whitespace-nowrap`}
                          >
                            Contact Us
                          </span>
                          {/* <div className="hidden absolute top-0 left-0 ml-[80px] group-hover:bg-rose-500 group-hover:inline p-4 z-50">Dashboard</div> */}
                        </NavLink>
                      </li>

                      <li className="">
                        <NavLink
                          to="/admin/enquiries/openings"
                          className="relative flex items-center mt-1 pl-3 py-3 pr-4 hover:text-gray-800 text-gray-200  hover:bg-[#FFCF1E] rounded"
                        >
                          <span className="inline-block mr-3">
                            <FontAwesomeIcon
                              className="text-slate-500 "
                              size="lg"
                              icon={faLaptopMedical}
                            />
                          </span>
                          <span
                            className={`${
                              !toggleBtn ? "block" : "hidden"
                            } group-hover:block whitespace-nowrap`}
                          >
                            Openings
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink
                      to="/admin/add-case-study"
                      className="flex items-center mt-1 pl-3 py-3 pr-4 text-gray-200 hover:text-gray-800 hover:bg-[#FFCF1E] rounded"
                    >
                      <span className="inline-block mr-3">
                        <FontAwesomeIcon
                          className="text-slate-500 "
                          size="lg"
                          icon={faLaptopMedical}
                        />
                      </span>
                      <span
                        className={`${
                          !toggleBtn ? "block" : "hidden"
                        } group-hover:block whitespace-nowrap`}
                      >
                        Add Case Study
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/admin/add-clone-app"
                      className="flex items-center mt-1 pl-3 py-3 pr-4 text-gray-200 hover:text-gray-800 hover:bg-[#FFCF1E] rounded"
                      href="#"
                    >
                      <span className="inline-block mr-3">
                        <FontAwesomeIcon
                          className="text-slate-500 "
                          size="lg"
                          icon={faLaptopMedical}
                        />
                      </span>
                      <span
                        className={`${
                          !toggleBtn ? "block" : "hidden"
                        } group-hover:block whitespace-nowrap`}
                      >
                        Add Clone App
                      </span>
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
            <div className="pt-8">
              <a
                onClick={logoutHandler}
                className="flex items-center pl-3 py-3 pr-2 text-gray-50 hover:bg-gray-900 rounded cursor-pointer"
              >
                <span className="inline-block mr-4 ">
                  <FontAwesomeIcon
                    className="text-slate-500 "
                    size="lg"
                    icon={faSignOut}
                  />
                </span>
                {!toggleBtn ? <span>Log Out</span> : null}
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
