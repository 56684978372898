import {
  faArrowRight,
  faCheckCircle,
  faDotCircle,
  faThumbtack,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getResponseDetails } from "../../graphs/appCalculator/appcalcApis";
import { useSelector } from "react-redux";

const CalcSuccess = () => {
  const [successData, setSuccessData] = useState([]);
  const AuthToken = useSelector((state) => state?.auth?.authToken);
  const params = useParams();
  console.log("success location => ", params);

  const successDataHandler = async () => {
    try {
      await getResponseDetails(AuthToken, params?.id).then((res) => {
        console.log("CalculateResponse data => ", res);
        setSuccessData(res?.data?.data[0]);
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    successDataHandler();
  }, []);
  return (
    <div className="mt-20 h-full w-full bg-white relative section-overview">
      <div className="cube">
        <img
          src="https://prometteursolutions.com/appcalc/assets/images/prom.png"
          alt="loader"
          className=""
        />
      </div>
      <div className="cube">
        <img
          src="https://prometteursolutions.com/appcalc/assets/images/prom.png"
          alt="loader"
          className=""
        />
      </div>
      <div className="cube">
        <img
          src="https://prometteursolutions.com/appcalc/assets/images/prom.png"
          alt="loader"
          className=""
        />
      </div>
      <div className="cube">
        <img
          src="https://prometteursolutions.com/appcalc/assets/images/prom.png"
          alt="loader"
          className=""
        />
      </div>
      <div className="cube">
        <img
          src="https://prometteursolutions.com/appcalc/assets/images/prom.png"
          alt="loader"
          className=""
        />
      </div>
      <div className="lg:mx-20 md:mx-16 sm:mx-4 mx-4 lg:py-20 md:py-20 sm:py-10 py-10">
        <div className="flex items-center justify-center">
          <div className="mb-2">
            <FontAwesomeIcon
              className="lg:text-7xl md:text-6xl sm:text-4xl text-4xl text-[#f6c40e]"
              size="lg"
              icon={faCheckCircle}
            />
          </div>
        </div>

        <div className="flex items-center justify-center">
          <h2 className="text-4xl font-bold">Success</h2>
        </div>
        <div className="flex items-center justify-center">
          <h2 className="h-20 w-1 my-3 bg-[#f6c40e]"></h2>
        </div>

        <div className="flex items-center justify-center">
          <h2 className="text-2xl font-medium">Services</h2>
        </div>

        <div className="flex grid-cols-1 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1  items-center justify-center  mt-2 lg:mx-24 md:mx-16 sm:mx-2 mx-2 ">
          {successData?.technology?.mobile && (
            <div className="border border-dashed bg-[#F8F9FA]">
              <div className="p-8 w-full relative">
                <h6 className="mb-2 text-base font-bold text-[#f6c40e] ">
                  Mobile App Developement
                </h6>
                <div>
                  <h6 className="text-base mb-2 font-semibold">
                    Technology Stacks:{" "}
                  </h6>
                  <p className="mb-1 flex items-center ">
                    <FontAwesomeIcon
                      className="text-[#f6c40e]"
                      size="sm"
                      icon={faThumbtack}
                    />
                    <span className="ml-2 text-sm font-medium">
                      {successData?.technology?.mobile}
                    </span>
                  </p>
                  <p className="mb-1 flex items-center ">
                    <FontAwesomeIcon
                      className="text-[#f6c40e]"
                      size="sm"
                      icon={faThumbtack}
                    />
                    <span className="ml-2 text-sm font-medium">
                      {successData?.technology?.mobileapi}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )}

          {successData?.technology?.["web-frontend"] && (
            <div className="border border-dashed bg-[#F8F9FA]">
              <div className="p-8 relative">
                <h6 className="mb-2 text-base font-bold text-[#f6c40e] ">
                  Web App Developement
                </h6>

                <div>
                  <h6 className="text-base mb-2 font-semibold">
                    Technology Stacks:{" "}
                  </h6>
                  <p className="mb-1 flex items-center ">
                    <FontAwesomeIcon
                      className="text-[#f6c40e]"
                      size="sm"
                      icon={faThumbtack}
                    />
                    <span className="ml-2 text-sm font-medium">
                      {successData?.technology?.["web-frontend"]}
                    </span>
                  </p>
                  <p className="mb-1 flex items-center ">
                    <FontAwesomeIcon
                      className="text-[#f6c40e]"
                      size="sm"
                      icon={faThumbtack}
                    />
                    <span className="ml-2 text-sm font-medium">
                      {successData?.technology?.["web-backend"]}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )}

         {successData?.technology?.["admin-frontend"] && (
          <div className=" border border-dashed bg-[#F8F9FA]">
            <div className="p-8 relative">
              <h6 className="mb-2 text-base font-bold text-[#f6c40e] ">
                Admin Panel Developement
              </h6>

              <div>
                <h6 className="lg:text-base lg:font-semibold md:text-base md:font-medium sm:text-sm sm:font-normal mb-2 ">
                  Technology Stacks:{" "}
                </h6>
                <p className="mb-1 flex items-center ">
                  <FontAwesomeIcon
                    className="text-[#f6c40e]"
                    size="sm"
                    icon={faThumbtack}
                  />
                  <span className="ml-2 text-sm font-medium">{successData?.technology?.["admin-frontend"]}</span>
                </p>
                <p className="mb-1 flex items-center ">
                  <FontAwesomeIcon
                    className="text-[#f6c40e]"
                    size="sm"
                    icon={faThumbtack}
                  />
                  <span className="ml-2 text-sm font-medium">{successData?.technology?.["admin-backend"]}</span>
                </p>
              </div>
            </div>
          </div>
            )}


        </div>

        <div className="flex items-center justify-center">
          <h2 className="h-10 w-[2px] my-3 bg-[#f6c40e]"></h2>
        </div>

        <div className="flex items-center justify-center">
          <h2 className="text-xl font-medium">Modules</h2>
        </div>

        <div className="bg-[#F8F9FA] grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-2 border border-dashed rounded-md p-2 mt-2">
          {successData?.enqmodules?.map((ele, idx) => (
            <div key={idx} className="flex items-center">
              <FontAwesomeIcon className="text-[#f6c40e] lg:text-base md:text-base sm:text-sm text-sm" icon={faDotCircle} />
              <span className="lg:text-base md:text-base sm:text-xm text-xm font-medium ml-2">{ele?.name}</span>
            </div>
          ))}
        </div>

        <div className="flex items-center justify-center">
          <h2 className="h-10 w-[2px] my-3 bg-[#f6c40e]"></h2>
        </div>

        <div className=" flex items-center justify-center ">
          <div className="bg-[#f6c40e] w-fit rounded-lg p-4 mt-2  border border-dashed">
            <div className="flex items-center">
              <p className="text-sm">
                We will connect with you shortly. Visit our website or contact
                us for more details
              </p>
            </div>
            <div className="flex items-center justify-center mt-3">
              <a href="https://prometteursolutions.com" className=" px-4 py-1 border-2 border-black hover:bg-black hover:text-[#f6c40e] hover:scale-110 hover:shadow-md transition-all ease-in-out">
                <span className="text-lg font-medium mr-2">Go</span>
                <FontAwesomeIcon size="sm" icon={faArrowRight} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalcSuccess;
