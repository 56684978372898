import React from "react";
import { Modal } from "antd";
import { useEffect } from "react";
import { deleteProposal } from "../../../../graphs/appCalculator/appcalcApis";
import { useSelector } from "react-redux";
const DeleteProposalModal = ({
  deleteProposalModal,
  setDeleteProposalModal,
  proposalId,
}) => {
  const AuthToken = useSelector((state) => state?.auth?.authToken);

  const deletePorposalHandler = async () => {
    if (proposalId) {
      try {
        await deleteProposal(AuthToken, proposalId).then((res) => {});
        setDeleteProposalModal(false);
      } catch (err) {
        throw err;
      }
    }
  };

  return (
    <>
      <Modal
        width={400}
        open={deleteProposalModal}
        onOk={() => setDeleteProposalModal(false)}
        onCancel={() => setDeleteProposalModal(false)}
        destroyOnClose
        footer={null}
      >
        <div className="text-center">
          <h2 className=" text-base">Are you sure want to delete Proposal</h2>
          <div className="flex items-center justify-center mt-3">
            <button
              onClick={() => setDeleteProposalModal(false)}
              className="py-2 px-4 bg-slate-400 border hover:border-slate-700 border-slate-400 rounded-lg backdrop-blur"
            >
              cancel
            </button>
            <button
              onClick={() => deletePorposalHandler(proposalId)}
              className="ml-2 py-2 px-4 bg-yellow-400 border hover:border-yellow-700 border-yellow-400 rounded-lg backdrop-blur"
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteProposalModal;
