import React from "react";
import TinyTextEditor from "./TinyTextEditor";

const PackageInputForm = ({
  errors1,
  touched1,
  errors2,
  touched2,
  onBlur,
  onChange,
  value1,
  value2,
  value3,
  title,
  setFieldValue,
}) => {
  return (
    <div className="border p-2 w-full rounded-lg">
      <div class="mb-2 w-full">
        <label class="block text-sm font-medium mb-2" for="">
          Package {title} Icon *
        </label>
        <input
          class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
          type="text"
          value={value3}
          name={`package${title}Icon`}
          id={`package${title}Icon`}
          onChange={onChange}
          onBlur={onBlur}
        />
        {errors1 && touched1 ? (
          <p className="text-rose-500 text-sm">{errors1}</p>
        ) : null}
      </div>

      <div class="mb-2 w-full">
        <label class="block text-sm font-medium mb-2" for="">
          Package {title} Title *
        </label>
        <input
          class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
          type="text"
          value={value1}
          name={`package${title}Heading`}
          id={`package${title}Heading`}
          onChange={onChange}
          onBlur={onBlur}
        />
        {errors1 && touched1 ? (
          <p className="text-rose-500 text-sm">{errors1}</p>
        ) : null}
      </div>

      <div class="mb-2 w-full">
        <label class="block text-sm font-medium mb-2">
          Package {title} Description *
        </label>

        <TinyTextEditor
          initialValue={""}
          className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
          id={`package${title}Description`}
          name={`package${title}Description`}
          onEditorChange={(content) =>
            setFieldValue(`package${title}Description`, content)
          }
          value={value2}

          // value={value2}
          // name={`successStatisticsIcon${title}Description`}
          // id={`successStatisticsIcon${title}Description`}
        />
      </div>
    </div>
  );
};

export default PackageInputForm;
