import { faGlobe, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const CalcHeader = () => {
  return (
    <>
      <nav className="fixed top-0 left-0 right-0 bg-slate-800 z-50 lg:px-4 md:px-4 sm:px-2 px-2 lg:py-3 md:py-3 sm:py-1 py-1">
        <div className="lg:mx-12 md:mx-12 sm:mx-4 mx-4 flex flex-wrap items-center justify-between">
          <div className="py-2 lg:h-14 md:h-14 sm:h-12 h-12">
            <img
              className="h-full"
              src="https://prometteursolutions.com/appcalc/assets/images/prom-logo.png"
            />
          </div>
          <div className=" ">
            <ul className="flex items-center justify-center gap-4">
              <li className="hover:text-[#ffcf1e] text-white">
                <a target="_blank" href="https://prometteursolutions.com">
                <FontAwesomeIcon size="sm" icon={faGlobe} />
                <span className="lg:text-sm md:text-sm text-xs sm:text-xs font-medium ml-2">
                Visit Our Website
                  </span>
                  
                </a>
                </li>
              <li className="hover:text-[#ffcf1e] text-white">
                <a target="blank" href="https://prometteursolutions.com/about-us">
                  <FontAwesomeIcon size="sm" icon={faInfoCircle} />
                  <span className="lg:text-sm md:text-sm text-xs sm:text-xs font-medium ml-2">
                About Prometteur
                  </span>
                </a>
                </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default CalcHeader;
