import React from "react";
import { Outlet } from "react-router-dom";
import CalcHeader from "./ui/CalcHeader";
import CalcFooter from "./ui/CalcFooter";
import '../assets/css/costcal.css'
import '../assets/css/calcheader.css'

const CalcLayout = () => {
  return (
    <div className="bg-[#F0F4F5] w-full h-full">
      <CalcHeader />
      <Outlet />
      {/* <CalcFooter /> */}
    </div>
  );
};

export default CalcLayout;
