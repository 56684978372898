import React, { useEffect, useRef, useState } from "react";
import { FieldArray, Formik } from "formik";
import Header from "../components/ui/Header";
import CButton from "../components/ui/Button";
import SuccessInputsForm from "../components/ui/SuccessInputsForm";
import BenefirsInputsForm from "../components/ui/BenefirsInputsForm";
import TinyTextEditor from "../components/ui/TinyTextEditor";
import PackageInputForm from "../components/ui/PackageInputForm";
import { addCloneApp } from "../graphs/appCalculator/appcalcApis";
import { useSelector } from "react-redux";
import { notification } from "antd";

const AddCloneApp = () => {
  const AuthToken = useSelector((state) => state?.auth?.authToken);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);


  const handleSubmit = async (value, { resetForm }) => {

    let formData = new FormData();
    formData.append("cloneUrl", value?.appUrl);
    formData.append("thumbImg", value?.appThumbnail);
    formData.append("bannHead", value?.bannerTitle);
    formData.append("bannDesc", value?.bannerDescription);
    formData.append("livedemoUrl", value?.liveDemoUrl);
    formData.append("bannImg", value?.liveDemoUrl);
    formData.append("succHead", value?.successStatisticsHeading);
    formData.append("succImg", value?.successStatisticsImage);
    formData.append("succBtn", value?.successStatisticsButtonName);
    formData.append("succIconHead1", value?.successStatisticsIcon1Heading);
    formData.append("succIconDesc1", value?.successStatisticsIcon1Description);
    formData.append("succIconHead2", value?.successStatisticsIcon2Heading);
    formData.append("succIconDesc2", value?.successStatisticsIcon2Description);
    formData.append("succIconHead3", value?.successStatisticsIcon3Heading);
    formData.append("succIconDesc3", value?.successStatisticsIcon3Description);
    formData.append("succIconHead4", value?.successStatisticsIcon4Heading);
    formData.append("succIconDesc4", value?.successStatisticsIcon4Description);
    formData.append("featureHeading", value?.featureHeading);
    formData.append("features", value?.features);
    formData.append("benHead", value?.benefitsHeading);
    formData.append("benSubhead", value?.benefitsSubHeading);
    formData.append("benDesc", value?.benefitsDescription);
    formData.append("benIcon1", value?.benefitsIcon1);
    formData.append("benTitle1", value?.benefitsIcon1Title);
    formData.append("benIcon2", value?.benefitsIcon2);
    formData.append("benTitle2", value?.benefitsIcon2);
    formData.append("benIcon3", value?.benefitsIcon2);
    formData.append("benTitle3", value?.benefitsIcon2);
    formData.append("benIcon4", value?.benefitsIcon4);
    formData.append("benTitle4", value?.benefitsIcon4Title);
    formData.append("PackHead", value?.packageheading);
    formData.append("packSubhead", value?.packagesubheading);
    formData.append("packHeadDesc", value?.packagedesc);
    formData.append("packImg", value?.packageImage);
    formData.append("packIcon1", value?.package1Icon);
    formData.append("packTitle1", value?.package1Heading);
    formData.append("packDesc1", value?.package1Description);
    formData.append("packIcon2", value?.package2Icon);
    formData.append("packTitle2", value?.package2Heading);
    formData.append("packDesc2", value?.package2Description);
    formData.append("packIcon3", value?.package3Icon);
    formData.append("packTitle3", value?.package3Heading);
    formData.append("packDesc3", value?.package3Description);
    formData.append("packIcon4", value?.package4Icon);
    formData.append("packTitle4", value?.package4Heading);
    formData.append("packDesc4", value?.package4Description);
    formData.append("packIcon5", value?.package5Icon);
    formData.append("packTitle5", value?.package5Heading);
    formData.append("packDesc5", value?.package5Heading);
    formData.append("packIcon6", value?.package6Icon);
    formData.append("packTitle6", value?.package6Heading);
    formData.append("packDesc6", value?.package6Description);
    formData.append("adminheading", value?.adminheading);
    formData.append("csadminss[]", value?.adminScreenshot);

    try {
      await addCloneApp(AuthToken, formData).then((res) => {
        notification["success"]({
          message: "Clone app added Successfully",
        });
      });
    } catch (err) {
      notification["error"]({
        message: "Something went wrong",
      });
      throw err;
    }
    resetForm();
  };
  
  const validateData = (value) => {
    const error = {};
    if (!value.appUrl) {
      error.appUrl = "Please enter value";
    }
    if (!value.appThumbnail) {
      error.appThumbnail = "Please enter value";
    }
    if (!value.bannerTitle) {
      error.bannerTitle = "Please enter value";
    }
    if (!value.bannerDescription) {
      error.bannerDescription = "Please enter value";
    }
    if (!value.liveDemoUrl) {
      error.liveDemoUrl = "Please enter value";
    }
    if (!value.bannerImage) {
      error.bannerImage = "Please enter value";
    }
    if (!value.successStatisticsHeading) {
      error.successStatisticsHeading = "Please enter value";
    }
    if (!value.successStatisticsImage) {
      error.successStatisticsImage = "Please enter value";
    }
    if (!value.successStatisticsHeading) {
      error.successStatisticsHeading = "Please enter value";
    }
    if (!value.successStatisticsButtonName) {
      error.successStatisticsButtonName = "Please enter value";
    }
    if (!value.successStatisticsIcon1Heading) {
      error.successStatisticsIcon1Heading = "Please enter value";
    }
    if (!value.successStatisticsIcon1Description) {
      error.successStatisticsIcon1Description = "Please enter value";
    }
    if (!value.successStatisticsIcon2Heading) {
      error.successStatisticsIcon2Heading = "Please enter value";
    }
    if (!value.successStatisticsIcon2Description) {
      error.successStatisticsIcon2Description = "Please enter value";
    }
    if (!value.successStatisticsIcon3Heading) {
      error.successStatisticsIcon3Heading = "Please enter value";
    }
    if (!value.successStatisticsIcon3Description) {
      error.successStatisticsIcon3Description = "Please enter value";
    }
    if (!value.successStatisticsIcon4Heading) {
      error.successStatisticsIcon4Heading = "Please enter value";
    }
    if (!value.successStatisticsIcon4Description) {
      error.successStatisticsIcon4Description = "Please enter value";
    }
    if (!value.featureHeading) {
      error.featureHeading = "Please enter value";
    }
    if (!value.benefitsHeading) {
      error.benefitsHeading = "Please enter value";
    }
    if (!value.benefitsSubHeading) {
      error.benefitsSubHeading = "Please enter value";
    }
    if (!value.benefitsDescription) {
      error.benefitsDescription = "Please enter value";
    }
    if (!value.benefitsIcon1) {
      error.benefitsIcon1 = "Please enter value";
    }
    if (!value.benefitsIcon1Title) {
      error.benefitsIcon1Title = "Please enter value";
    }
    if (!value.benefitsIcon2) {
      error.benefitsIcon2 = "Please enter value";
    }
    if (!value.benefitsIcon2Title) {
      error.benefitsIcon2Title = "Please enter value";
    }
    if (!value.benefitsIcon3) {
      error.benefitsIcon3 = "Please enter value";
    }
    if (!value.benefitsIcon3Title) {
      error.benefitsIcon3Title = "Please enter value";
    }
    if (!value.benefitsIcon4) {
      error.benefitsIcon4 = "Please enter value";
    }
    if (!value.benefitsIcon4Title) {
      error.benefitsIcon4Title = "Please enter value";
    }
    if (!value.packageImage) {
      error.packageImage = "Please enter value";
    }
    if (!value.package1Icon) {
      error.package1Icon = "Please enter value";
    }
    if (!value.package1Heading) {
      error.package1Heading = "Please enter value";
    }
    if (!value.package1Description) {
      error.package1Description = "Please enter value";
    }
    if (!value.package2Icon) {
      error.package2Icon = "Please enter value";
    }
    if (!value.package2Heading) {
      error.package2Heading = "Please enter value";
    }
    if (!value.package2Description) {
      error.package2Description = "Please enter value";
    }
    if (!value.package3Icon) {
      error.package3Icon = "Please enter value";
    }
    if (!value.package3Heading) {
      error.package3Heading = "Please enter value";
    }
    if (!value.package3Description) {
      error.package3Description = "Please enter value";
    }
    if (!value.package4Icon) {
      error.package4Icon = "Please enter value";
    }
    if (!value.package4Heading) {
      error.package4Heading = "Please enter value";
    }
    if (!value.package4Description) {
      error.package4Description = "Please enter value";
    }
    if (!value.package5Icon) {
      error.package5Icon = "Please enter value";
    }
    if (!value.package5Heading) {
      error.package5Heading = "Please enter value";
    }
    if (!value.package5Description) {
      error.package5Description = "Please enter value";
    }
    if (!value.package6Icon) {
      error.package6Icon = "Please enter value";
    }
    if (!value.package6Heading) {
      error.package6Heading = "Please enter value";
    }
    if (!value.package6Description) {
      error.package6Description = "Please enter value";
    }
    if (!value.features) {
      error.features = "Please enter value";
    }
    if (!value.packageheading) {
      error.packageheading = "Please enter value";
    }
    if (!value.packagesubheading) {
      error.packagesubheading = "Please enter value";
    }
    if (!value.packagedesc) {
      error.packagedesc = "Please enter value";
    }
    if (!value.adminheading) {
      error.adminheading = "Please enter value";
    }
    if (!value.adminScreenshot) {
      error.adminScreenshot = "Please enter value";
    }
    return error;
  };

  return (
    <section className="py-8">
      <Header title={"Add New Clone App"} />
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-full px-4 mb-8 lg:mb-0">
            <div className="px-6 pb-6 pt-4 bg-white shadow rounded">
              <Formik
                enableReinitialize="true"
                initialValues={{
                  appUrl: "",
                  appThumbnail: [],
                  bannerTitle: "",
                  bannerDescription: "",
                  liveDemoUrl: "",
                  bannerImage: [],
                  successStatisticsHeading: "",
                  successStatisticsImage: [],
                  successStatisticsButtonName: "",
                  successStatisticsIcon1Heading: "",
                  successStatisticsIcon1Description: "",
                  successStatisticsIcon2Heading: "",
                  successStatisticsIcon2Description: "",
                  successStatisticsIcon3Heading: "",
                  successStatisticsIcon3Description: "",
                  successStatisticsIcon4Heading: "",
                  successStatisticsIcon4Description: "",
                  featureHeading: "",
                  benefitsHeading: "",
                  benefitsSubHeading: "",
                  benefitsDescription: "",
                  benefitsIcon1: "",
                  benefitsIcon1Title: "",
                  benefitsIcon2: "",
                  benefitsIcon2Title: "",
                  benefitsIcon3: "",
                  benefitsIcon3Title: "",
                  benefitsIcon4: "",
                  benefitsIcon4Title: "",
                  packageImage: null,

                  package1Icon: "",
                  package1Heading: "",
                  package1Description: "",
                  package2Icon: "",
                  package2Heading: "",
                  package2Description: "",
                  package3Icon: "",
                  package3Heading: "",
                  package3Description: "",

                  package4Icon: "",
                  package4Heading: "",
                  package4Description: "",

                  package5Icon: "",
                  package5Heading: "",
                  package5Description: "",

                  package6Icon: "",
                  package6Heading: "",
                  package6Description: "",

                  features: [],
                  featureHeading: "",
                  packageheading: "",
                  packagesubheading: "",
                  packagedesc: "",
                  adminheading: "",
                  adminScreenshot: null,
                }}
                onSubmit={handleSubmit}
                validate={validateData}
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  handleReset,
                  handleSubmit,
                  errors,
                  touched,
                  setFieldValue,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit} className="mb-4">
                    <div className="grid grid-cols-12 gap-4 items-center">
                      <div className="col-span-12">
                        <div className="lg:flex md:flex sm:flex-wrap flex-wrap gap-2 items-start justify-start">
                          <div className="w-auto">
                            <div className="mb-2">
                              <label
                                className="block text-sm font-medium mb-2"
                                for=""
                              >
                                Clone App Url *
                              </label>
                              <input
                                className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                type="text"
                                value={values?.appUrl}
                                name="appUrl"
                                id="appUrl"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Example small letter"
                              />
                              <p className="text-sm text-blue-500">
                                * Once added cannot be updated
                              </p>
                              {errors.appUrl && touched.appUrl ? (
                                <p className="text-rose-500 text-sm">
                                  {errors.appUrl}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div className="w-auto">
                            <div className="mb-2">
                              <label
                                className="block text-sm font-medium mb-2"
                                for=""
                              >
                                Clone App Thumbnail *
                              </label>
                              <input
                                className="block w-full px-4 py-2 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                type="file"
                                ref={fileInputRef1}
                                accept="image/png, image/jpeg, image/jpg"
                                name="appThumbnail"
                                id="appThumbnail"
                                onChange={(e) =>
                                  setFieldValue(
                                    "appThumbnail",
                                    e.target.files[0]
                                  )
                                }
                                onBlur={handleBlur}
                                placeholder="Example small letter"
                              />
                              {errors.appThumbnail && touched.appThumbnail ? (
                                <p className="text-rose-500 text-sm">
                                  {errors.appThumbnail}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-span-12">
                        <h2>Main Banner</h2>
                        <div className="mb-2">
                          <label
                            className="block text-sm font-medium mb-2"
                            for=""
                          >
                            Banner title *
                          </label>
                          <input
                            className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="text"
                            value={values?.bannerTitle}
                            name="bannerTitle"
                            id="bannerTitle"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Example small letter"
                          />
                          {errors.bannerTitle && touched.bannerTitle ? (
                            <p className="text-rose-500 text-sm">
                              {errors.bannerTitle}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div className="mb-2">
                          <label
                            className="block text-sm font-medium mb-2"
                            for=""
                          >
                            Banner Description *
                          </label>

                          <TinyTextEditor
                            initialValue={""}
                            className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            id={"bannerDescription"}
                            onEditorChange={(content) =>
                              setFieldValue("bannerDescription", content)
                            }
                            name={"bannerDescription"}
                            value={values?.bannerDescription}
                          />
                          {errors.bannerDescription &&
                          touched.bannerDescription ? (
                            <p className="text-rose-500 text-sm">
                              {errors.bannerDescription}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div className="mb-2">
                          <label
                            className="block text-sm font-medium mb-2"
                            for=""
                          >
                            Live Demo Url *
                          </label>
                          <input
                            className="block w-auto px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="text"
                            value={values?.liveDemoUrl}
                            name="liveDemoUrl"
                            id="liveDemoUrl"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Example small letter"
                          />
                          {errors.liveDemoUrl && touched.liveDemoUrl ? (
                            <p className="text-rose-500 text-sm">
                              {errors.liveDemoUrl}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div className="mb-2">
                          <label className="block text-sm font-medium mb-2">
                            Banner Image *
                          </label>
                          <input
                            className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="file"
                            accept="image/png, image/jpeg, image/jpg"
                            name="bannerImage"
                            id="bannerImage"
                            ref={fileInputRef2}
                            onChange={(e) => {
                              setFieldValue("bannerImage", e.target.files[0]);
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.bannerImage && touched.bannerImage ? (
                            <p className="text-rose-500 text-sm">
                              {errors.bannerImage}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <h2>Success Statistics</h2>
                        <div className="mb-2">
                          <label
                            className="block text-sm font-medium mb-2"
                            for=""
                          >
                            Success Statistics Heading *
                          </label>
                          <input
                            className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="text"
                            value={values?.successStatisticsHeading}
                            name="successStatisticsHeading"
                            id="successStatisticsHeading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.successStatisticsHeading &&
                          touched.successStatisticsHeading ? (
                            <p className="text-rose-500 text-sm">
                              {errors.successStatisticsHeading}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div className="mb-2">
                          <label
                            className="block text-sm font-medium mb-2"
                            for=""
                          >
                            Success Statistics image *
                          </label>
                          <input
                            className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="file"
                            accept="image/png, image/jpeg, image/jpg"
                            name="successStatisticsImage"
                            id="successStatisticsImage"
                            ref={fileInputRef3}
                            onChange={(e) => {
                              setFieldValue(
                                "successStatisticsImage",
                                e.target.files[0]
                              );
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.successStatisticsImage &&
                          touched.successStatisticsImage ? (
                            <p className="text-rose-500 text-sm">
                              {errors.successStatisticsImage}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div className="mb-2">
                          <label
                            className="block text-sm font-medium mb-2"
                            for=""
                          >
                            Success Statistics Button Name*
                          </label>
                          <input
                            className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="text"
                            value={values?.successStatisticsButtonName}
                            name="successStatisticsButtonName"
                            id="successStatisticsButtonName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.successStatisticsButtonName &&
                          touched.successStatisticsButtonName ? (
                            <p className="text-rose-500 text-sm">
                              {errors.successStatisticsButtonName}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div className="grid grid-cols-12 items-center gap-4 justify-between">
                          <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12">
                            <SuccessInputsForm
                              errors1={errors.successStatisticsIcon1Heading}
                              value1={values?.successStatisticsIcon1Heading}
                              touched1={touched.successStatisticsIcon1Heading}
                              errors2={errors.successStatisticsIcon1Description}
                              value2={values?.successStatisticsIcon1Description}
                              touched2={
                                touched.successStatisticsIcon1Description
                              }
                              title={"1"}
                              setFieldValue={setFieldValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12">
                            <SuccessInputsForm
                              errors1={errors.successStatisticsIcon2Heading}
                              value1={values?.successStatisticsIcon2Heading}
                              touched1={touched.successStatisticsIcon2Heading}
                              errors2={errors.successStatisticsIcon2Description}
                              value2={values?.successStatisticsIcon2Description}
                              touched2={
                                touched.successStatisticsIcon2Description
                              }
                              title={"2"}
                              setFieldValue={setFieldValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-12 items-center gap-2 mt-4 justify-between w-full">
                          <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12">
                            <SuccessInputsForm
                              errors1={errors.successStatisticsIcon3Heading}
                              value1={values?.successStatisticsIcon3Heading}
                              touched1={touched.successStatisticsIcon3Heading}
                              errors2={errors.successStatisticsIcon3Description}
                              value2={values?.successStatisticsIcon3Description}
                              touched2={
                                touched.successStatisticsIcon3Description
                              }
                              title={"3"}
                              setFieldValue={setFieldValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12">
                            <SuccessInputsForm
                              errors1={errors.successStatisticsIcon4Heading}
                              value1={values?.successStatisticsIcon4Heading}
                              touched1={touched.successStatisticsIcon4Heading}
                              errors2={errors.successStatisticsIcon4Description}
                              value2={values?.successStatisticsIcon4Description}
                              touched2={
                                touched.successStatisticsIcon4Description
                              }
                              title={"4"}
                              setFieldValue={setFieldValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div className="mb-2">
                          <label className="block text-sm font-medium mb-2">
                            Features Heading *
                          </label>
                          <input
                            rows={8}
                            className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="text"
                            value={values?.featureHeading}
                            name="featureHeading"
                            id="featureHeading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.featureHeading && touched.featureHeading ? (
                            <p className="text-rose-500 text-sm">
                              {errors.featureHeading}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <FieldArray name="features">
                        {({ push, remove }) => (
                          <div className="col-span-12 ">
                            {values.features.map((feature, index) => (
                              <div
                                className="border bg-slate-100 p-2 mb-2"
                                key={index}
                              >
                                <div className="mb-2">
                                  <label className="block text-sm font-medium mb-2">
                                    Feature Icon {index + 1} *
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Feature Icon"
                                    className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                    name={`features[${index}].featureicon`}
                                    value={feature.featureicon}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                                <div className="mb-2">
                                  <label className="block text-sm font-medium mb-2">
                                    Feature Title {index + 1} *
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Feature Title"
                                    className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                    name={`features[${index}].featuretitle`}
                                    value={feature.featuretitle}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>

                                <div className="mb-2">
                                  <label className="block text-sm font-medium mb-2">
                                    Feature Description {index + 1} *
                                  </label>
                                  <textarea
                                    rows={8}
                                    className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                    type="text"
                                    name={`features[${index}].featuredesc`}
                                    value={feature.featuredesc}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>

                                <div className="flex items-center justify-end">
                                  <button
                                    className="bg-rose-500 px-4 py-2 rounded border-0 text-white"
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}
                            <button
                              className="bg-lime-500 px-4 py-2 rounded border-0 text-white mt-2"
                              type="button"
                              onClick={() =>
                                push({
                                  featuretitle: "",
                                  featuredesc: "",
                                  featureicon: "",
                                })
                              }
                            >
                              Add Feature
                            </button>
                          </div>
                        )}
                      </FieldArray>

                      <div className="col-span-12">
                        <h2>Benefits</h2>
                        <div className="mb-2">
                          <label
                            className="block text-sm font-medium mb-2"
                            for=""
                          >
                            Benefits Heading *
                          </label>
                          <input
                            className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="text"
                            value={values?.benefitsHeading}
                            name="benefitsHeading"
                            id="benefitsHeading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.benefitsHeading && touched.benefitsHeading ? (
                            <p className="text-rose-500 text-sm">
                              {errors.benefitsHeading}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div className="mb-2">
                          <label
                            className="block text-sm font-medium mb-2"
                            for=""
                          >
                            Benefits Sub-heading *
                          </label>
                          <input
                            className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="text"
                            value={values?.benefitsSubHeading}
                            name="benefitsSubHeading"
                            id="benefitsSubHeading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.benefitsSubHeading &&
                          touched.benefitsSubHeading ? (
                            <p className="text-rose-500 text-sm">
                              {errors.benefitsSubHeading}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div className="mb-2">
                          <label
                            className="block text-sm font-medium mb-2"
                            for=""
                          >
                            Benefits Description *
                          </label>
                          <TinyTextEditor
                            initialValue={""}
                            className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            id={"benefitsDescription"}
                            onEditorChange={(content) =>
                              setFieldValue("benefitsDescription", content)
                            }
                            name={"benefitsDescription"}
                            value={values?.benefitsDescription}
                          />
                          {errors.benefitsDescription &&
                          touched.benefitsDescription ? (
                            <p className="text-rose-500 text-sm">
                              {errors.benefitsDescription}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div className="grid  gap-2 justify-between w-full grig-cols-12">
                          <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12">
                            <BenefirsInputsForm
                              errors1={errors.benefitsIcon1}
                              value1={values?.benefitsIcon1}
                              touched1={touched.benefitsIcon1}
                              errors2={errors.benefitsIcon1Title}
                              value2={values?.benefitsIcon1Title}
                              touched2={touched.benefitsIcon1Title}
                              title={"1"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12">
                            <BenefirsInputsForm
                              errors1={errors.benefitsIcon2}
                              value1={values?.benefitsIcon2}
                              touched1={touched.benefitsIcon2}
                              errors2={errors.benefitsIcon2Title}
                              value2={values?.benefitsIcon2Title}
                              touched2={touched.benefitsIcon2Title}
                              title={"2"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>

                        <div className="flex gap-8">
                          <BenefirsInputsForm
                            errors1={errors.benefitsIcon3}
                            value1={values?.benefitsIcon3}
                            touched1={touched.benefitsIcon3}
                            errors2={errors.benefitsIcon3Title}
                            value2={values?.benefitsIcon3Title}
                            touched2={touched.benefitsIcon3Title}
                            title={"3"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <BenefirsInputsForm
                            errors1={errors.benefitsIcon4}
                            value1={values?.benefitsIcon4}
                            touched1={touched.benefitsIcon4}
                            errors2={errors.benefitsIcon4Title}
                            value2={values?.benefitsIcon4Title}
                            touched2={touched.benefitsIcon4Title}
                            title={"4"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>

                      <div className="col-span-12">
                        <p>Package Details</p>
                        <div className="col-span-12">
                          <div className="mb-2">
                            <label className="block text-sm font-medium mb-2">
                              Package Heading *
                            </label>
                            <input
                              placeholder="Package Heading"
                              className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                              type="text"
                              value={values?.packageheading}
                              name="packageheading"
                              id="packageheading"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.packageheading && touched.packageheading ? (
                              <p className="text-rose-500 text-sm">
                                {errors.packageheading}
                              </p>
                            ) : null}
                          </div>

                          <div className="mb-2">
                            <label className="block text-sm font-medium mb-2">
                              Package Sub-heading *
                            </label>
                            <input
                              placeholder="Package Sub-heading"
                              className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                              type="text"
                              value={values?.packagesubheading}
                              name="packagesubheading"
                              id="packagesubheading"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.packagesubheading &&
                            touched.packagesubheading ? (
                              <p className="text-rose-500 text-sm">
                                {errors.packagesubheading}
                              </p>
                            ) : null}
                          </div>

                          <div className="mb-2">
                            <label className="block text-sm font-medium mb-2">
                              Package Description *
                            </label>
                            <TinyTextEditor
                              initialValue={""}
                              className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                              id={"packagedesc"}
                              onEditorChange={(content) =>
                                setFieldValue("packagedesc", content)
                              }
                              name={"packagedesc"}
                              value={values?.packagedesc}
                            />
                            {errors.packagedesc && touched.packagedesc ? (
                              <p className="text-rose-500 text-sm">
                                {errors.packagedesc}
                              </p>
                            ) : null}
                          </div>

                          <div className="mb-2">
                            <label className="block text-sm font-medium mb-2">
                              Package Image *
                            </label>
                            <input
                              className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                              type="file"
                              accept="image/png, image/jpeg, image/jpg"
                              name="packageImage"
                              id="packageImage"
                              ref={fileInputRef4}
                              onChange={(e) =>
                                setFieldValue("packageImage", e.target.files[0])
                              }
                              onBlur={handleBlur}
                            />
                            {errors.packageImage && touched.packageImage ? (
                              <p className="text-rose-500 text-sm">
                                {errors.packageImage}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div className="grid grid-cols-12 items-center gap-2 justify-between w-full">
                          <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12">
                            <PackageInputForm
                              errors1={errors.package1Heading}
                              value1={values?.package1Heading}
                              touched1={touched.package1Heading}
                              errors2={errors.package1Description}
                              value2={values?.package1Description}
                              value3={values?.package1Icon}
                              touched2={touched.package1Description}
                              title={"1"}
                              setFieldValue={setFieldValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12">
                            <PackageInputForm
                              errors1={errors.package2Heading}
                              value1={values?.package2Heading}
                              touched1={touched.package2Heading}
                              errors2={errors.package2Description}
                              value2={values?.package2Description}
                              value3={values?.package2icon}
                              touched2={touched.package2Description}
                              title={"2"}
                              setFieldValue={setFieldValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-12 items-center gap-2 justify-between w-full mt-2">
                          <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12">
                            <PackageInputForm
                              errors1={errors.package3Heading}
                              value1={values?.package3Heading}
                              touched1={touched.package3Heading}
                              errors2={errors.package3Description}
                              value2={values?.package3Description}
                              value3={values?.package3icon}
                              touched2={touched.package3Description}
                              title={"3"}
                              setFieldValue={setFieldValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12">
                            <PackageInputForm
                              errors1={errors.package4Heading}
                              value1={values?.package4Heading}
                              touched1={touched.package4Heading}
                              errors2={errors.package4Description}
                              value2={values?.package4Description}
                              value3={values?.package4icon}
                              touched2={touched.package4Description}
                              title={"4"}
                              setFieldValue={setFieldValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-12 items-center gap-2 justify-between w-full mt-2">
                          <div className="lg:col-span-6 md:col-span-6 col-span-12  sm:col-span-12">
                            <PackageInputForm
                              errors1={errors.package5Heading}
                              value1={values?.package5Heading}
                              touched1={touched.package5Heading}
                              errors2={errors.package5Description}
                              value2={values?.package5Description}
                              value3={values?.package5icon}
                              touched2={touched.package5Description}
                              title={"5"}
                              setFieldValue={setFieldValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12">
                            <PackageInputForm
                              errors1={errors.package6Heading}
                              value1={values?.package6Heading}
                              touched1={touched.package6Heading}
                              errors2={errors.package6Description}
                              value2={values?.package6Description}
                              value3={values?.package6icon}
                              touched2={touched.package6Description}
                              title={"6"}
                              setFieldValue={setFieldValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-12">
                        <p className="text-lg font-medium">Admin</p>
                        <div className="mb-2">
                          <label className="block text-sm font-medium mb-2">
                            Admin Heading *
                          </label>
                          <input
                            className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="text"
                            placeholder="Admin Heading"
                            value={values?.adminheading}
                            name="adminheading"
                            id="adminheading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.adminheading && touched.adminheading ? (
                            <p className="text-rose-500 text-sm">
                              {errors.adminheading}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div className="mb-2">
                          <label className="block text-sm font-medium mb-2">
                            Admin Screenshot *
                          </label>
                          <input
                            className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="file"
                            ref={fileInputRef5}
                            accept="image/png, image/jpeg, image/jpg"
                            name="adminScreenshot"
                            id="adminScreenshot"
                            onChange={(e) =>
                              setFieldValue(
                                "adminScreenshot",
                                e.target.files[0]
                              )
                            }
                            onBlur={handleBlur}
                          />
                          {errors.adminScreenshot && touched.adminScreenshot ? (
                            <p className="text-rose-500 text-sm">
                              {errors.adminScreenshot}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center justify-center gap-2 mt-2">
                      <button
                        type="submit"
                        className="bg-[#787878] text-white px-4 py-2 rounded"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          resetForm();
                          fileInputRef1.current.value = "";
                          fileInputRef2.current.value = "";
                          fileInputRef3.current.value = "";
                          fileInputRef4.current.value = "";
                          fileInputRef5.current.value = "";
                        }}
                        className="bg-[#303030] text-white px-4 py-2 rounded"
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddCloneApp;
