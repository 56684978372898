import { SET_TOGGLE_BTN } from "../constant/actionTypes";

const initialState = {
  toggle: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TOGGLE_BTN:
      return { ...state, toggle: action.data };

    default:
      return { ...state };
  }
};
