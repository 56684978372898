import React, { useEffect, useState } from "react";
import Header from "../../../components/ui/Header";
import ResponseCard from "../../../components/ui/ResponseCard";
import { useSelector } from "react-redux";
import {
  getResponseDetails,
  getappCalcApis,
} from "../../../graphs/appCalculator/appcalcApis";
import { useLocation } from "react-router-dom";
import { MEDIALINK } from "../../../constant/common";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ResponseDetails = () => {
  const AuthToken = useSelector((state) => state?.auth?.authToken);
  const [responseDetails, setResponseDetails] = useState([]);
  const { state } = useLocation();
  console.log(state);
  const getAllCalculateResponseData = async () => {
    try {
      await getResponseDetails(AuthToken, state).then((res) => {
        console.log("CalculateResponse data => ", res);
        setResponseDetails(res?.data?.data);
      });
    } catch (err) {
      throw err;
    }
  };

  console.log(responseDetails);
  useEffect(() => {
    getAllCalculateResponseData();
  }, []);

  return (
    <>
      <section class="py-8">
        <Header title={"Response Details"} />

        <section class="py-2">
          <div class="container px-4 mx-auto">
            <div class="flex flex-wrap -m-4"></div>

            {responseDetails?.map((ele, idx) => (
              <div class="mt-4  ">
                <div className="bg-white p-4 rounded-lg w-full">
                  <div className="my-2">
                    <h2 className="text-xl font-bold mb-2">
                      {ele?.proposalData?.name}
                    </h2>
                    <hr />
                    <div className="flex flex-wrap gap-2 items-center mt-2">
                      <div className="flex items-center gap-2 text-sm  mx-4">
                        <span className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="25"
                            height="25"
                            viewBox="0 0 30 30.000001"
                            preserveAspectRatio="xMidYMid meet"
                            version="1.0"
                          >
                            <defs>
                              <clipPath id="id1">
                                <path
                                  fill="#C2C9D2"
                                  d="M 3.460938 6.5625 L 26.539062 6.5625 L 26.539062 24.707031 L 3.460938 24.707031 Z M 3.460938 6.5625 "
                                  clipRule="nonzero"
                                />
                              </clipPath>
                            </defs>
                            <g clipPath="url(#id1)">
                              <path
                                fill="#FFCF1E"
                                d="M 24.230469 11.101562 L 15 16.769531 L 5.769531 11.101562 L 5.769531 8.832031 L 15 14.503906 L 24.230469 8.832031 Z M 24.230469 6.5625 L 5.769531 6.5625 C 4.492188 6.5625 3.472656 7.578125 3.472656 8.832031 L 3.460938 22.441406 C 3.460938 23.695312 4.492188 24.707031 5.769531 24.707031 L 24.230469 24.707031 C 25.507812 24.707031 26.539062 23.695312 26.539062 22.441406 L 26.539062 8.832031 C 26.539062 7.578125 25.507812 6.5625 24.230469 6.5625 "
                                fillOpacity="1"
                                fillRule="nonzero"
                              />
                            </g>
                          </svg>
                        </span>
                        <p>{ele?.enquirydata?.email}</p>
                      </div>
                      <div className="flex items-center gap-2 text-sm  mx-4">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-telephone-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="#FFCF1E"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                            />{" "}
                          </svg>
                        </span>
                        <p>{ele?.enquirydata?.contact} </p>
                      </div>
                      <div className="flex items-center gap-2 text-sm  mx-4">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-calendar-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="#FFCF1E"
                              d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z"
                            />{" "}
                          </svg>
                        </span>
                        <p>{ele?.enquirydata?.created} </p>
                      </div>
                      <div className="flex items-center gap-2 text-sm  mx-4">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-message-circle"
                          >
                            <path
                              fill="#FFCF1E"
                              d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"
                            ></path>
                          </svg>
                        </span>
                        <p>{ele?.enquirydata?.comment}</p>
                      </div>
                      <div>
                        {ele?.enquirydata?.attachment !== "" ? (
                          <>
                            <FontAwesomeIcon
                              className="text-[#FFCF1E]"
                              size="md"
                              icon={faPaperclip}
                            />
                            <a
                              target="_blank"
                              href={`${MEDIALINK}appcalc_enquiries/${ele?.enquirydata?.attachment}`}
                              download
                            >
                              {" "}
                              Attachment{" "}
                            </a>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div>
                    <h2 className="text-base font-bold mb-2">
                      Application Category :
                    </h2>
                    <h2 className="text-base font-bold mb-2">Services:</h2>

                    {ele?.technology?.mobile ? (
                      <div className="border bg-slate-100/50 p-2">
                        <p>Mobile App Development - </p>
                        <div className="flex items-center">
                          <h2 className="text-base font-bold">Technology : </h2>
                          <span>{ele?.technology?.mobile}</span>
                        </div>
                        <div className="flex items-center">
                          <h2 className="text-base font-bold">Api : </h2>
                          <span>{ele?.technology?.mobileapi}</span>
                        </div>
                      </div>
                    ) : null}

                    {ele?.technology?.["web-frontend"] ? (
                      <div className="border bg-slate-100/50 p-2 mt-4">
                        <p>Web App Development - </p>
                        <div className="flex items-center">
                          <h2 className="text-base font-bold">
                            Web Frontend :{" "}
                          </h2>
                          <span>{ele?.technology?.["web-frontend"]}</span>
                        </div>
                        <div className="flex items-center">
                          <h2 className="text-base font-bold">
                            Web Backend :{" "}
                          </h2>
                          <span>{ele?.technology?.["web-backend"]}</span>
                        </div>
                      </div>
                    ) : null}

                    {ele?.technology?.["admin-frontend"] ? (
                      <div className="border bg-slate-100/50 p-2 mt-4">
                        <p>Admin Development - </p>
                        <div className="flex items-center">
                          <h2 className="text-base font-bold">
                            Admin Front End :{" "}
                          </h2>
                          <span>{ele?.technology?.["admin-frontend"]}</span>
                        </div>
                        <div className="flex items-center">
                          <h2 className="text-base font-bold">
                            Admin Back End :{" "}
                          </h2>
                          <span>{ele?.technology?.["admin-backend"]}</span>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-4">
                    <h2 className="text-base font-bold mb-2">Modules :</h2>
                    {ele?.other_modules?.map((item) => (
                      <div className="border bg-slate-100/50 p-2 mt-2">
                        <h2>{item?.name}</h2>
                        <div className="flex items-center justify-between">
                          <span>-</span>
                          <div className="p-1 bg-yellow-400 rounded text-sm font-base">
                            <span></span>
                            <p>{parseFloat(item?.hours).toFixed(2)} hrs</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="mt-4">
                    <h2 className="lg:text-base lg:font-bold md:text-base md:font-bold sm:text-sm sm:font-medium font-medium text-sm mb-2">
                      Grand Total :
                    </h2>
                    <div className=" mt-2">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="border lg:col-span-1 md:col-span-1 sm:col-span-3 col-span-3 bg-slate-100/50 p-2">
                          <div className="flex items-center gap-2 ">
                            <h2>Total Hours</h2>
                            <div className="p-1 bg-yellow-400 rounded text-sm font-base">
                              <p>
                                {parseFloat(ele?.total_hours).toFixed(2)} hrs
                              </p>
                            </div>
                          </div>
                          <div className="">
                            <p>
                              Technology :{" "}
                              {ele?.enquirydata?.technology_percentage}%
                            </p>
                            <p>
                              Mobile - {ele?.enquirydata?.mobile_percentage}%
                            </p>
                            <p>Admin - {ele?.enquirydata?.admin_percentage}%</p>
                            <p>Web - {ele?.enquirydata?.web_percentage}%</p>
                          </div>
                        </div>

                        <div className="border lg:col-span-1 md:col-span-1 sm:col-span-3 col-span-3 bg-slate-100/50 p-2">
                          <div className="flex items-center gap-2 ">
                            <h2>Base Price </h2>
                            <div className="p-1 bg-yellow-400 rounded text-sm font-base">
                              <span></span>
                              <p>
                                {parseFloat(
                                  ele?.enquirydata?.total_price
                                ).toFixed(2)}{" "}
                                hrs
                              </p>
                              {/* <p> {ele?.enquirydata?.total_price}</p> */}
                            </div>
                          </div>
                        </div>

                        <div className="border  lg:col-span-1 md:col-span-1 sm:col-span-3 col-span-3 bg-slate-100/50 p-2">
                          <div className="flex items-center gap-2 ">
                            <h2>Total Price</h2>
                            <div className="p-1 bg-yellow-400 rounded text-sm font-base">
                              <span></span>
                              <p>
                                {" "}
                                {parseFloat(
                                  (ele.enquirydata.total_price / 100) *
                                    ele.enquirydata.quality_percentage +
                                    +ele.enquirydata.total_price
                                ).toFixed(2)}{" "}
                                (App Quality:{" "}
                                {ele.enquirydata.quality_percentage}%)
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </section>
    </>
  );
};

export default ResponseDetails;
