import {
  DELETE_CALC_RESPONSE,
  DELETE_PROPOSAL,
  GET_ALL_CALC_MODULE,
  GET_ALL_IMAGES,
  GET_ALL_SUBFEATURES,
  GET_PARENT_SERVICES,
  GET_RESPONSE_DETAILS,
  GET_SERVICES_TECH,
  GET_TECHNOLOGY_MAIN,
  WEBURL,
} from "../../constant/common";
import axios from "axios";

export const getappCalcApis = async (AuthToken, type) => {
  try {
    return await axios.get(`${WEBURL}${type}/view`, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};

export const deleteappCalcApis = async (AuthToken, type, id) => {
  try {
    return await axios.delete(`${WEBURL}${type}/delete/${id}`, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};

export const editappCalcApis = async (AuthToken, type, values) => {
  try {
    return await axios.post(`${WEBURL}${type}/edit`, values, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};

export const addappCalcApis = async (AuthToken, type, values) => {
  try {
    return await axios.post(`${WEBURL}/${type}/add`, values, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};

//app calc response
export const getResponseDetails = async (AuthToken, id) => {
  try {
    return await axios.get(`${GET_RESPONSE_DETAILS}/${id}`, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};

//fetch technology by main
export const getTechnologyMain = async (AuthToken, id) => {
  try {
    return await axios.get(`${GET_TECHNOLOGY_MAIN}/${id}`, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};

export const getServicesTech = async (AuthToken, id) => {
  try {
    return await axios.get(`${GET_SERVICES_TECH}/${id}`, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    console.log(err);
  }
};

export const getAllImages = async (AuthToken) => {
  try {
    return await axios.get(`${GET_ALL_IMAGES}`, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};

//proposals api
export const deleteProposal = async (AuthToken, id) => {
  try {
    return await axios.delete(`${DELETE_PROPOSAL}/${id}`, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};

export const deleteCalcResponse = async (AuthToken, id) => {
  try {
    return await axios.delete(`${DELETE_CALC_RESPONSE}/${id}`, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};

//services apis
//get parent services
export const getParentServices = async (AuthToken) => {
  try {
    return await axios.get(`${GET_PARENT_SERVICES}`, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};

//add case study
export const addCaseStudy = async (AuthToken, value) => {
  try {
    return await axios.post(`${WEBURL}add_case_study`, value, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};

//add clone app
export const addCloneApp = async (AuthToken, value) => {
  try {
    return await axios.post(`${WEBURL}add_clone_app`, value, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};

export const uploadImage = async (AuthToken, value) => {
  try {
    return await axios.post(`${WEBURL}upload`, value, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};


//const calculator
//get all modules
export const getAllCalcModule = async (AuthToken) => {
  try {
    return await axios.get(`${GET_ALL_CALC_MODULE}`, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};

export const getSubFeatures = async (AuthToken, id) => {
  try {
    return await axios.get(`${GET_ALL_SUBFEATURES}${id}`,{value:""}, {
      headers: { Authorization: AuthToken },
    });
  } catch (err) {
    throw err;
  }
};

