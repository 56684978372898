import React from "react";
import { Modal } from "antd";
import { useEffect } from "react";
import { deleteCalcResponse } from "../../../../graphs/appCalculator/appcalcApis";
import { useSelector } from "react-redux";

const DeLeteCalcResponseModal = ({
    deleteResponseModal,
    setDeleteResponseModal,
    responseId,
  }) => {
    const AuthToken = useSelector((state) => state?.auth?.authToken);
  
    const deletePorposalHandler = async () => {
      if (responseId) {
        try {
          await deleteCalcResponse(AuthToken, responseId).then((res) => {
            console.log(res);
          });
          setDeleteResponseModal(false)
        } catch (err) {
          console.log(err);
        }
      }
    };
  return (
    <>
    <Modal
      width={400}
      open={deleteResponseModal}
      onOk={() => setDeleteResponseModal(false)}
      onCancel={() => setDeleteResponseModal(false)}
      destroyOnClose
      footer={null}
    >
      <div className="text-center">
        <h2 className=" text-base">Are you sure want to delete Response</h2>
        <div className="flex items-center justify-center mt-3">
          <button
            onClick={() => setDeleteResponseModal(false)}
            className="py-2 px-4 bg-slate-400 border hover:border-slate-700 border-slate-400 rounded-lg backdrop-blur"
          >
            cancel
          </button>
          <button onClick={()=> deletePorposalHandler(responseId)} className="ml-2 py-2 px-4 bg-yellow-400 border hover:border-yellow-700 border-yellow-400 rounded-lg backdrop-blur">
            Ok
          </button>
        </div>
      </div>
    </Modal>
  </>
  )
}

export default DeLeteCalcResponseModal
