import { DELETE_USER, GET_USER, REGISTER_USER, UPDATE_PASSWORD, UPDATE_USER } from "../constant/common";
import axios from "axios";

export const getUsers = async(AuthToken) => {
try{
 return await axios.get(`${GET_USER}`,{
    headers: {Authorization: AuthToken},
 })
}catch(err){
    throw err;
}
}

export const addUser = async(Authtokoen, values) => {
    try{
        return await axios.post(`${REGISTER_USER}`,values,{
            headers: {Authorization:Authtokoen},
        })
    }catch(err){
        throw err;
    }
}

export const deleteUser = async(Authoken,id) => {
    try{
        return await axios.delete(`${DELETE_USER}/${id}`,{
            headers:{Authorization:Authoken}
        })
    }catch(err){
        throw err;
    }
}

export const updateUser = async(Authtoken, values, id) => {
    try{
        return await axios.post(`${UPDATE_USER}/${id}`,values,{
            headers:{Authorization:Authtoken}
        })
    }catch(err){
        throw err;
    }
}

export const updatePassword = async(Authtoken, values, id) =>{
    try{
        return await axios.post(`${UPDATE_PASSWORD}/${id}`,values,{
            headers:{Authorization:Authtoken}
        })
    }catch(err){
        throw err;
    }
}