import React from "react";

const BenefirsInputsForm = ({value1,value2,errors1,touched1,errors2,touched2, onBlur, onChange,  title}) => {
  return (
    <div className="w-full p-2 rounded-lg border">
      <div className="w-full">
        <div class="mb-2 w-full">
          <label class="block text-sm font-medium mb-2" for="">
            Benefits Icon {title} *
          </label>
          <input
            class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
            type="text"
            value={value1}
            name={`benefitsIcon${title}`}
            id={`benefitsIcon${title}`}
            onChange={onChange}
            onBlur={onBlur}
          />
          {errors1 && touched1 ? (
            <p className="text-rose-500 text-sm">{errors1}</p>
          ) : null}
        </div>

        <div class="mb-2 w-full">
          <label class="block text-sm font-medium mb-2" for="">
            Benefits Icon {title} Title*
          </label>
          <input
            class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
            type="text"
            value={value2}
            name={`benefitsIcon${title}Title`}
            id={`benefitsIcon${title}Title`}
            onChange={onChange}
            onBlur={onBlur}
          />
          {errors2 && touched2 ? (
            <p className="text-rose-500 text-sm">{errors2}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BenefirsInputsForm;
