import { Modal } from 'antd'
import React from 'react'

const EditProposalModal = ({editProposalModal, setEditProposalModal, proposalData}) => {
  return (
    <>
      <Modal
      open={editProposalModal}
      onOk={()=>setEditProposalModal(false)}
      onCancel={()=>setEditProposalModal(false)}
      destroyOnClose
      footer={null}
      >
        <h2>Edit Proposals Modal</h2>
      </Modal>
    </>
  )
}

export default EditProposalModal
