import React, { useEffect, useState } from "react";
import Header from "../../../components/ui/Header";
import {
  addappCalcApis,
  editappCalcApis,
  getappCalcApis,
} from "../../../graphs/appCalculator/appcalcApis";
import { useSelector } from "react-redux";
import { MEDIALINK } from "../../../constant/common";
import { Formik } from "formik";
import DeleteModal from "../../../components/ui/modal/DeleteModal";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import CButton from "../../../components/ui/Button";
import { notification } from "antd";

const Module = () => {
  const [allModuleData, setAllModuleData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const AuthToken = useSelector((state) => state?.auth?.authToken);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPageOptions = [10, 20, 50, 100];
  const rowsPerPage = 10;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const columns = [
    {
      name: "Sr. No",
      center: true,
      width: "70px",
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
    },
    {
      name: "Icon",
      cell: (ele) => (
        <div className="font-medium">
          {ele?.icon ? (
            <img
              className="w-8 h-8"
              src={`${MEDIALINK}modules/${ele?.icon}`}
              alt="icon"
            />
          ) : null}
        </div>
      ),
    },
    {
      name: "Module Name",
      sortable: true,
      selector: (row) => <p className="break-words whitespace-break-spaces">{row?.name}</p>,
    },
    {
      name: "If Default(consider this price)",
      selector: (ele) => (ele?.is_default === "0" ? "No" : "Yes"),
    },
    {
      name: "Action",
      center: true,
      cell: (ele) => (
        <div>
          <div
            class="inline-block mr-2 cursor-pointer"
            onClick={() => {
              setEditData(ele);
              goToTop();
            }}
          >
            <FontAwesomeIcon
              className="text-blue-600 hover:text-blue-800"
              size="lg"
              icon={faEdit}
            />
          </div>
          <div
            onClick={() => {
              setDeleteId(ele?.id);
              setDeleteModal(true);
            }}
            className="cursor-pointer inline-block"
          >
            <FontAwesomeIcon
              className="text-rose-500 hover:text-rose-600"
              size="lg"
              icon={faTrashAlt}
            />
          </div>
        </div>
      ),
    },
  ];
  const getAllModuleData = async () => {
    try {
      await getappCalcApis(AuthToken, "module").then((res) => {
        setAllModuleData(res.data?.data);
        console.log("module data", res.data?.data);
        // setAllUserData(res?.data)
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (value, { resetForm }) => {
    console.log(value);
    let formData = new FormData();
    formData.append("name", value.name);

    if (editData === null) {
      try {
        await addappCalcApis(AuthToken, "module", formData).then((res) => {
          console.log(res);
        });
        notification["success"]({
          message: "Data added successfully",
        });
        getAllModuleData();
      } catch (err) {
        throw err;
      }
    } else {
      formData.append("id", value?.id);
      formData.append("is_default", value?.is_default);
      formData.append("icon", value?.icon);
      try {
        await editappCalcApis(AuthToken, "module", formData).then((res) => {
          console.log(res);
        });
        notification["success"]({
          message: "Data updated successfully",
        });
        setEditData(null);
        getAllModuleData();
      } catch (err) {
        console.log(err);
      }
    }
    resetForm();
  };

  const validateData = (value) => {
    const error = {};
    if (!value.name) {
      error.name = "Please enter value";
    }
    console.log("validation error =>", error);
    return error;
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getAllModuleData();
  }, [editData, deleteModal]);

  return (
    <section class="py-8">
      <Header title={"Modules"} />
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap -mx-4">
          <div class="w-full lg:w-full px-4 mb-8 lg:mb-0">
            <div class="px-6 pb-6 pt-4 bg-white shadow rounded">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  name: editData?.name ? editData.name : "",
                  id: editData?.id ? editData.id : "",
                  is_default: editData?.is_default ? editData.is_default : "",
                  icon: editData?.icon ? editData.icon : "",
                }}
                onSubmit={handleSubmit}
                validate={validateData}
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  handleReset,
                  handleSubmit,
                  errors,
                  touched,
                  setFieldValue,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className=" grid grid-cols-7 gap-4 items-start">
                      <div className="lg:col-span-2 md:col-span-2 sm:col-span-7 col-span-7">
                        <div class="mb-6">
                          <label class="block text-sm font-medium mb-2" for="">
                            Enter Module *
                          </label>
                          <input
                            class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                            type="text"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="name"
                            value={values?.name}
                            placeholder="New Module"
                          />
                          {errors.name && touched.name ? (
                            <p className="text-sm text-rose-500">
                              {errors.name}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      {editData ? (
                        <>
                          <div className="lg:col-span-2 md:col-span-2 sm:col-span-7 col-span-7">
                            <div class="mb-6">
                              <label
                                class="block text-sm font-medium mb-2"
                                for=""
                              >
                                Service Icon
                              </label>
                              <input
                                class="block w-full px-4 py-2 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                type="file"
                                name="icon"
                                onChange={(e) =>
                                  setFieldValue("icon", e.target.files[0])
                                }
                                onBlur={handleBlur}
                                id="icon"
                              />
                            </div>
                          </div>

                          <div className="lg:col-span-2 md:col-span-2 sm:col-span-7 col-span-7">
                            <div class="">
                              <label
                                class="block text-sm font-medium mb-2"
                                for=""
                              >
                                Is Default
                              </label>
                              <select
                                class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                                type="file"
                                name="is_default"
                                value={values?.is_default}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="is_default"
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                            <span className="text-xs">
                              (Its price will be considered , if not selected)
                            </span>
                          </div>
                          {errors.is_default && touched.is_default ? (
                            <p className="text-sm text-rose-500">
                              {errors.is_default}
                            </p>
                          ) : null}
                        </>
                      ) : null}
                      <div className="lg:col-span-2 md:col-span-2 sm:col-span-7 col-span-7 my-auto">
                        <CButton title={"Submit"} type={"submit"} />
                      </div>
                    </div>
                  </form>
                )}
              </Formik>

              <div class="p-4 mb-6 bg-white shadow-xl rounded overflow-x-auto">
                <DataTable
                  columns={columns}
                  data={allModuleData}
                  pagination={true}
                  striped
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={rowsPerPageOptions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteId={deleteId}
        type={"module"}
        title={"module"}
      />
    </section>
  );
};

export default Module;
