import React, { Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Routes, createBrowserRouter } from "react-router-dom";
import Dashboard from "../pages/admin/Dashboard";
import AdminLayout from "./Layout";
import Login from "../pages/Login";
import Users from "../pages/admin/Users";
import Proposals from "../pages/admin/Proposals";
import Protected from "./Protected";
import CreateProposals from "../pages/admin/CreateProposals";
import Industry from "../pages/admin/appCalculator/Industry";
import Services from "../pages/admin/appCalculator/Services";
import Module from "../pages/admin/appCalculator/Module";
import Questions from "../pages/admin/appCalculator/Questions";
import AppQuality from "../pages/admin/appCalculator/AppQuality";
import Technology from "../pages/admin/appCalculator/Technology";
import CalculatorResponse from "../pages/admin/appCalculator/CalculatorResponse";
import ContactUs from "../pages/admin/enquiries/ContactUs";
import JobApplications from "../pages/admin/enquiries/JobApplications";
import Openings from "../pages/admin/enquiries/Openings";
import Callback from "../pages/admin/enquiries/Callback";
import Feature from "../pages/admin/appCalculator/Feature";
import AddCaseStudy from "../pages/AddCaseStudy";
import AddCloneApp from "../pages/AddCloneApp";
import { useSelector } from "react-redux";
import ResponseDetails from "../pages/admin/appCalculator/ResponseDetails";
import EditResponseDetails from "../pages/admin/appCalculator/EditResponseDetails";
import EditProposal from "../pages/admin/EditProposal";
import GuestLayout from "./GuestLayout";
import ResetPassword from "../pages/ResetPassword";
import ViewProposal from "../pages/admin/ViewProposal";
import CalcLayout from "./CalcLayout";
import CalcServices from '../pages/costCalc/CalcServices'
import CalcSuccess from "../pages/costCalc/CalcSuccess";

const PageRouter = () => {
  const [isSignedIn, setIsSignedIn] = useState(null);
  const AuthToken = useSelector((state) => state?.auth?.authToken);
  const logindata = useSelector((state)=> state?.auth?.loginUserData)

//   const PageRouter = createBrowserRouter([
//     { path : '/', element : <Login/> },
//     {
//       element : <GuestLayout/>,
//       children : [
//         { path : '/admin/dashboard', element : <Dashboard/> },
//         { path : '/admin/createproposals', element : <CreateProposals /> },
//         { path : '/admin/editProposal/:id', element :  <EditProposal /> },
//         { path : '/admin/add-case-study', element : <AddCaseStudy />},
//         { path : '/admin/add-clone-app', element : <AddCloneApp />},
//       ]
//   },
//   {
//     element : <AdminLayout/>,
//     children : [
//         { path : '/admin/dashboard', element : <Dashboard/> },
//         { path : '/admin/users', element : <Users /> },
//         { path : '/admin/createproposals', element : <CreateProposals /> },
//         { path : '/admin/editProposal/:id', element :  <EditProposal /> },
//         { path : '/admin/appcalc/industry', element : <Industry /> },
//         { path : '/admin/appcalc/services', element : <Services /> },
//         { path : '/admin/appcalc/module', element : <Module /> },
//         { path : '/admin/appcalc/feature', element : <Feature /> },
//         { path : '/admin/appcalc/questions', element : <Questions /> },
//         { path : '/admin/appcalc/app-quality', element : <AppQuality /> },
//         { path : '/admin/appcalc/technology', element : <Technology /> },
//         { path : '/admin/appcalc/calculator-response', element : <CalculatorResponse /> },
//         { path : '/admin/appcalc/response-details/:id', element : <ResponseDetails /> },
//         { path : '/admin/appcalc/edit-details/:id', element : <EditResponseDetails />},
//         { path : '/admin/enquiries/contact-us', element : <ContactUs />},
//         { path : '/admin/enquiries/job-applications', element : <JobApplications />},
//         { path : '/admin/enquiries/openings', element : <Openings />},
//         { path : '/admin/enquiries/callback', element : <Callback />},
//         { path : '/admin/add-case-study', element : <AddCaseStudy />},
//         { path : '/admin/add-clone-app', element : <AddCloneApp />},
//     ]
// }

//   ]) 



  return (
    <>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route element={<CalcLayout />}> 
        <Route exact path="/cost-calculator" element={<CalcServices />} />
        <Route exact path="/calculator-overview/:id" element={<CalcSuccess />} />
        </Route>


        {logindata?.role === "guest" ? 
        <Route element={<GuestLayout />}>
        <Route path="/admin/dashboard" element={<Protected><Dashboard /> </Protected> } />
        <Route exact path="/admin/proposals" element={<Proposals />} />
        <Route path="/admin/editProposal/:id" element={<Protected><CreateProposals /></Protected> } />
        <Route path="/admin/add-case-study" element={<Protected><AddCaseStudy /></Protected> } />
        <Route path="/admin/add-clone-app" element={<Protected><AddCloneApp /></Protected> } />
        <Route path="/admin/change-password" element={<Protected><ResetPassword /></Protected> } />

        </Route>
        : 
        
        <Route element={<AdminLayout />}>
          <Route
            path="/admin/dashboard"
            element={
              <Protected>
                <Dashboard />
              </Protected>
            }
          />
          <Route
            exact
            path="/admin/users"
            element={
              <Suspense fallback={<div className="">Loading...</div>}>
                <Users />
              </Suspense>
            }
          />
          <Route exact path="/admin/proposals" element={<Proposals />} />
          <Route
            path="/admin/createproposals"
            element={
              <Protected isSignedIn={false}>
                <CreateProposals />
              </Protected>
            }
          />

          <Route
            path="/admin/editProposal/:id"
            element={
              <Protected isSignedIn={false}>
                <EditProposal />
              </Protected>
            }
          />
          <Route
            path="/admin/viewProposal/:id"
            element={
              <Protected isSignedIn={false}>
                <ViewProposal />
              </Protected>
            }
          />
          <Route
            path="/admin/appcalc/industry"
            element={
              <Protected isSignedIn={false}>
                <Industry />
              </Protected>
            }
          />
          <Route
            path="/admin/appcalc/services"
            element={
              <Protected isSignedIn={false}>
                <Services />
              </Protected>
            }
          />
          <Route
            path="/admin/appcalc/module"
            element={
              <Protected isSignedIn={false}>
                <Module />
              </Protected>
            }
          />
          <Route
            path="/admin/appcalc/feature"
            element={
              <Protected isSignedIn={false}>
                <Feature />
              </Protected>
            }
          />
          <Route
            path="/admin/appcalc/questions"
            element={
              <Protected isSignedIn={false}>
                <Questions />
              </Protected>
            }
          />
          <Route
            path="/admin/appcalc/app-quality"
            element={
              <Protected isSignedIn={false}>
                <AppQuality />
              </Protected>
            }
          />
          <Route
            path="/admin/appcalc/technology"
            element={
              <Protected isSignedIn={false}>
                <Technology />
              </Protected>
            }
          />
          <Route
            path="/admin/appcalc/calculator-response"
            element={
              <Protected isSignedIn={false}>
                <CalculatorResponse />
              </Protected>
            }
          />
          <Route
            path="/admin/appcalc/response-details/:id"
            element={
              <Protected isSignedIn={false}>
                <ResponseDetails />
              </Protected>
            }
          />
          <Route
            path="/admin/appcalc/edit-details/:id"
            element={
              <Protected isSignedIn={false}>
                <EditResponseDetails />
              </Protected>
            }
          />

          <Route
            path="/admin/enquiries/contact-us"
            element={
              <Protected isSignedIn={false}>
                <ContactUs />
              </Protected>
            }
          />
          <Route
            path="/admin/enquiries/job-applications"
            element={
              <Protected isSignedIn={false}>
                <JobApplications />
              </Protected>
            }
          />
          <Route
            path="/admin/enquiries/openings"
            element={
              <Protected isSignedIn={false}>
                <Openings />
              </Protected>
            }
          />
          <Route
            path="/admin/enquiries/callback"
            element={
              <Protected isSignedIn={false}>
                <Callback />
              </Protected>
            }
          />
          <Route
            path="/admin/add-case-study"
            element={
              <Protected isSignedIn={false}>
                <AddCaseStudy />
              </Protected>
            }
          />
          <Route
            path="/admin/add-clone-app"
            element={
              <Protected isSignedIn={false}>
                <AddCloneApp />
              </Protected>
            }
          />
        </Route>
        
        }
        

      </Routes>
    </>
  );
};

export default PageRouter;
