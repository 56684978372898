import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setLoginFlag, setAuthToken, setLoginUserData } from "../actions/auth";
import { Formik } from "formik";
import { adminLogin } from "../graphs/auth/login";
import { notification } from "antd";
import bgOffice from "../assets/images/bg_office.jpg"

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    console.log(values);
    try {
      await adminLogin(values).then((res) => {
        console.log("response=> ", res?.data);
        if (res?.data.length === 0) {
          notification["warning"]({
            message: "Please enter valid details",
          });
          console.log("enter valid details");
        } else {
          dispatch(setLoginFlag(true));
          dispatch(setLoginUserData(res?.data));
          dispatch(setAuthToken(res?.data?.token));
          navigate("/admin/dashboard");
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const validateData = (values) => {
    const error = {};
    if (!values.username) {
      error.username = "Please enter username";
    }
    if (!values.password) {
      error.password = "Please enter password";
    }
    console.log(error);
    return error;
  };
  return (
    <section className="relative h-screen pt-24 lg:py-16 bg-slate-800">
      <div className="hidden lg:block absolute top-0 right-0 h-full w-1/2">
        <img
          className="absolute top-0 left-0 h-full w-full object-cover"
          src={bgOffice}
          alt=""
        />
        <div className="relative p-20">
          <a className="inline-block mb-56" href="#">
            <img src="trizzle-assets/logos/trizzle-white-logo.svg" alt="" />
          </a>
          {/* <div className="max-w-xl">
            <p className="text-3xl font-semibold text-white leading-10 mb-8">
              Trizzle is easy to use and has a lot of great features that make
              it a valuable tool for any developer.
            </p>
            <div className="flex items-center mb-12">
              <img
                className="w-14 h-14 mr-4 rounded-full border border-blue-500"
                src="trizzle-assets/images/avatar-men-2.png"
                alt=""
              />
              <div>
                <h4 className="text-lg leading-6 font-semibold text-white">
                  John Doe
                </h4>
                <span className="text-xs font-semibold text-gray-100">
                  Front-End Developer
                </span>
              </div>
            </div>
            <div>
              <a
                className="inline-block w-2 h-2 mr-2 rounded-full bg-blue-50"
                href="#"
              ></a>
              <a
                className="inline-block w-2 h-2 mr-2 rounded-full bg-blue-400"
                href="#"
              ></a>
              <a
                className="inline-block w-2 h-2 mr-2 rounded-full bg-blue-400"
                href="#"
              ></a>
              <a
                className="inline-block w-2 h-2 mr-2 rounded-full bg-blue-400"
                href="#"
              ></a>
              <a
                className="inline-block w-2 h-2 rounded-full bg-blue-400"
                href="#"
              ></a>
            </div>
          </div> */}
        </div>
      </div>
      <div className="container px-4 mx-auto">
        <div className="lg:w-1/2">
          <div className="relative max-w-xs lg:max-w-md mx-auto text-center">
            <a className="inline-block mx-auto mb-10" href="#">
              <img
                className="block w-28 h-auto"
                src="https://prometteursolutions.com/appcalc/assets/images/logo2.png"
                alt=""
              />
            </a>
            {/* <h2 className="text-2xl text-gray-100 font-semibold mb-2">
              Log in to your account
            </h2>
            <p className="text-gray-300 font-medium mb-10">
              Welcome back! Please enter your details.
            </p> */}

            <Formik
              initialValues={{
                username: "",
                password: "",
              }}
              onSubmit={handleSubmit}
              validate={validateData}
            >
              {({
                values,
                handleBlur,
                handleChange,
                handleReset,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="relative w-full h-14 py-4 px-3  border border-gray-400 hover:border-white focus-within:border-green-500 rounded-lg">
                    <span className="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-300 px-1 bg-gray-600">
                      Email
                    </span>
                    <input
                      name="username"
                      value={values?.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full outline-none bg-transparent text-sm text-gray-100 font-medium"
                      id="username"
                      type="email"
                    />
                  </div>
                  <div className="mb-8">
                       {errors.username && touched.username ? (
                  <p className="text-rose-500 text-start">{errors.username}</p>
                ) : null}
                </div>
                  <div className="relative w-full h-14 py-4 px-3 border border-gray-400 hover:border-white focus-within:border-green-500 rounded-lg">
                    <span className="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-300 px-1 bg-gray-600">
                      Password
                    </span>
                    <input
                      name="password"
                      value={values?.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full outline-none bg-transparent text-sm text-gray-100 font-medium"
                      id="password"
                      type="password"
                    />
                  </div>
                  <div className="mb-6">
                       {errors.password && touched.password ? (
                  <p className="text-rose-500 text-start">{errors.password}</p>
                ) : null}
                </div>
                  {/* <div className="flex flex-wrap items-center justify-between mb-6">
                <div className="flex items-center mb-4 sm:mb-0">
                  <input id="signInInput4-3" type="checkbox" />
                  <label
                    className="ml-2 text-xs text-gray-300 font-semibold"
                    for=""
                  >
                    Remember for 30 days
                  </label>
                </div>
                <div className="w-full sm:w-auto">
                  <a
                    className="inline-block text-xs font-semibold text-blue-500 hover:text-blue-600"
                    href="#"
                  >
                    Forgot password?
                  </a>
                </div>
              </div> */}
                  <button
                    type="submit"
                    className="block w-full py-4 mb-4 leading-6 text-white font-semibold bg-[#F6C40E] hover:bg-[#F6C40E] rounded-lg transition duration-200"
                  >
                    Sign In
                  </button>
                  {/* <div className="text-white">
                    <Link to="/cost-calculator">
                    App calc
                    </Link>
                    </div> */}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="lg:hidden relative mt-16">
        <img
          className="absolute top-0 left-0 h-full w-full object-cover"
          src="trizzle-assets/images/background-blue-gradient.png"
          alt=""
        />
        <div className="container px-4 mx-auto">
          <div className="relative max-w-xs mx-auto py-12">
            <a className="inline-block mb-24" href="#">
              <img src="trizzle-assets/logos/trizzle-white-logo.svg" alt="" />
            </a>
            <div className="max-w-xl">
              <p className="text-xl font-semibold text-white leading-10 mb-8">
                Trizzle is easy to use and has a lot of great features that make
                it a valuable tool for any developer.
              </p>
              <div className="flex items-center mb-12">
                <img
                  className="w-14 h-14 mr-4 rounded-full border border-blue-500"
                  src="trizzle-assets/images/avatar-men-2.png"
                  alt=""
                />
                <div>
                  <h4 className="text-lg leading-6 font-semibold text-white">
                    John Doe
                  </h4>
                  <span className="text-xs font-semibold text-gray-100">
                    Front-End Developer
                  </span>
                </div>
              </div>
              <div>
                <a
                  className="inline-block w-2 h-2 mr-2 rounded-full bg-blue-50"
                  href="#"
                ></a>
                <a
                  className="inline-block w-2 h-2 mr-2 rounded-full bg-blue-400"
                  href="#"
                ></a>
                <a
                  className="inline-block w-2 h-2 mr-2 rounded-full bg-blue-400"
                  href="#"
                ></a>
                <a
                  className="inline-block w-2 h-2 mr-2 rounded-full bg-blue-400"
                  href="#"
                ></a>
                <a
                  className="inline-block w-2 h-2 rounded-full bg-blue-400"
                  href="#"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
