import React from 'react'
import TinyTextEditor from './TinyTextEditor'

const SuccessInputsForm = ({errors1,touched1,errors2,touched2, onBlur, onChange, value1,value2, title, setFieldValue}) => {
  return (
    <div className="border p-2 w-full rounded-lg">                          
    <div class="mb-2 w-full">
      <label
        class="block text-sm font-medium mb-2"
        for=""
      >
        Success Statistics Icon {title} Heading *
      </label>
      <input
        class="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
        type="text"
        value={value1}
        name={`successStatisticsIcon${title}Heading`}
        id={`successStatisticsIcon${title}Heading`}
        onChange={onChange}
        onBlur={onBlur}
      />
      {errors1 && touched1 ? (
        <p className="text-rose-500 text-sm">
          {errors1}
        </p>
      ) : null}
    </div>

    <div class="mb-2 w-full">
      <label
        class="block text-sm font-medium mb-2"
        for=""
      >
        Success Statistics Icon {title} description *
      </label>
      <TinyTextEditor
       initialValue={""}
       className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
       id={`successStatisticsIcon${title}Description`}
       name={`successStatisticsIcon${title}Description`}
       onEditorChange={(content) =>
         setFieldValue(`successStatisticsIcon${title}Description`, content)
       }
       value={value2}

        // value={value2}
        // name={`successStatisticsIcon${title}Description`}
        // id={`successStatisticsIcon${title}Description`}
      />
       {errors2 && touched2 ? (
        <p className="text-rose-500 text-sm">
          {errors2}
        </p>
      ) : null}
    </div>
    </div>
  )
}

export default SuccessInputsForm
