import React, { useEffect, useState } from "react";
import ViewProposalModal from "../../components/ui/modal/proposals/ViewProposalModal";
import CButton from "../../components/ui/Button";
import EditProposalModal from "../../components/ui/modal/proposals/EditProposalModal";
import { useNavigate } from "react-router-dom";
import DeleteProposalModal from "../../components/ui/modal/proposals/DeleteProposalModal";
import { getProposals } from "../../graphs/getProposals";
import { useSelector } from "react-redux";
import ViewImagesModal from "../../components/ui/modal/ViewImagesModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

function Proposals() {
  const [viewProposalModal, setViewProposalModal] = useState(false);
  const [editProposalModal, setEditProposalModal] = useState(false);
  const [proposalData, setProposalData] = useState(null);
  const [deleteProposalModal, setDeleteProposalModal] = useState(false);
  const [allProposalsData, setaALLProposalsData] = useState([]);
  const [proposalId, setProposalId] = useState();
  const [globalList, setGlobalList] = useState([]);
  const AuthToken = useSelector((state) => state?.auth?.authToken);
  const id = useSelector((state) => state?.auth?.loginUserData?.user_id);
  const role = useSelector((state) => state?.auth?.loginUserData?.role);

  const navigate = useNavigate();

  const handleSearch = (value) => {
    const searchTerm = value;

    if (searchTerm !== "") {
      const filteredData = globalList?.filter((ele) =>
        ele.title.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
      setaALLProposalsData(filteredData);
    } else {
      getAllProposals();
    }
  };
  const handleclick = () => {
  };

  const getAllProposals = async () => {
    try {
      await getProposals(AuthToken, id, role).then((res) => {
        setaALLProposalsData(res?.data);
        setGlobalList(res?.data);
      });
    } catch (err) {
      throw err
    }
  };
  useEffect(() => {
    getAllProposals();
  }, [deleteProposalModal]);
  return (
    <>
      <section className="py-8">
        <div className="px-4">
          <div className="lg:flex md:flex sm:block block items-center justify-between p-4 mb-6 bg-white shadow rounded overflow-x-auto">
            <div className="flex items-center ">
              <h2 className="text-lg font-medium">Proposals</h2>
              <span className="ml-2 border-2 rounded-full border-yellow-400">
                <span className=" p-2 text-sm">
                  {allProposalsData?.length ? allProposalsData?.length : ""}
                </span>
              </span>
            </div>
            <div className="flex items-center w-full">
              <div className="lg:flex md:flex sm:block block  items-center justify-center w-full">
                <div className="w-full md:w-1/2 lg:mt-0 md:mt-0 sm:mt-2 mt-2 lg:w-auto flex pr-4 mb-4 md:mb-0 md:mr-4 md:ml-auto border-2 rounded bg-white">
                  <input
                    onChange={(e) => handleSearch(e.target.value)}
                    className="py-2 pl-4 pr-2 text-sm placeholder-gray-500 focus:outline-0"
                    type="text"
                    placeholder="Search Proposal Name..."
                  />
                  <button className="ml-auto">
                    <svg
                      className="text-rose-500"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.0921 16.9083L15.0004 13.8417C16.2005 12.3453 16.7817 10.4461 16.6244 8.53441C16.4672 6.62274 15.5835 4.84398 14.155 3.56386C12.7265 2.28375 10.8619 1.59958 8.94451 1.65205C7.02711 1.70452 5.20268 2.48963 3.84636 3.84594C2.49004 5.20226 1.70493 7.02669 1.65247 8.94409C1.6 10.8615 2.28416 12.7261 3.56428 14.1546C4.84439 15.583 6.62316 16.4668 8.53482 16.624C10.4465 16.7812 12.3457 16.2001 13.8421 15L16.9087 18.0667C16.9862 18.1448 17.0784 18.2068 17.1799 18.2491C17.2815 18.2914 17.3904 18.3132 17.5004 18.3132C17.6104 18.3132 17.7193 18.2914 17.8209 18.2491C17.9224 18.2068 18.0146 18.1448 18.0921 18.0667C18.2423 17.9113 18.3262 17.7036 18.3262 17.4875C18.3262 17.2714 18.2423 17.0637 18.0921 16.9083ZM9.16708 15C8.01335 15 6.88554 14.6579 5.92625 14.0169C4.96696 13.3759 4.21929 12.4649 3.77778 11.399C3.33627 10.3331 3.22075 9.16019 3.44583 8.02863C3.67091 6.89708 4.22648 5.85767 5.04229 5.04187C5.85809 4.22606 6.89749 3.67049 8.02905 3.44541C9.1606 3.22033 10.3335 3.33585 11.3994 3.77736C12.4653 4.21887 13.3763 4.96654 14.0173 5.92583C14.6583 6.88512 15.0004 8.01293 15.0004 9.16666C15.0004 10.7138 14.3858 12.1975 13.2919 13.2914C12.1979 14.3854 10.7142 15 9.16708 15Z"
                        fill="#382CDD"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="">
                  <CButton
                    onClick={() => {
                      navigate("/admin/createproposals");
                      handleclick();
                    }}
                    title={"Create Proposals"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full lg:w-full px-4 mb-8 lg:mb-0">
              <div className="px-6 pb-6 pt-4 bg-white shadow rounded">
                {allProposalsData?.map((ele, idx) => {
                  return (
                    <div key={idx} className="flex flex-wrap hover:shadow-sm hover:bg-slate-300  transform transition-all justify-between items-center mb-2 p-4 bg-slate-100 rounded">
                      <div className="flex flex-wrap items-center">
                        <span className="inline-flex w-6 h-6 mr-3 justify-center items-center bg-yellow-300 rounded-full font-medium text-base">
                          {idx + 1}
                        </span>
                        <div>
                          <h4 className="text-sm font-medium">{ele?.title}</h4>
                          {/* <p className="text-xs text-gray-500">
                        Development Department
                      </p> */}
                        </div>
                      </div>

                      <div className="flex flex-wrap items-center">
                        <span className="inline-block mr-3 py-1 px-2 bg-indigo-50 text-xs text-slate-900 rounded-full">
                          Created {ele?.created_at} - {ele?.first_name}{" "}
                          {ele?.last_name}
                        </span>
                        <div>
                          <div
                            className="inline-block hover:scale-110 cursor-pointer mr-2 text-lime-600 hover:text-lime-800"
                            title="view"
                            // onClick={()=> {setViewProposalModal(true); setProposalId(ele?.id)}}
                            onClick={() =>
                              navigate(`/admin/viewProposal/${ele?.id}`, {
                                state: ele,
                              })
                            }
                          >
                            <FontAwesomeIcon size="sm" icon={faEye} />
                          </div>
                          <div
                            onClick={() =>
                              navigate(`/admin/editProposal/${ele?.id}`, {
                                state: ele,
                              })
                            }
                            title="edit"
                            // onClick={()=> {setEditProposalModal(true);  setProposalId(ele?.id)}}
                            className="inline-block mr-2 text-blue-600 hover:text-blue-800 hover:scale-110 cursor-pointer "
                          >
                            <FontAwesomeIcon size="sm" icon={faEdit} />
                          </div>

                          <div
                            onClick={() => {
                              setProposalId(ele?.id);
                              setDeleteProposalModal(true);
                            }}
                            className="inline-block hover:opacity-05 hover:text-rose-600 text-rose-500 hover:scale-110 cursor-pointer "
                            title="delete"
                          >
                            <FontAwesomeIcon size="sm" icon={faTrashAlt} />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <ViewProposalModal
          viewProposalModal={viewProposalModal}
          setViewProposalModal={setViewProposalModal}
          proposalId={proposalId}
        />
        <EditProposalModal
          editProposalModal={editProposalModal}
          setEditProposalModal={setEditProposalModal}
          proposalData={proposalData}
        />
        <DeleteProposalModal
          deleteProposalModal={deleteProposalModal}
          setDeleteProposalModal={setDeleteProposalModal}
          proposalId={proposalId}
        />
      </section>
    </>
  );
}

export default Proposals;
