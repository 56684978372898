import React from 'react'

const CalcInputCard = ({ele, value1, value2, type , handleBlur, name,onChangeCheckStatus}) => {
  return (
    <label
    key={ele?.id}
    className="mb-3 relative cursor-pointer"
  >
    {value1?.includes(ele?.id) ? (
      <img
        src="https://prometteursolutions.com/appcalc/bx-bg.png"
        className="absolute top-0 right-0  z-20 transition-all ease-in-out "
      />
    ) : null}
    <div
      className={`${
        value1?.includes(ele?.id)
          ? "border border-yellow-300 shadow-lg scale-105"
          : ""
      }  border rounded-sm hover:scale-105 hover:shadow-lg transition-all ease-in-out relative group lg:w-32 lg:h-32 md:w-32 md:h-32sm:w-26 w-26`}
    >
      <div className="flex items-center cursor-pointer p-2 custom-control-label">
        <input
          className="hidden"
          name={name}
          onChange={ (e) => {
            const isChecked = e.target.checked;
            const itemId = ele?.id;
            const parentServicesArray =
              value1 || [];

            if (isChecked) {
              parentServicesArray.push(itemId);
            } else {
              const index =
                parentServicesArray.indexOf(itemId);
              if (index !== -1) {
                parentServicesArray.splice(
                  index,
                  1
                );
              }
            }

            // onChangeCheckStatus( parentServicesArray  );

            const itemName = ele;
            const parentServicesArrayname =
              value2 || [];

            if (isChecked) {
              parentServicesArrayname.push(
                itemName
              );
            } else {
              const index =
                parentServicesArrayname.indexOf(
                  itemName
                );
              if (index !== -1) {
                parentServicesArrayname.splice(
                  index,
                  1
                );
              }
            }

            onChangeCheckStatus( {
                parentServicesArray:parentServicesArray,
                parentServicesArrayname:parentServicesArrayname
            }  );
            // onChangeCheckStatus(
            //   `mainServices`,
            //   parentServicesArrayname
            // );
          }}
          checked={
            value1?.includes(
              ele?.id
            ) || false
          }
          handleBlur={handleBlur}
          type={type}
        />

        <div className="text-center w-full">
          <img
            className="w-10 mb-4 block mx-auto formbx_img"
            alt="service4"
            src={`https://prometteursolutions.com/appcalc_api/media/services/${ele?.icon}`}
          />
          <p className="block relative p-0 m-0 text-xs font-medium ">
            {ele?.service}
          </p>
        </div>
      </div>
    </div>
  </label>
  )
}

export default CalcInputCard
