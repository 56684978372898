import { Modal, notification } from "antd";
import React from "react";
import { Formik } from "formik";
import TinyTextEditor from "../../TinyTextEditor";
import {
  addappCalcApis,
  editappCalcApis,
} from "../../../../graphs/appCalculator/appcalcApis";
import { useSelector } from "react-redux";

const AddOpeningModal = ({
  addOpeningModal,
  setAddOpeningModal,
  openingData,
}) => {
  const AuthToken = useSelector((state) => state?.auth?.authToken);
  const sidebarToggle = useSelector((state) => state?.toggleBtn?.toggle);
  const handleSubmit = async (value) => {
    let formData = new FormData();
    formData.append("job_title", value?.jobTitle);
    formData.append("id", value?.id);
    formData.append("status", value?.status);
    formData.append("job_description", value?.jobDescription);
    try {
      if (openingData === null) {
        await addappCalcApis(AuthToken, "opening", formData).then((res) => {
          notification["success"]({
            message: "Data added Successfully",
          });
          setAddOpeningModal(false);
        });
      } else {
        await editappCalcApis(AuthToken, "opening", formData).then((res) => {
          notification["success"]({
            message: "Data Edited Successfully",
          });
          setAddOpeningModal(false);
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const validateData = (value) => {
    const error = {};

    if (!value.jobTitle) {
      error.jobTitle = "Please enter value";
    }
    if (!value.jobDescription) {
      error.jobDescription = "Please enter value";
    }
    return error;
  };

  return (
    <div>
      <Modal
        width={800}
        open={addOpeningModal}
        onOk={() => setAddOpeningModal(false)}
        onCancel={() => setAddOpeningModal(false)}
        footer={null}
        centered
        className="m-8"
        style={{ left: `${sidebarToggle ? "90px" : "140px"}` }}
        destroyOnClose
      >
        <h2 className="mb-4 text-xl font-bold">
          {openingData === null ? "Add New Opening" : "Edit Opening"}
        </h2>
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: openingData?.id ? openingData?.id : "",
            jobTitle: openingData?.job_title ? openingData.job_title : "",
            jobDescription: openingData?.job_description
              ? openingData.job_description
              : "",
            status:openingData?.status ? openingData?.status : ""
          }}
          onSubmit={handleSubmit}
          validate={validateData}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleReset,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div class="mb-6">
                <label class="block text-sm font-medium mb-2" for="">
                  Job Title *
                </label>
                <input
                  className={`block w-full px-4 py-3  text-sm placeholder-gray-500 bg-white border rounded ease-in-out transition-all delay-700 ${
                    errors.firstName && touched.firstName ? "" : ""
                  }`}
                  type="text"
                  name="jobTitle"
                  id="jobTitle"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.jobTitle}
                  placeholder="Job Title"
                />
                {errors.jobTitle && touched.jobTitle ? (
                  <p className="text-rose-500">{errors.jobTitle}</p>
                ) : null}
              </div>

              <div className="mb-6">
              <label class="block text-sm font-medium mb-2" for="">
                  Status *
                </label>
                <select
                className="block w-full px-4 py-3  text-sm placeholder-gray-500 bg-white border rounded ease-in-out transition-all delay-700"
                  value={values.status}
                  name="status"
                  id="status"
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <option value="">Select Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <div class="mb-6">
                <label class="block text-sm font-medium mb-2" for="">
                  Job Description *
                </label>
                <TinyTextEditor
                  value={values?.jobDescription}
                  className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
                  id={"jobDescription"}
                  onEditorChange={(content) =>
                    setFieldValue("jobDescription", content)
                  }
                  name={"jobDescription"}
                  // value={values?.jobDescription}
                />
                {errors?.jobDescription && touched.jobDescription ? (
                  <p className="text-rose-500 text-sm">
                    {errors.jobDescription}
                  </p>
                ) : null}
              </div>

              <div>
                <button
                  type="submit"
                  className="py-2 px-4 bg-yellow-400 border hover:border-yellow-700 border-yellow-400 rounded-lg backdrop-blur"
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default AddOpeningModal;
